import React, { useEffect, useState } from "react";
import AnimationSpinner from "../../../AnimationSpinner";
import Swal from "sweetalert2";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const MembershipEdit = (props) => {

	const [subDomain, setSubDomain] = useState("");
	const [metaTitle, setMetaTile] = useState("");
	const [headerTitle, setHeaderTitle] = useState("");
	const [headerContent, setheaderContent] = useState("");
	const [description, setDescription] = useState("");
	const [siteLogo, setSiteLogo] = useState(undefined);
	const [siteLogoPreview, setSiteLogoPreview] = useState(undefined);
	const [favicon, setFavicon] = useState(undefined);
	const [faviconPreview, setFaviconPreview] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const { membershipIds } = useParams();

	const handleSiteLogoChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setSiteLogo(file);
			setSiteLogoPreview(URL.createObjectURL(file));
		}
	};
	const handleFaviconChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFavicon(file);
			setFaviconPreview(URL.createObjectURL(file));
		}
	};

	const fetchData = async () => {
		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "getSingleMembership");
		formData.append("ids", membershipIds);
		await axios
			.post(`${props.base_url}api/user/get-membership-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setSubDomain(response.data.items.sub_domain)
					setMetaTile(response.data.items.meta_title)
					setDescription(response.data.items.meta_description)
					setHeaderTitle(response.data.items.header_title)
					setheaderContent(response.data.items.header_content)
					setFavicon(response.data.items.favicon)
					setSiteLogo(response.data.items.logo)
					setLoading(false);
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	useEffect(() => {
		fetchData();
	}, [])


	const handleSubmit = (e) => {
		e.preventDefault();
		var token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}
		let isValid = true;
		if (!subDomain) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Sub domain is required!",
			});
			isValid = false;
		} else if (subDomain.length < 1 || subDomain.length > 12) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Sub domain must be between 1 and 12 characters!",
			});
			isValid = false;
		} else if (!/^[a-z0-9]+$/.test(subDomain)) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Sub domain can only contain lowercase letters and numbers!",
			});
			isValid = false;
		}
		if (!isValid) {
			return;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "updateMembership");
		formData.append("membership_ids", membershipIds);
		formData.append("sub_domain", subDomain);
		formData.append("meta_title", metaTitle);
		formData.append("meta_description", description);
		formData.append("header_title", headerTitle);
		formData.append("header_content", headerContent);
		formData.append("logo", siteLogo);
		formData.append("favicon", favicon);
		axios
			.post(`${props.base_url}api/user/get-membership-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false);
					setSubDomain("")
					setMetaTile("")
					setDescription("")
					setFavicon(undefined)
					setFaviconPreview(undefined)
					setSiteLogo(undefined)
					setSiteLogoPreview(undefined)
					setLoading(false);
					navigate("/manage-membership")
					Swal.fire({
						icon: "success",
						title: "Membership updated",
						text: "Your Membership details has been updated successfully.",
					});
					navigate("/manage-membership", { replace: true });
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						// text: response.data.message,
						html: `<p>${response.data.message}</p>`
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};


	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 col-md-12 mx-auto">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h6 className="title">Add New Membership</h6>
								</div>
								<form
									onSubmit={handleSubmit}
									acceptCharset="utf-8"
									autoComplete="off"
								>
									<div className="card-body">
										<div className="row form-group mb-0">
											<div className="col-md-12 mb-3">
												<label className="form-label">
													Sub Domain <span className="text-danger">*</span>
												</label>
												<input
													type="text"
													placeholder="Enter your sub domain here.."
													value={subDomain}
													onChange={(e) => setSubDomain(e.target.value)}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Meta title
												</label>
												<input
													type="text"
													placeholder="Enter your meta title here.."
													value={metaTitle}
													onChange={(e) => setMetaTile(e.target.value)}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Meta Description
												</label>
												<textarea
													rows="5"
													style={{ height: "100px" }}
													placeholder="Enter Your Site Meta Description..."
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													className="form-control custom-form-control"
												></textarea>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Header title
												</label>
												<input
													type="text"
													placeholder="Enter your header title here.."
													value={headerTitle}
													onChange={(e) => setHeaderTitle(e.target.value)}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Header content
												</label>
												<textarea
													value={headerContent}
													rows="5"
													style={{ height: "100px" }}
													placeholder="Enter your header content..."
													onChange={(e) => setheaderContent(e.target.value)}
													className="form-control custom-form-control"
												>
												</textarea>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Site logo
												</label>
												<input
													type="file"
													onChange={handleSiteLogoChange}
													className="form-control custom-form-control"
												/>
											</div>
											<div className="col-md-12 mb-4">
												{siteLogo && (
													<img
														src={siteLogoPreview || siteLogo}
														alt="Site Logo"
														className="img-fluid img-box mb-3 "
													/>
												)}
											</div>
											<div className="col-md-12 mb-3">
												<label className="form-label">
													Site Favicon
												</label>
												<input
													type="file"
													onChange={handleFaviconChange}
													className="form-control custom-form-control"
												/>
											</div>
											<div className="col-md-12 mb-4">
												{favicon && (
													<img
														src={faviconPreview || favicon}
														alt="Site Logo"
														className="img-fluid img-box mb-3 "
													/>
												)}
											</div>
										</div>
									</div>

									<button
										type="submit"
										className="btn btn-primary px-4 mt-2 mb-2"
									>
										Update Membership
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MembershipEdit;
