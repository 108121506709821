import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import AnimationSpinner from "../../AnimationSpinner";
import loginImg from "../../assests/images/loginPage/loginpageBackground.png";
import darkLogo from "../../assests/images/dark-logo.png";
import { BsFillPersonFill } from "react-icons/bs";
import { handleInvalidToken } from "../../AuthUtils";
import axios from "axios";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";

const ForgotPassReset = ({ base_url }) => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const { varificationCode } = useParams();
	const navigate = useNavigate();
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};
	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	useEffect(() => {
		if (!varificationCode) {
			handleInvalidToken(navigate);
		}
	}, [varificationCode]);

	// sweetalert function--------------------------------------------------------
	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}
	// handle Submit reqst for reset password-----------------------------------
	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			let isValid = true;
			if (!newPassword && !confirmPassword) {
				Sweetalert("Password & Confirm Password");
				isValid = false;
			} else {
				if (!newPassword) {
					Sweetalert("Password");
					isValid = false;
				}
				if (!confirmPassword) {
					Sweetalert("Confirm password");
					isValid = false;
				}
				if (newPassword.length < 6 || newPassword.length > 12) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password should be between 6 and 12 characters.",
					});
					isValid = false;
				}
				if (newPassword !== confirmPassword) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password and confirm Password doesn't match",
					});
					isValid = false;
				}
			}
			if (!isValid) {
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "resetPasswordAction");
			formData.append("new_password", newPassword);
			formData.append("confirm_password", confirmPassword);
			formData.append("verification_code", varificationCode);

			axios
				.post(`${base_url}api/user/forgot-password`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Password Changed",
							text: "Your password have been changed successfully.",
						});
						handleInvalidToken(navigate);
					} else {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	return (
		<>
			<section className="login-box-wrapper">
				<AnimationSpinner show={loading} />
				<div className="container-fluid">
					<div className="login-box">
						<div className="row justify-content-center align-items-center h-100">
							<div className="col-lg-7 d-none d-lg-block">
								<div className="left-login-container ">
									<img src={loginImg} alt="Login image" />
								</div>
							</div>
							<div className="col-md-8 col-lg-5 mx-auto">
								<div className="right-login-container mx-auto">
									<div className="inner-login-box">
										<div className="login-content">
											<div className="login-head col-md-10 col-lg-9 mx-auto text-center">
												<div className="login-logo">
													<img src={darkLogo} alt="Logo" />
												</div>
												<h2 className="title fs-lg-2">
													Welcome To TeamBuddy AI
												</h2>
												<h6 className="sub-title fs-6">
													Reset your password below.
												</h6>
											</div>

											<div className="row p-0">
												<div className="col-md-10 col-lg-12 mx-auto">
													{/* <h6 className="sub-title mb-2 login-content d-none d-md-none d-lg-block">
														Reset Password
													</h6> */}
													<form onSubmit={handleSubmit}>
														<div className="row mt-2">
															<div className="col-md-12 ">
																<div className="form-group">
																	<div
																		data-mdb-input-init
																		className="form-outline "
																	>
																		<label htmlFor="form3Example1">
																			Enter Password
																		</label>
																		<div className="position-relative">
																			<input
																				type={
																					!showNewPassword ? "password" : "text"
																				}
																				id="form3Example1"
																				className="form-control login-from-control mt-1"
																				placeholder="Enter your Password"
																				onChange={({ target: { value } }) =>
																					setNewPassword(value)
																				}
																			/>
																			<div
																				className="login-input-icon position-absolute"
																				onClick={handleShowNewPassword}
																				style={{ cursor: "pointer" }}
																			>
																				{showNewPassword ? (
																					<FaRegEyeSlash />
																				) : (
																					<FaRegEye />
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12 ">
																<div className="form-group">
																	<div
																		data-mdb-input-init
																		className="form-outline"
																	>
																		<label htmlFor="form3Example2">
																			Confirm Password
																		</label>
																		<div className="position-relative">
																			<input
																				type={
																					!showConfirmPassword ? "password" : "text"
																				}
																				id="form3Example2"
																				className="form-control login-from-control mt-1"
																				placeholder="Confrim Password"
																				onChange={({ target: { value } }) =>
																					setConfirmPassword(value)
																				}
																			/>
																			<div
																				className="login-input-icon position-absolute"
																				onClick={handleShowConfirmPassword}
																				style={{ cursor: "pointer" }}
																			>
																				{showConfirmPassword ? (
																					<FaRegEyeSlash />
																				) : (
																					<FaRegEye />
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																<button
																	type="submit"
																	data-mdb-button-init
																	data-mdb-ripple-init
																	className="btn w-100 login-button"
																>
																	Reset Password
																</button>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																<div className="forgot-link ">
																	<span className="go-back">Go back to </span>
																	<Link to="/login" className="login-link">
																		Login
																	</Link>
																</div>
																</div>
															</div>
														</div>
														
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPassReset;
