import React, { useState, useEffect } from "react";
import './Train.styles.css';

import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Train = ({ base_url }) => {

	const navigate = useNavigate();
	const { projectIds } = useParams();
	const [activeTab, setActiveTab] = useState('upload');
	const [uploadFile, setUploadFile] = useState(null);
	const [projectInfo, setProjectInfo] = useState('');
	const [websiteURL, setWebsiteURL] = useState('');
	const [oldUploadFile, setOldUploadFile] = useState('');
	const [loading, setLoading] = useState(false)
	const DESCRIPTION_LIMIT = 3000;

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setUploadFile(file);
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append('action', 'getProjectTasks');
				formData.append('token', token);
				formData.append('project_ids', projectIds);
				const response = await axios.post(`${base_url}user/api/process-resource`, formData);

				if (response.data.status) {
					setLoading(false)

					if (response.data.items.source_type !== '' && response.data.items.source !== '') {
						setActiveTab(response.data.items.source_type);
						if (response.data.items.source_type === 'upload' && response.data.items.source_path !== '') {
							setOldUploadFile([{ name: response.data.items.source, url: response.data.items.source_path }]);
						} else if (response.data.items.source_type === 'write') {
							setProjectInfo(response.data.items.source);
						} else if (response.data.items.source_type === 'import') {
							setWebsiteURL(response.data.items.source);
						}

					}

				} else {
					setLoading(false)
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: error.response?.data?.message || error.message || 'Something went wrong. Please try again.',
				});
			}
		};

		fetchData();
	}, [navigate, base_url, projectIds]);

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		setUploadFile(file);
	};

	const handleSubmit = () => {
		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}


		if (activeTab === 'upload' && (uploadFile === '' || uploadFile === null)) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Please upload a file to proceed.",
			});
			return;
		}

		if (activeTab === 'write' && projectInfo === '') {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Please enter project information to proceed.",
			});
			return;
		}
		if (activeTab === 'write' && projectInfo.length < 100) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Content must be at least 100 characters.",
			});
			return;
		}

		if (activeTab === 'write' && projectInfo.length > 5000) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Content cannot exceed 1000 characters.",
			});
			return;
		}




		if (activeTab === 'import' && websiteURL === '') {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Please enter the website url to import.",
			});
			return;
		}



		if (activeTab === 'import' && (websiteURL.includes('.mp4') || websiteURL.includes('.mp3') || websiteURL.includes('.png') || websiteURL.includes('.jpg') || websiteURL.includes('.jpeg'))) {
			Swal.fire({
				icon: "error",
				title: "Validation Error",
				text: "Please import valid url.",
			});
			return;
		}
		setLoading(true)
		const formData = new FormData();

		formData.append("token", token);
		formData.append("action", "setProjectTask");
		formData.append("project_ids", projectIds);
		formData.append("type", activeTab);

		if (activeTab === 'import') {
			formData.append("tasks", websiteURL);
		} else if (activeTab === 'write') {
			formData.append("tasks", projectInfo);
		} else {
			formData.append("tasks", uploadFile);
		}



		axios.post(`${base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "Project Added",
						text: "Your Project details have been added successfully.",
					});
					navigate("/manage-project", { replace: true });
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};


	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title"> Project Tasks</h4>
								</div>
								<div className="row my-5">
									<div className="col-lg-12 mx-auto">
										<div className="upload-interface">
											<div className="d-flex justify-content-between align-items-center flex-wrap">
												<div className="tab-container">
													<button className={`tab ${activeTab === 'write' ? 'active' : ''}`} onClick={() => setActiveTab('write')}>Write</button>
													<button className={`tab ${activeTab === 'upload' ? 'active' : ''}`} onClick={() => setActiveTab('upload')}>Upload</button>
													<button className={`tab ${activeTab === 'import' ? 'active' : ''}`} onClick={() => setActiveTab('import')}>Import Website</button>
												</div>
												<div className="submit-button-container">
													<button className="btn btn-primary submit-btn" onClick={handleSubmit}>Submit</button>
												</div>
											</div>

											{activeTab === 'upload' && (
												<div className="upload-section">
													<h4 className="div-title">Upload Your Document</h4>
													<div className="file-drop-area" onDragOver={handleDragOver} onDrop={handleDrop}>
														<input type="file" id="fileInput" className="file-input" accept=".txt,.pdf" onChange={handleFileChange} />
														<label htmlFor="fileInput" className="file-input-label">
															<i className="upload-icon"></i>
															{uploadFile ? `File: ${uploadFile.name}` : 'Click to upload or drag and drop file'}
														</label>
													</div>
													{oldUploadFile && oldUploadFile.length > 0 && (
														<a target="_blank" rel="noopener noreferrer" href={oldUploadFile[0].url}>
															{oldUploadFile[0].name}
														</a>
													)}										</div>
											)}

											{activeTab === 'write' && (
												<div className="write-section">
													<h4 className="div-title">Write Project Information</h4>
													<textarea placeholder="Enter project information here..." className="write-textarea" value={projectInfo} onChange={(e) => setProjectInfo(e.target.value)} maxLength={DESCRIPTION_LIMIT}
													></textarea>
													<small className="instuction-text">{projectInfo.length}/{DESCRIPTION_LIMIT}</small>

												</div>
											)}

											{activeTab === 'import' && (
												<div className="import-section">
													<h4 className="div-title">Import from Website URL</h4>
													<input type="url" placeholder="Enter website URL" className="form-control custom-form-control" value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)} />
												</div>
											)}
										</div>
									</div>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Train;
