// utils/fetchMenuPermissions.js
import axios from "axios";
import Swal from "sweetalert2";

export const fetchMenuPermissions = async (token, base_url, setPermissions) => {
	try {
		if (!token) {
			return;
		}

		const formData = new FormData();
		formData.append("action", "getPermissions");
		formData.append("token", token);

		const response = await axios.post(`${base_url}user/api/process-resource`, formData);

		if (response.data.status === true) {
			setPermissions(response.data.menu_permission, response.data.package_access, response.data.features);
		}
	} catch (error) {
		Swal.fire({
			icon: "error",
			title: "Error",
			text:
				error.response?.data?.message ||
				error.message ||
				"Something went wrong. Please try again.",
		});
	}
};
