import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";

// bootstrap imports
import { Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { IoIosPeople } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import { FaGlobe } from "react-icons/fa";

const MembershipList = (props) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [showURL, setShowURL] = useState(true);
	const [domainModel, setShowDomainModel] = useState(false);
	const [selectedMembership, setSelectedMembership] = useState({});
	const [domain, setDomain] = useState("");
	const [url, setUrl] = useState("");

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const handleEditNameClick = (ids,) => {
		navigate(`/edit-membership/${ids}`);
	};

	const handleStaffList = (ids) => {
		navigate(`/manage-membership-staff/${ids}`);
	}
	const handleViewSite = (sub_domain) => {
		const currentDomain = window.location.hostname; // Gets the current domain
		const domainPrefix = currentDomain.split('.')[0]; // Gets the first part of the domain (subdomain)
		
		if (showURL) {
			window.open(`https://${sub_domain}.${domainPrefix}.com/home/`, '_blank');
		}
	}
	


	// core functons--------------------------------------------------------
	// fetching membership list--------------------------------------------------
	const fetchMemberships = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == "") {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "getMembershipList");
			formData.append("token", token);
			formData.append("offset", currentPage);
			formData.append("limit", itemsPerPage ? itemsPerPage : 10);
			formData.append("search", searchTerm);
			const response = await axios.post(
				`${props.base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				const updatedMemberships = response.data.items.map((membership) => {
					const createdAt = new Date(membership.created * 1000);
					membership.created = createdAt.toLocaleString();
					return membership;
				});
				setItems(updatedMemberships);
				setTotalItems(response.data.total_items || 0);
				setShowURL(response.data.show_url);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	useEffect(() => {
		fetchMemberships();
	}, [currentPage, navigate, props.base_url, searchTerm, itemsPerPage, domainModel]);


	// deleting membership function--------------------------------------------------

	const handledomainclick = async (ids) => {
		const selectedItem = items.find(item => item.ids === ids);
		setDomain(selectedItem.custom_domain)
		setSelectedMembership(selectedItem)
		setShowDomainModel(true)
	}
	const closeModel = () => {
		setShowDomainModel(false);
		setSelectedMembership({})

	};

	const handleCustomDomainUpdate = async (e) => {
		e.preventDefault();

		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			if (selectedMembership.customDomain !== '') {
				const trimmedDomain = domain.trim();
				if (trimmedDomain.length > 30) {
					Swal.fire({
						icon: "error",
						title: "Invalid Domain",
						text: "Custom domain must not be longer than 30 characters.",
					});
					return;
				}
				if (trimmedDomain != '' && !trimmedDomain.endsWith('.com')) {
					Swal.fire({
						icon: "error",
						title: "Invalid Domain",
						text: "Domain must end with '.com'.",
					});
					return;
				}
				const cleanDomain = trimmedDomain.replace(/^(https?:\/\/)?(www\.)?/i, '');
				const domainRegex = /^(?!\-)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;

				if (trimmedDomain != '' && !domainRegex.test(cleanDomain)) {
					Swal.fire({
						icon: "error",
						title: "Invalid Domain",
						text: "Please add a valid domain without http/https/www.",
					});
					return;
				}
			}


			setLoading(true);
			const formData = new FormData();
			formData.append("action", "updateMembershipDomain");
			formData.append("token", token);
			formData.append("membership_ids", selectedMembership.ids);
			formData.append("custom_domain", domain);
			const response = await axios.post(
				`${props.base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: "Custom domain updated",
					text: "Custom domain updated successfully.",
				});
				setShowDomainModel(false)
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Failed to update custom domain. Please try again.",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const handleDeleteMembership = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "Warning: Deleting this item will permanently remove it, including all associated membership site settings and AI staff linked to the list for sale. This action cannot be undone.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "deleteMembership");
				formData.append("token", token);
				formData.append("ids", ids);
				const response = await axios.post(
					`${props.base_url}api/user/get-membership-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					fetchMemberships();
					// setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your Membership has been deleted.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire("Error", "Failed to delete Membership", "error");
			}
		}
	};
	// ------------------------------------------- end of core functions---------------------------------------------------------------------
	useEffect(() => {
		setCurrentPage(1);
	}, [itemsPerPage]);

	useEffect(() => {

		const email = localStorage.getItem("email");
		if (email) {
			const filteredUrl = email.split('@')[0];
			setUrl(filteredUrl);
		}
	}, []);
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const buttonText = domain != '' ? "Update" : "Add";
	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="row">
									<div className="col-lg-12">
										<div className="head-part d-flex align-items-center justify-content-between mx-0">
											<h3 className="title">Membership List</h3>
											<div>
												<Link to="/create-membership">
													<button
														type="button"
														className="btn btn-primary mr-3 mb-2"
													>
														New membership
													</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div>
										<div
											id="dataTable_agency_user_wrapper"
											className="dataTables_wrapper dt-bootstrap4 no-footer"
										>
											<div className="row align-items-center">
												<div className="col-sm-12 col-md-6">
													<div
														className="dataTables_length"
														id="dataTable_agency_user_length"
													>
														<label className="d-flex align-items-center">
															show
															<select
																id="formRange"
																className="form-select custom-form-select"
																value={itemsPerPage}
																onChange={(e) =>
																	setItemsPerPage(e.target.value)
																}
															>
																<option value="10">10</option>
																<option value="25">25</option>
																<option value="50">50</option>
															</select>
															entries
														</label>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div
														id="dataTable_agency_user_filter"
														className="dataTables_filter"
													>
														<label className="form-label w-100">
															<input
																type="search"
																className="form-control custom-form-control mt-2"
																placeholder="Search for a Membership"
																aria-controls="dataTable_agency_user"
																value={searchTerm}
																onChange={(e) => setSearchTerm(e.target.value)}
															/>
														</label>
													</div>
												</div>
											</div>
											{items.length > 0 ? (
												<div className="row">
													<div className="col-sm-12">
														<div className="table-responsive">
															<table
																id="dataTable_agency_user"
																className="table table-striped w-100 dataTable no-footer"
																role="grid"
																aria-describedby="dataTable_agency_user_info"
																style={{ width: "1067px" }}
															>
																<thead className="table-head">
																	<tr role="row">
																		<th>Status</th>
																		<th>Sub domain</th>
																		<th>Created Date</th>
																		<th>Actions</th>
																	</tr>
																</thead>
																<tbody>
																	{items.map((item, index) => (
																		<tr
																			role="row"
																			className={
																				index % 2 == 1 ? "even" : "odd"
																			}
																			key={item.ids}
																		>
																			<td>
																				<span
																					className={`badge ${item.status == 1
																						? "text-bg-success"
																						: "text-bg-danger"
																						}`}
																				>
																					{item.status == 1
																						? "active"
																						: "inactive"}
																				</span>
																			</td>
																			<td>{item.sub_domain}</td>
																			<td>{item.created}</td>
																			<td>
																				<div className="d-flex justify-content-center alig-items-center">
																					<div title="View Site"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer" onClick={() => handleViewSite(item.sub_domain)}

																					>
																						<FaEye className="f17 text-dark fs-5" />
																					</div>
																					<div title="Edit Membership"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handleEditNameClick(item.ids)}
																					>
																						<FaRegEdit className="f17 text-success fs-5" />
																					</div>
																					<div title="Delete Membership"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handleDeleteMembership(item.ids)}
																					>
																						<MdDeleteForever className="f17 text-danger fs-5" />
																					</div>
																					<div title="Add Selling Staff"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handleStaffList(item.ids)}
																					>
																						<IoIosPeople className="f17 text-info fs-5" />
																					</div>
																					<div title="Add Custom Domain"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handledomainclick(item.ids)}
																					>
																						<FaGlobe className="f17 text-warning fs-5" />
																					</div>
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											) : (
												<div className="row mt-4 mb-5">
													<div className="col-lg-5 col-md-8 mx-auto">
														<div className="empty-box">
															<div className="card-body">
																<div className="list-empty-box">
																	<div className="icon">
																		<img
																			src={emptyIcon}
																			alt="No data available"
																		/>
																	</div>
																	<p>No data available</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="row justify-content-between align-items-center mt-4 mb-4">
												<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
													<Pagination className="mb-0">
														<Pagination.Prev
															onClick={() =>
																setCurrentPage((prev) => Math.max(prev - 1, 1))
															}
															disabled={currentPage === 1}
														/>
														{[...Array(totalPages)].map((_, index) => (
															<Pagination.Item
																key={index + 1}
																active={index + 1 === currentPage}
																onClick={() => setCurrentPage(index + 1)}
															>
																{index + 1}
															</Pagination.Item>
														))}
														<Pagination.Next
															onClick={() =>
																setCurrentPage((prev) =>
																	Math.min(prev + 1, totalPages)
																)
															}
															disabled={currentPage === totalPages}
														/>
													</Pagination>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{domainModel && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content custom-domain-model">
							<div className="modal-header">
								<h5 className="modal-title" id="custom_domainLabel">Add existing domain</h5>
								<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModel}>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-sm-12">
										<div className="row">
											<div className="col-lg-12 mt-4 mx-auto ">
												<form method="POST" id="customForm">
													<span id="domainstatus" className="text-success"> </span>
													<div className="form-group">
														<label htmlFor="custom_domain">Custom Domain</label>
														<input
															type="text"
															name="domain_custom"
															placeholder="Enter Custom Domain" className="form-control ml-10 " id="domain_custom"
															value={domain || ""}
															onChange={(e) => { setDomain(e.target.value) }}
														/>
														<p className="form-text text-muted">Enter the domain you want to connect.Please
															add your domain without http/https/www .</p>
														<div className="form-group">
															<label>Configure your DNS records</label> <br />
															<p className="des">
																You'll need to setup a DNS record to point to your <span className="text-dark fw-bold" id="domainName">
																	{selectedMembership.custom_domain ? `${selectedMembership.sub_domain}.${selectedMembership.custom_domain}` : ''}
																</span> on our server. DNS records can be setup through your domain registrars control panel. Since every registrar has a different setup, contact them for assistance if you're unsure.
															</p>
															<table className="table table-nowrap card-table">
																<thead>
																	<tr>
																		<th>Type</th>
																		<th>Record</th>
																		<th>Value</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>CNAME</td>
																		<td id="cnameRecordValue">{selectedMembership.custom_domain || ""}</td>
																		<td id="aiassistant_subdomain">
																			{`${selectedMembership.sub_domain}.teambuddyai.com`}
																		</td>

																	</tr>
																</tbody>
															</table>
															<p className="form-text text-muted">
																DNS changes may take up to 48-72 hours to take effect, although it's normally a lot faster than that. You will receive a reply when your custom domain has been activated. Please allow 1-2 business days for this process to complete.
															</p>
														</div>

													</div>
													<div className="modal-footer">
														<button className="btn btn-outline-primary mx-1" type="button" data-dismiss="modal" onClick={closeModel}>Cancel</button>
														<button type="submit" form="customForm" id="btnSubmit" className="btn btn-primary my-3" onClick={handleCustomDomainUpdate}> {buttonText}</button>
													</div>

												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MembershipList;
