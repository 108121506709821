import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";


const Create = ({ base_url }) => {
	const navigate = useNavigate();

	const [title, setTitle] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedProject, setSelectedProject] = useState("");
	const [companies, setCompanies] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [loading, setLoading] = useState(false)
	const TITLE_LIMIT = 100;

	const handleCompanyChange = (e) => {
		const companyId = e.target.value;
		setSelectedCompany(companyId);

		if (!companyId) {
			setSelectedDepartment("");
			setSelectedProject("");
			setDepartmentList([]);
			setProjectList([]);
		}
	};

	useEffect(() => {
		const fetchDataCompanyAndDepartmentAndProject = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getCompanyAndDepartmentAndProject");
				formData.append("token", token);
				formData.append("selected_company", selectedCompany);
				formData.append("selected_department", selectedDepartment);
				formData.append("selected_project", selectedProject);
				const response = await axios.post(`${base_url}api/user/communication-and-collaboration`, formData);

				if (response.data.status) {
					setLoading(false)
					setCompanies(response.data.companies || []);
					if (selectedCompany) {
						setDepartmentList(response.data.department || []);
						setProjectList(response.data.project || []);
					}
				} else {
					setLoading(false)
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
				});
			}
		};

		fetchDataCompanyAndDepartmentAndProject();
	}, [navigate, base_url, selectedCompany, selectedDepartment, selectedProject]);

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: `${field} is required!`,
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!title && !selectedCompany && !selectedDepartment && !selectedProject) {
			Sweetalert("Title, Company, Department, and Project");
			isValid = false;
		} else {
			if (!title) {
				Sweetalert("Title");
				isValid = false;
			}
			if (!selectedCompany) {
				Sweetalert("Company");
				isValid = false;
			}
			if (!selectedDepartment) {
				Sweetalert("Department");
				isValid = false;
			}
			if (!selectedProject) {
				Sweetalert("Project");
				isValid = false;
			}
		}
		if (!isValid) return;
		setLoading(true)
		const formData = new FormData();
		formData.append("title", title);
		formData.append("company_ids", selectedCompany);
		formData.append("department_ids", selectedDepartment);
		formData.append("project_ids", selectedProject);
		formData.append("token", token);
		formData.append("action", "startCommunication");

		axios
			.post(`${base_url}api/user/communication-and-collaboration`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					navigate(`/chat/${response.data.ids}`);

				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Add Communication Details</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formTitle" className="form-label">
												Title
											</label>
											<input
												type="text"
												id="formTitle"
												className="form-control custom-form-control"
												placeholder="Enter title"
												value={title}
												maxLength={TITLE_LIMIT}
												onChange={(e) => setTitle(e.target.value)}
											/>
											<small className="instuction-text">{title.length}/{TITLE_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Select Company
											</label>
											<select
												name="company"
												className="form-control custom-form-control"
												value={selectedCompany}
												onChange={handleCompanyChange}
											>
												<option value="">Select Company</option>
												{companies.map((company) => (
													<option key={company.ids} value={company.ids}>
														{company.company_name}
													</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Select Department
											</label>
											<select
												name="department"
												className="form-control custom-form-control"
												value={selectedDepartment}
												onChange={(e) => setSelectedDepartment(e.target.value)}
											>
												<option value="">{selectedCompany
													? "Select Department"
													: "Select a company first"}</option>
												{departmentList.map((department) => (
													<option key={department.ids} value={department.ids}>
														{department.department_name}
													</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectName" className="form-label">
												Select Project
											</label>
											<select
												name="project"
												className="form-control custom-form-control"
												value={selectedProject}
												onChange={(e) => setSelectedProject(e.target.value)}
											>
												<option value="">{selectedDepartment
													? "Select Project"
													: "Select a department first"}</option>
												{projectList.map((project) => (
													<option key={project.ids} value={project.ids}>
														{project.project_name}
													</option>
												))}
											</select>
										</div>
									</div>
									<button type="submit" className="btn btn-primary mt-2 mb-3">
										Add Communication
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Create;
