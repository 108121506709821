import React, { useState, useEffect } from "react";
import userImg from "../../assests/images/avtar.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";

const Profile = ({ base_url }) => {
	const [isDataFetched, setISDataFetched] = useState(false);
	const [preview, setPreview] = useState(null); 
	const [user, setUser] = useState({
		avatar: null,
		username: "",
		contact: "",
		country: "",
		city: "",
		state: "",
		zipcode: "",
		email: "",
	});
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate();

	// fetching user data
	const fetchData = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true)
			const formData = new FormData();
			formData.append("action", "getProfile");
			formData.append("token", token);
			const response = await axios.post(`${base_url}api/user/get-user-resource`, formData);

			if (response.data.status === true) {
				setLoading(false)
				const userData = response.data.items || {};
				if (!isDataFetched) {
					setUser({
						avatar: userData.avatar || null,
						username: userData.name || "",
						contact: userData.contactNo || "",
						country: userData.country || "",
						city: userData.city || "",
						state: userData.state || "",
						zipcode: userData.zipCode || "",
						email: userData.email || "",
					});
				}
				setISDataFetched(true);
			} else {
				setLoading(false)
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleAvatarChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setUser((prevUser) => ({
				...prevUser,
				avatar: file,
			}));
			setPreview(URL.createObjectURL(file)); 
		}
	};

	const handleUpdate = async (e) => {
		e.preventDefault();
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (!user.username) {
				Swal.fire({
					icon: "warning",
					title: "Name Required",
					text: "Please enter your name.",
				});
				return;
			}
			setLoading(true)
			const formData = new FormData();
			formData.append("action", "updateUserProfile");
			formData.append("token", token);
			formData.append("avatar", user.avatar);
			formData.append("username", user.username);
			formData.append("contact", user.contact);
			formData.append("country", user.country);
			formData.append("city", user.city);
			formData.append("state", user.state);
			formData.append("zipcode", user.zipcode);

			const response = await axios.post(`${base_url}api/user/get-user-resource`, formData);

			if (response.data.status === true) {
				setLoading(false)
				localStorage.setItem('username', response.data.username);
				if(response.data.avatar != '' || response.data.avatar != null){
					localStorage.setItem('avatar', response.data.avatar);
				}
				Swal.fire({
					icon: "success",
					title: "Profile Updated",
					text: "Your profile has been updated successfully.",
				});
				navigate("/dashboard", { replace: true });
			} else {
				setLoading(false)
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Update Failed",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const handleReset = (e) => {
		e.preventDefault();
		setUser({
			...user,
			avatar: null,
			username: "",
			contact: "",
			country: "",
			city: "",
			state: "",
			zipcode: "",
		});
		setPreview(null); // Clear the preview
	};

	return (
		<div className="right-container">
			<AnimationSpinner show={loading}/>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Update Your Profile</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12">
						<div className="row">
							<div className="col-lg-12 ">
								<div className="card-container">
									<div className="card">
										{/* <div className="card-header">
											<h6 className="profile-heading">Contact Information</h6>
										</div> */}
										<div className="card-body ">
											<form encType="multipart/form-data">
												<div className="row">
													<div className="col-lg-12 mb-4 ">
														<div className="form-group border-bottom">
															<label className="form-label " htmlFor="contact_no">
																User-Image
															</label>
															<div className="d-flex align-items-center flex-wrap mb-3">
																<div className="me-3">
																	<img
																			id="img"
																			src={preview || base_url + 'uploads/users/' + user.avatar || userImg}
																			className="avatar user-profile-img"
																	/>
																</div>
																<div className="img-input">
																	<input
																		type="file"
																		id="userfile"
																		name="avatar"
																		accept=".jpg,.png,.jpeg"
																		className="form-control custom-form-control"
																		onChange={handleAvatarChange}
																	/>
																</div>
																
															</div>
															
															
															
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12  ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="name">
																Name
															</label>
															<input
																type="text"
																name="name"
																placeholder="Enter Name"
																className="form-control custom-form-control"
																id="name"
																value={user?.username || ""}
																// onChange={(e)=> setUserName(e.target.value)}
																onChange={(e) =>
																	setUser({ ...user, username: e.target.value })
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="email">
																Email
															</label>
															<input
																type="text"
																name="email"
																placeholder="Enter Email"
																className="form-control custom-form-control"
																id="email"
																readOnly
																value={user?.email || ""}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="contact_no">
																Contact No.
															</label>
															<input
																type="number"
																name="contact_no"
																placeholder="Enter Contact No. "
																className="form-control custom-form-control"
																id="contact_no"
																value={user?.contact || ""}
																// onChange={(e)=> setUserContact(e.target.value)}
																onChange={(e) =>
																	setUser({ ...user, contact: e.target.value })
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="country">
																Country
															</label>
															<input
																type="text"
																name="country"
																placeholder="Enter country"
																className="form-control custom-form-control"
																id="country"
																value={user?.country}
																// onChange={(e)=> setUsercountry(e.target.value)}
																onChange={(e) =>
																	setUser({ ...user, country: e.target.value })
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="city">
																City
															</label>
															<input
																type="text"
																name="city"
																placeholder="Enter city"
																className="form-control custom-form-control"
																id="city"
																value={user?.city || ""}
																// onChange={(e)=> setUserCity(e.target.value)}
																onChange={(e) =>
																	setUser({ ...user, city: e.target.value })
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className="form-group mb-4">
															<label className="form-label" htmlFor="state">
																State
															</label>
															<input
																type="text"
																name="state"
																placeholder="Enter state"
																className="form-control custom-form-control"
																id="state"
																value={user?.state || ""}
																// onChange={(e)=> setUserState(e.target.value)}
																onChange={(e) =>
																	setUser({ ...user, state: e.target.value })
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12 ">
														<div className=" form-group mb-4">
															<label className="form-label" htmlFor="zip_code">
																Zip Code
															</label>
															<input
																type="name"
																name="zip_code"
																placeholder="Enter zip code"
																className="form-control custom-form-control"
																id="zip_code"
																value={user?.zipcode || ""}
																onChange={(e) =>
																	setUser({ ...user, zipcode: e.target.value })
																}
															/>
														</div>
													</div>
												</div>

												<div className="col-lg-12 mt-2 mb-3">
													<button
														type="submit"
														className="btn btn-primary me-2"
														onClick={handleUpdate}
													>
														Update
													</button>
													<Link>
														<button
															id="FormReset"
															className="btn btn-secondary waves-effect m-l-5"
															onClick={handleReset}
														>
															Reset
														</button>
													</Link>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
