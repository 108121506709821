import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";

// bootstrap imports
import { Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { TbPasswordUser } from "react-icons/tb";
import { FaEllipsisVertical } from "react-icons/fa6";

const TeamList = ({ base_url }) => {
	const [items, setItems] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState("");
	const [updatedTeam, setUpdatedTeam] = useState({});

	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [currentPage, setCurrentPage] = useState(1);

	// models
	const [showNameModal, setShowNameModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);

	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	// constants for range option----------------------------
	const [itemsPerPage, setItemsPerPage] = useState(10);

	// sweetalert function--------------------------------------------------------
	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}
	// model functions start----------------------------------------------
	const closeModel = () => {
		setSelectedTeam(null);
		setShowPasswordModal(false);
	};
	const handleEditPasswordClick = (ids) => {
		const team = items.find((item) => item.ids === ids);
		if (team) {
			setSelectedTeam(team);
			setShowPasswordModal(true);
		}
	};
	// model functions end--------------------------------------------------

	// core functons--------------------------------------------------------
	const handleEdit = (ids) => {
		navigate(`/edit-team/${ids}`);
	};

	// fetching agency list--------------------------------------------------
	const fetchTeams = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if(searchTerm == ''){
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "fetchTeams");
			formData.append("token", token);
			formData.append("offset", currentPage);
			formData.append("limit", itemsPerPage ? itemsPerPage : 10);
			formData.append("search", searchTerm);

			const response = await axios.post(
				`${base_url}api/user/get-team-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				const updatedTeams = response.data.items.map((Team) => {
					const createdAt = new Date(Team.created_at * 1000);
					Team.created_at = createdAt.toLocaleString();
					return Team;
				});

				setItems(updatedTeams || []);
				setTotalItems(response.data.total_items || 0);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchTeams();
	}, [currentPage, navigate, base_url, searchTerm, itemsPerPage]);

	// delete team function-----------------------------------------------------------------
	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this item!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("token", token);
				formData.append("action", "deleteTeam");
				formData.append("teamIds", ids);

				const response = await axios.post(
					`${base_url}api/user/get-team-resource`,
					formData
				);
				if (response.data.status) {
					setLoading(false);
					fetchTeams();
					Swal.fire({
						icon: "success",
						text: "Team Updated",
						text: "Your team is deleted successfully",
					});
				} else {
					setLoading(false);
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		}
	};

	// handle team passowrd change funcion --------------------------------------------------
	const handlePasswordUpdate = (e, ids) => {
		e.preventDefault();
		setShowPasswordModal(false);
		try {
			var token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			let isValid = true;
			if (!newPassword && !confirmPassword) {
				Sweetalert("Password & Confirm Password");
				isValid = false;
			} else {
				if (!newPassword) {
					Sweetalert("Password");
					isValid = false;
				}
				if (!confirmPassword) {
					Sweetalert("Confirm password");
					isValid = false;
				}
				if (newPassword.length < 6 || newPassword.length > 12) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password should be between 6 and 12 characters.",
					});
					isValid = false;
				}
				if (newPassword !== confirmPassword) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password and confirm Password doesn't match",
					});
					isValid = false;
				}
			}
			if (!isValid) {
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "changeTeamPassword");

			formData.append("new_password", newPassword);
			formData.append("teamIds", ids);

			axios
				.post(`${base_url}api/user/get-team-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						setNewPassword("");
						setConfirmPassword("");
						Swal.fire({
							icon: "success",
							title: "Password Changed",
							text: "Your password have been changed successfully.",
						});
					} else {
						setLoading(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	useEffect(() => {
		setCurrentPage(1);
	}, [itemsPerPage]);
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="row">
									<div className="col-lg-12">
										<div className="head-part d-flex align-items-center justify-content-between mx-0">
											<h3 className="title">Team Member List</h3>
											<div>
												<Link to="/create-team">
													<button
														type="button"
														className="btn btn-primary mr-3 mb-2"
													>
														Add Team Member
													</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div>
										<div
											id="dataTable_agency_user_wrapper"
											className="dataTables_wrapper dt-bootstrap4 no-footer"
										>
											<div className="row align-items-center">
												<div className="col-sm-12 col-md-6">
													<div
														className="dataTables_length"
														id="dataTable_agency_user_length"
													>
														<label className="d-flex align-items-center">
															show
															<select
																id="formRange"
																className="form-select custom-form-select"
																value={itemsPerPage}
																onChange={(e) =>
																	setItemsPerPage(e.target.value)
																}
															>
																<option value="10">10</option>
																<option value="25">25</option>
																<option value="50">50</option>
															</select>
															entries
														</label>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div
														id="dataTable_agency_user_filter"
														className="dataTables_filter"
													>
														<label className="form-label w-100">
															<input
																type="search"
																className="form-control custom-form-control mt-2"
																placeholder="Search for a Team Member..."
																aria-controls="dataTable_agency_user"
																value={searchTerm}
																onChange={(e) => setSearchTerm(e.target.value)}
															/>
														</label>
													</div>
												</div>
											</div>
											{items.length > 0 ? (
												<div className="row">
													<div className="col-sm-12">
														<div className="table-responsive">
															<table
																id="dataTable_agency_user"
																className="table table-striped w-100 dataTable no-footer"
																role="grid"
																aria-describedby="dataTable_agency_user_info"
																style={{ width: "1067px" }}
															>
																<thead className="table-head">
																	<tr role="row">
																		<th>Status</th>
																		<th>Created Date</th>
																		<th>Email Address</th>
																		<th>Fullname</th>
																		<th>Actions</th>
																	</tr>
																</thead>
																<tbody>
																	{items.map((team, index) => (
																		<tr
																			role="row"
																			className={
																				index % 2 == 1 ? "even" : "odd"
																			}
																			key={team.ids}
																		>
																			<td>
																				<span
																					className={`badge ${
																						team.status == 1
																							? "text-bg-success"
																							: "text-bg-danger"
																					}`}
																				>
																					{team.status == 1
																						? "active"
																						: "inactive"}
																				</span>
																			</td>
																			<td>{team.created_at}</td>
																			<td>{team.email}</td>
																			<td>{team.name}</td>
																			<td>
																				<Dropdown>
																					<Dropdown.Toggle
																						id="dropdown-no-caret"
																						as="button"
																						className="btn btn-light"
																					>
																						<FaEllipsisVertical />
																					</Dropdown.Toggle>
																					<Dropdown.Menu>
																						<Dropdown.Item
																							onClick={() =>
																								handleEdit(team.ids)
																							}
																						>
																							<FaRegEdit className="f17 text-success me-2 mb-1" />
																							Edit
																						</Dropdown.Item>
																						<Dropdown.Item
																							onClick={() =>
																								handleDelete(team.ids)
																							}
																						>
																							<MdDeleteForever className="f17 text-danger me-2 mb-1" />
																							Delete
																						</Dropdown.Item>
																						<Dropdown.Item
																							onClick={() =>
																								handleEditPasswordClick(
																									team.ids
																								)
																							}
																						>
																							<TbPasswordUser className="f17 text-primary me-2 mb-1" />
																							Change password
																						</Dropdown.Item>
																					</Dropdown.Menu>
																				</Dropdown>
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											) : (
												<div className="row mt-4 mb-5">
													<div className="col-lg-5 col-md-8 mx-auto">
														<div className="empty-box">
															<div className="card-body">
																<div className="list-empty-box">
																	<div className="icon">
																		<img
																			src={emptyIcon}
																			alt="No data available"
																		/>
																	</div>
																	<p>No data available</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="row justify-content-between align-items-center mt-4 mb-4">
												<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
													<Pagination className="mb-0">
														<Pagination.Prev
															onClick={() =>
																setCurrentPage((prev) => Math.max(prev - 1, 1))
															}
															disabled={currentPage === 1}
														/>
														{[...Array(totalPages)].map((_, index) => (
															<Pagination.Item
																key={index + 1}
																active={index + 1 === currentPage}
																onClick={() => setCurrentPage(index + 1)}
															>
																{index + 1}
															</Pagination.Item>
														))}
														<Pagination.Next
															onClick={() =>
																setCurrentPage((prev) =>
																	Math.min(prev + 1, totalPages)
																)
															}
															disabled={currentPage === totalPages}
														/>
													</Pagination>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showPasswordModal && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									Change Password for "{selectedTeam.name}"
								</h5>
								<button type="button" className="close" onClick={closeModel}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={(e) => handlePasswordUpdate(e, selectedTeam)}>
									<div className="mb-3">
										<label className="form-label">New Password</label>
										<input
											type="password"
											className="form-control custom-form-control"
											autoComplete="new-password"
											value={newPassword}
											onChange={({ target: { value } }) =>
												setNewPassword(value)
											}
										/>
									</div>
									<div className="mb-3">
										<label className="form-label">Confirm New Password</label>
										<input
											type="password"
											className="form-control custom-form-control"
											autoComplete="new-password"
											value={confirmPassword}
											onChange={({ target: { value } }) =>
												setConfirmPassword(value)
											}
										/>
									</div>
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TeamList;
