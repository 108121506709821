import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import AnimationSpinner from "../../../AnimationSpinner";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
const TeamCreate = ({ base_url }) => {
	const [team, setTeam] = useState({
		teamName: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [menuList, setMenuList] = useState([]);
	const [permissions, setPermissions] = useState([]);

	// alert function -------------------------------------------------------------------------------------
	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	// funciton to create new Team -------------------------------------------------------------------------
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			var token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			let isValid = true;
			if (
				!team.teamName &&
				!team.email &&
				!team.password &&
				!team.confirmPassword &&
				!permissions
			) {
				setIsSubmitted(false);
				Sweetalert("team Name, Email , Password & Permissions ");
				isValid = false;
			} else {
				setIsSubmitted(false);
				if (!team.teamName) {
					Sweetalert("team Name");
					isValid = false;
				} else if (permissions == '') {
					Sweetalert("Permissions");
					isValid = false;
				} else if (!team.email) {
					Sweetalert("Email");
					isValid = false;
				} else if (!team.password) {
					Sweetalert("Password");
					isValid = false;
				} else if (!team.confirmPassword) {
					Sweetalert("Confirm Password");
					isValid = false;
				} else if (team.password.length < 6 || team.password.length > 12) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password should be between 6 and 12 characters.",
					});
					isValid = false;
				} else if (team.password !== team.confirmPassword) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password and confirm password do not match!",
					});
					isValid = false;
				}
			}
			if (!isValid) {
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "createTeam");
			formData.append("teamName", team.teamName);
			formData.append("email", team.email);
			formData.append("password", team.password);
			formData.append("permissions", JSON.stringify(permissions));

			axios
				.post(`${base_url}api/user/get-team-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Team Added",
							text: "Your team member have been added successfully.",
						});
						navigate("/manage-team", { replace: true });
						setIsSubmitted(false);
					} else {
						setLoading(false);
						setIsSubmitted(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			setIsSubmitted(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const fetchMenuList = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "fetchMenuList");

			await axios
				.post(`${base_url}api/user/get-team-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setMenuList(response.data.items);
					} else {
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
					}
				});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchMenuList();
	}, []);

	const groupedItems = menuList.reduce((acc, item) => {
		if (!acc[item.type]) acc[item.type] = [];
		acc[item.type].push({ value: Number(item.id), label: item.name });
		return acc;
	}, {});

	const handleSelectionChange = (selectedOptions) => {
		const selectedIdsArray = selectedOptions
			? selectedOptions.map((option) => option.value)
			: [];
		setPermissions(selectedIdsArray);
	};

	const groupedOptions = Object.entries(groupedItems).map(
		([category, items]) => ({
			label: category,
			options: items,
		})
	);

	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 col-md-10 mx-auto">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h6 className="title">Add New Team Member</h6>
								</div>
								<form
									onSubmit={handleSubmit}
									acceptCharset="utf-8"
									autoComplete="off"
								>
									<div className="card-body">
										<input
											type="hidden"
											id="base_url"
											name="base_url"
											value=""
										/>
										<div className="row form-group mb-0">
											<div className="col-md-6 mb-3">
												<label className="form-label">
													Team Member Name <span className="text-danger">*</span>
												</label>
												<input
													type="text"
													name="first_name"
													placeholder="Enter team name"
													value={team.teamName}
													onChange={(e) =>
														setTeam({ ...team, teamName: e.target.value })
													}
													id="first_name"
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Email Address <span className="text-danger">*</span>
												</label>
												<input
													type="text"
													name="email_address"
													value={team.email}
													placeholder="Enter team email"
													onChange={(e) =>
														setTeam({ ...team, email: e.target.value })
													}
													id="email_address"
													className="form-control custom-form-control"
													autoComplete="new-password"
												/>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Select access for team member
												</label>
												<Select
													isMulti
													options={groupedOptions}
													onChange={handleSelectionChange}
													closeMenuOnSelect={false}
													aria-label="Select the permissions"
													placeholder="Select the permissions"
													styles={{
														groupHeading: (base) => ({
															...base,
															fontSize: "14px",
															color: "#333",
														}),
														multiValueLabel: (base) => ({
															...base,
															fontSize: "14px",
															color: "black",
														}),
														placeholder: (base) => ({
															...base,
															color: "#0000008a",
															fontSize: "14px",
														}),
														option: (base) => ({
															...base,
															color: "#0202029f",
															fontSize: "14px",
															padding: "10px",
														}),
														menu: (base) => ({
															...base,
															maxHeight: '200px',
															overflowY: 'auto',
															scrollbarWidth: 'none'
														}),
														menuList: (base) => ({
															...base,
															padding: 0,
														}),
													}}
													value={permissions.map((id) => {
														for (let group of groupedOptions) {
															const selectedOption = group.options.find(
																(option) => option.value === id
															);
															if (selectedOption) return selectedOption;
														}
														return null;
													})}
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Password <span className="text-danger">*</span>
												</label>
												<input
													type="password"
													name="password"
													placeholder="Enter password"
													value={team.password}
													onChange={(e) =>
														setTeam({ ...team, password: e.target.value })
													}
													id="password"
													className="form-control custom-form-control"
													autoComplete="new-password"
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="form-label">
													Confirm Password{" "}
													<span className="text-danger">*</span>{" "}
												</label>
												<input
													type="password"
													name="confirm_password"
													placeholder="Confirm password"
													value={team.confirmPassword}
													onChange={(e) =>
														setTeam({
															...team,
															confirmPassword: e.target.value,
														})
													}
													id="confirm_password"
													className="form-control custom-form-control"
												/>
											</div>
										</div>
									</div>
									<button
										name="btn_submit_block"
										type="submit"
										className="btn btn-primary px-4 mt-2 mb-2 mt-2"
										id="btn_submit_block"
									>
										Create
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamCreate;
