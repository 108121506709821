import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";
import { Form, Badge } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import { FaCircleInfo } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCreateNewFolder } from "react-icons/md"

const List = (props) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const [staffCustomization, setStaffCustomization] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [negativePrompt, setNegativePrompt] = useState("");
	const [hiringStaffIds, setHiringStaffIds] = useState("");
	const [aiStaffImage, setAiStaffImage] = useState(null);
	const [companys, setCompany] = useState([]);
	const [companyIds, setCompanyIds] = useState(null);
	const [hiringStatus, setHiringStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [teamMemberDetails, setTeamMemberDetails] = useState([]);
	const [category, setCategory] = useState("")

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				if (searchTerm == '') {
					setLoading(true);
				}
				const formData = new FormData();
				formData.append("action", "getPreBuiltAIStaffLists");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				formData.append("type", "custom");
				formData.append("category", category);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems || 0);
					setTeamMemberDetails(response.data.teamMemberDetails || []);

				} else {
					if (response.data.reset) {
						setLoading(false);
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [currentPage, navigate, props.base_url, searchTerm, itemsPerPage, category]);

	useEffect(() => {
		const fetchDataCompany = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "getUserCompanyLists");
				formData.append("token", token);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					if (response.data.items === "") {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Please Create Company First",
						});
						handleClose();
						return;
					}
					setShowModal(true);
					setCompany(response.data.items || []);
				} else {
					setLoading(false);
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		if (hiringStatus) fetchDataCompany();
	}, [navigate, props.base_url, hiringStatus]);
	const handleHiring = (ids) => {
		const item = items.find((item) => item.ids === ids);
		if (item) {
			setStaffCustomization(item);
			setNegativePrompt("");
			// setShowModal(true);
			setHiringStatus(true);
			setHiringStaffIds(ids);
		}
	};

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this ai agent!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, fire it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "removeCustomAIAgent");
				formData.append("token", token);
				formData.append("agent_ids", ids);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Fired!", "Your ai agent has been delete.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				// console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to delete ai agent", "error");
			}
		}
	};

	const handleClose = () => {
		setShowModal(false);
		setHiringStatus(false);
		setStaffCustomization(null);
		setNegativePrompt("");
	};

	const handleAiStaffImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setAiStaffImage(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		if (!staffCustomization.name) {
			Sweetalert("AI Staff Name");
			return;
		}
		if (!staffCustomization.description) {
			Sweetalert("AI Staff Description");
			return;
		}
		if (!companyIds) {
			Sweetalert("Company");
			return;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "hiringStaff");
		formData.append("name", staffCustomization.name);
		formData.append("description", staffCustomization.description);
		formData.append("ai_staff_image", aiStaffImage);
		formData.append("negative_prompt", negativePrompt);
		formData.append("hiring_staff_ids", hiringStaffIds);
		formData.append("company_ids", companyIds);

		axios
			.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Hiring Successful",
						text: "Your company staff has been hired successfully.",
					});
					navigate("/manage-staff", { replace: true });
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	const handleEdit = (ids) => {
		navigate(`/edit-custom-agents/${ids}`);
	};
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<Form.Group controlid="search" className="company-search-bar">
									<div className="head-part d-flex justify-content-between w-100 align-items-center">
										<h4 className=" title"> Manage AI Agents</h4>
										<Form.Control
											className="custom-form-control w-50 mb-2"
											type="text"
											placeholder="Search for AI Agent..."
											value={searchTerm}
											onChange={(e) => {
												setSearchTerm(e.target.value);
												setCurrentPage(1);
											}}
										/>
										<div className="col-lg-4 custom-form-control mb-2">
											<select name="category" value={category} onChange={(e) => setCategory(e.target.value)} className="form-select custom-form-select"
												id="category">
												<option value="">All Category</option>
												<option value="Business Development">Business Development</option>
												<option value="Content Creation">Content Creation</option>
												<option value="Comedy">Comedy</option>
												<option value="Copywriting">Copywriting</option>
												<option value="Creative Design">Creative Design</option>
												<option value="Culinary Arts">Culinary Arts</option>
												<option value="Digital Marketing">Digital Marketing</option>
												<option value="Education">Education</option>
												<option value="Finance">Finance</option>
												<option value="Health & Fitness">Health & Fitness</option>
												<option value="Health & Wellness">Health & Wellness</option>
												<option value="Home Improvement">Home Improvement</option>
												<option value="Human Resources">Human Resources</option>
												<option value="Law">Law</option>
												<option value="Marketing">Marketing</option>
												<option value="Marketing & Advertising">Marketing & Advertising</option>
												<option value="Marketing & Communications">Marketing & Communications</option>
												<option value="Marketing & Social Media">Marketing & Social Media</option>
												<option value="Personal Development">Personal Development</option>
												<option value="Product Management">Product Management</option>
												<option value="Real Estate">Real Estate</option>
												<option value="Research & Development">Research & Development</option>
												<option value="Sales">Sales</option>
												<option value="SEO">SEO</option>
												<option value="SEO & Content Marketing">SEO & Content Marketing</option>
												<option value="Social Media">Social Media</option>
												<option value="Social Media & Customer Engagement">Social Media & Customer Engagement</option>
												<option value="Social Media Marketing">Social Media Marketing</option>
												<option value="Social Media Marketing Pioneer">Social Media Marketing Pioneer</option>
												<option value="Software Development">Software Development</option>
												<option value="Tech Writing">Tech Writing</option>
												<option value="Therapy">Therapy</option>
												<option value="Travel">Travel</option>
											</select>
										</div>
									</div>
								</Form.Group>


								{items.length > 0 ? (
									<div className="row">
										{items.map((item) => {
											const created_by = teamMemberDetails.find(
												(member) => member.ids === item.created_by
											);
											const modified_by = teamMemberDetails.find(
												(member) => member.ids === item.modified_by
											);

											return (<div
												className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4"
												key={item.ids}
											>
												<div className="card staff-div">
													<div className="hover-effect">
														<div className="staff-member-image">
															<img
																className="card-img-top"
																src={item.avatar}
																alt={item.name}
															/>
														</div>
														<div className="card-body">
															<h5 className="name ">{item.name}</h5>
															<h6 className="profile ">{item.field}</h6>
														</div>
														<div className="hover-btn">
															<h6 className="description-text ">
																{item.description}
															</h6>

															{props.menuFullAccess || props.userPermissions.includes('delete_agents') ? (

																<button
																	className="btn btn-danger btn-sm me-1 mb-1"
																	onClick={() => handleDelete(item.ids)}
																>
																	Delete
																</button>
															) : null}

															{props.menuFullAccess || props.userPermissions.includes('edit_agents') ? (

																<button
																	className="btn btn-info btn-sm me-1 mb-1"
																	onClick={() => handleEdit(item.ids)}
																>
																	Edit
																</button>
															) : null}
															{props.menuFullAccess || props.userPermissions.includes('hire_agents') ? (

																<button
																	className="btn btn-primary btn-sm me-1 mb-1"
																	onClick={() => handleHiring(item.ids)}
																>
																	Hire
																</button>
															) : null}
															{(created_by?.name || modified_by?.name) && (
																<Dropdown className="info-dropdown">
																	<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-secondary">
																		Team Member <FaCircleInfo />
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		{created_by?.name && (
																			<Dropdown.Item>
																				<MdOutlineCreateNewFolder className="f17 text-success me-2 mb-1" />
																				<span className="staf-menu-dropdown-desc">Created By:</span> <Badge bg="secondary">{created_by.name}</Badge>
																			</Dropdown.Item>
																		)}
																		{modified_by?.name && (
																			<Dropdown.Item>
																				<FaRegEdit className="f17 text-success me-2 mb-1" />
																				<span className="staf-menu-dropdown-desc">Modified By: </span><Badge bg="secondary">{modified_by.name}</Badge>
																			</Dropdown.Item>
																		)}
																	</Dropdown.Menu>
																</Dropdown>
															)}

														</div>
													</div>
												</div>
											</div>
											)
										})}
									</div>
								) : (
									<div className="row mt-4 mb-5">
										<div className="col-lg-5 col-md-8 mx-auto">
											<div className="empty-box">
												<div className="card-body">
													<div className="list-empty-box">
														<div className="icon">
															<img src={emptyIcon} alt="No Data available" />
														</div>
														<p>No data available</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								<nav aria-label="Page navigation">
									<ul className="pagination w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
										<li
											className={`page-item ${currentPage === 1 ? "disabled" : ""
												}`}
										>
											<button
												className="page-link"
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											>
												Previous
											</button>
										</li>
										{[...Array(totalPages)].map((_, index) => (
											<li
												className={`page-item ${index + 1 === currentPage ? "active" : ""
													}`}
												key={index + 1}
											>
												<button
													className="page-link"
													onClick={() => setCurrentPage(index + 1)}
												>
													{index + 1}
												</button>
											</li>
										))}
										<li
											className={`page-item ${currentPage === totalPages ? "disabled" : ""
												}`}
										>
											<button
												className="page-link"
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(prev + 1, totalPages)
													)
												}
											>
												Next
											</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>

				{/* Modal */}
				{showModal && (
					<div className="modal d-block">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Staff Details</h5>
									<button type="button" className="close" onClick={handleClose}>
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<form onSubmit={handleSubmit}>
										<div className="mb-3">
											<select
												name="company_ids"
												className="form-select"
												onChange={(e) => setCompanyIds(e.target.value)}
											>
												<option value="">select company</option>
												{companys.map((company) => (
													<option key={company.ids} value={company.ids}>
														{company.company_name}
													</option>
												))}
											</select>
										</div>
										<div className="mb-3">
											<label>AI Staff Name</label>
											<input
												type="text"
												className="form-control custom-form-control"
												value={staffCustomization.name}
												onChange={(e) =>
													setStaffCustomization({
														...staffCustomization,
														name: e.target.value,
													})
												}
											/>
										</div>
										<div className="mb-3">
											<label>Description</label>
											<textarea
												className="form-control custom-form-control"
												value={staffCustomization.description}
												onChange={(e) =>
													setStaffCustomization({
														...staffCustomization,
														description: e.target.value,
													})
												}
											/>
										</div>
										<div className="mb-3">
											<label>Upload Image</label>
											{/* <input type="file" accept='.jpg,.jpeg,.png' onChange={handleAiStaffImageChange} className="form-control" /> */}
											<input
												type="file"
												id="avatar"
												accept=".jpg,.jpeg,.png"
												className="form-control form-radius upload-img"
												onChange={handleAiStaffImageChange}
											/>
										</div>
										<div className="mb-3">
											<label>Negative Prompt</label>
											<input
												type="text"
												className="form-control custom-form-control"
												value={negativePrompt}
												onChange={(e) => setNegativePrompt(e.target.value)}
											/>
										</div>
										<button type="submit" className="btn btn-primary">
											Submit
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default List;
