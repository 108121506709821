import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import "./Sidebar.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FaTools } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { BsBuildingsFill, BsMicrosoftTeams } from "react-icons/bs";
import { FaGifts } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaWandSparkles } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { FaDesktop } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GiUpgrade } from "react-icons/gi";
import { FaUserPlus } from "react-icons/fa6";
import { AiFillProject } from "react-icons/ai";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaBuilding } from "react-icons/fa";
import { ImEmbed2 } from "react-icons/im";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaCrown } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa6";
import { FaStore } from "react-icons/fa";
import { SiAudiobookshelf } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { FaGift } from "react-icons/fa";

const Sidebar = () => {
	const { menuPermissionArray, menuFullPermission, isSidebarOpen, setIsSidebarOpen, isSmallScreen, clearPermissions, packageAccess, features } = useContext(AppContext);

	// https://aiebookpal.com/enter/teem-buddy-ebook?user=
	// https://aiebookpal.com/enter/teem-buddy-audiobook
	// ?user=
	// https://aiebookpal.com/enter/teem-buddy-store?user=
	const [activeTab, setActiveTab] = useState(null); // For main menu
	const [ebookURL, setEbookURL] = useState('');
	const [audiobookURL, setAudiobookURL] = useState('');
	const [storeURL, setStoreURL] = useState('');

	const navigate = useNavigate();

	const handleLogout = () => {
		Swal.fire({
			title: "Are you sure you want to log out?",
			icon: "warning",
			showDenyButton: true,
			confirmButtonText: "Yes, log me out",
			denyButtonText: "No, stay logged in",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Logged out",
					"You have been successfully logged out.",
					"success"
				);
				clearPermissions();
				handleInvalidToken(navigate);
			} else if (result.isDenied) {
				Swal.fire("Stayed logged in", "You chose to stay logged in.", "info");
			}
		});
	};
	useEffect(() => {
		var email = localStorage.getItem('email');
		setEbookURL('https://aiebookpal.com/enter/teem-buddy-ebook?user=' + btoa(email));
		setAudiobookURL('https://aiebookpal.com/enter/teem-buddy-audiobook?user=' + btoa(email));
		setStoreURL('https://aiebookpal.com/enter/teem-buddy-store?user=' + btoa(email));
	  }, []);
	


	const handleFeatureAccess = (feature) => {
		return feature ? packageAccess.includes(feature) : true;
	};

	const handleAccessDenied = (feature) => {
		const featureInfo = features.find((feat) => feat.value === feature);
		const upgradeUrl = featureInfo?.upgrade_url;

		Swal.fire({
			title: `Unlock ${featureInfo?.name || "Feature"}!`,
			text: `${featureInfo?.upgrade_title || "Upgrade your plan to access this feature."}`,
			icon: "info",
			showCancelButton: false,
			confirmButtonText: "Upgrade Now",
		}).then((result) => {
			if (result.isConfirmed) {
				window.open(upgradeUrl, "_blank");
			}
		});
	};

	const handleMenuClick = (feature, url, hasSubmenu, index, targetBlank) => {
		if (handleFeatureAccess(feature)) {
			if (url) {
				if (targetBlank) {
					window.open(url, "_blank");
				} else {
					navigate(url);
				}
			}
			handleItemClick(hasSubmenu, index);
		} else {
			handleAccessDenied(feature);
		}
	};

	const handleSubmenuClick = (feature, url, index) => {
		if (handleFeatureAccess(feature)) {
			navigate(url);
			handleSubmenuItemClick();
		} else {
			handleAccessDenied(feature);
		}
	};

	const handleItemClick = (hasSubmenu, index) => {
		if (isSidebarOpen) {
			if (isSmallScreen && !hasSubmenu) {
				setIsSidebarOpen(false);
			}
			setActiveTab((prev) => (prev === index ? null : index));
		} else {
			setIsSidebarOpen(true);
			setActiveTab(index);
		}
	};

	const handleSubmenuItemClick = () => {
		if (isSmallScreen) {
			setIsSidebarOpen(false);
		}
	};


	return (
		<div className={`sidebar transition ${isSidebarOpen ? "toogleOpen" : "toggleClose"}`}>
			<ul className="title mb-0 pe-0">
				{[
					{
						icon: <RiDashboardHorizontalFill />,
						label: "Dashboard",
						labelUrl: "/dashboard",
						slug: "dashboard",
					},
					{
						icon: <BsBuildingsFill />,
						label: "AI Company",
						submenu: [
							{ title: "Create", url: "/company-create", slug: "company_create" },
							{ title: "Manage", url: "/manage-company", slug: "manage_company" },
						],
					},
					{
						icon: <FaUserPlus />,
						label: "AI Staffs",
						submenu: [
							{ title: "Hire", url: "/ai-staff-hiring", slug: "ai_staff_hiring" },
							{ title: "My Staff", url: "/manage-staff", slug: "manage_staff" },
						],

					},
					{
						icon: <FaBuilding />,
						label: "AI Department",
						submenu: [
							{ title: "Create", url: "/create-department", slug: "create_department" },
							{ title: "Manage", url: "/manage-department", slug: "manage_department" },
						],

					},
					{
						icon: <AiFillProject />,
						label: "AI Project",
						submenu: [
							{ title: "Create", url: "/create-project", slug: "create_project" },
							{ title: "Manage", url: "/manage-project", slug: "manage_project" },
						],

					},
					{
						icon: <BiSolidMessageSquareDetail />,
						label: "AI Communication",
						submenu: [
							{ title: "Create", url: "/start-conversations", slug: "start_conversations" },
							{ title: "Manage", url: "/manage-conversations", slug: "manage_conversations" },
						],
					},
					{
						icon: <AiFillProject />,
						label: "Custom AI Agent",
						submenu: [
							{ title: "Create", url: "/build-custom-agents", slug: "build_custom_agents", feature: "premium" },
							{ title: "Manage", url: "/manage-agents", slug: "manage_agents", feature: "premium" },
						],
					},
					{
						icon: <ImEmbed2 />,
						label: "Embed Project",
						submenu: [
							{ title: "Create", url: "/create-embed-project", slug: "create_embed_project" },
							{ title: "Manage", url: "/manage-embed-project", slug: "manage_embed_project" },
						],
					},
					{
						icon: <FaPeopleGroup />,
						label: "Leads",
						submenu: [
							{ title: "Manage Leads", url: "/manage-leads", slug: "manage_leads", feature: "premium" },
							{ title: "Leads Inbox", url: "/leads-conversation", slug: "leads_conversation", feature: "premium" },
						],
					},
					{
						icon: <BsMicrosoftTeams />,
						label: "Teams",
						submenu: [
							{ title: "Create", url: "/create-team", slug: "create_team", feature: "premium" },
							{ title: "Manage", url: "/manage-team", slug: "manage_team", feature: "premium" },
						],
					},


					{ icon: <MdEmail />, label: "Integration", labelUrl: "/integration-settings", slug: "integration", feature: "premium" },

					{
						icon: <FaCrown />,
						label: "Membership",
						submenu: [
							{ title: "Create", url: "/create-membership", slug: "create_membership", feature: "membership" },
							{ title: "Manage", url: "/manage-membership", slug: "membership", feature: "membership" },
							{ title: "Payout Settings", url: "/membership-payout-settings", slug: "payout-settings", feature: "membership" },
							{ title: "Membership Leads", url: "/membership-leads", slug: "membership-leads", feature: "membership" }
						],
					},
					{ icon: <FaBookOpen />, label: "Ebook Edition", labelUrl: ebookURL, slug: "ebook", feature: "ebook", targetBlank: "_blank" },
					{ icon: <SiAudiobookshelf />, label: "AudioBook Edition", labelUrl: audiobookURL, slug: "audiobook", feature: "audiobook", targetBlank: "_blank" },
					{ icon: <FaStore />, label: "Store Edition", labelUrl: storeURL, slug: "store", feature: "store", targetBlank: "_blank" },
					{ icon: <FaGifts />, label: "DFY Edition", labelUrl: "/dfy", slug: "dfy", feature: "dfy" },

					{
						icon: <FaHandHoldingUsd />,
						label: "Reseller Edition",
						labelUrl: "/reseller",
						slug: "reseller",
						feature: "reseller"
					},
					{
						icon: <FaTools />,
						label: "Agency Edition",
						submenu: [
							{ title: "Create", url: "/create-agency", slug: "create_agency", feature: "agency" },
							{ title: "Manage", url: "/manage-agency", slug: "manage_agency", feature: "agency" },
						],

					},
					{
						icon: <FaWandSparkles />,
						label: "Whitelabel Edition",
						labelUrl: "/whitelabel",
						slug: "whitelabel",
						feature: "whitelabel"
					},


					{
						icon: <IoMdSettings />,
						label: "Open AI Settings",
						labelUrl: "/open-ai",
						slug: "open_ai",
					},
					{
						icon: <FaDesktop />,
						label: "Training Videos",
						labelUrl: "/training-videos",
						slug: "training_videos",

					},
					{
						icon: <MdOutlineSupportAgent />,
						label: "Support Desk",
						targetBlank: "_blank",
						labelUrl: "https://imtopvendor.tawk.help/",
						slug: "support",

					},
					{ icon: <FaGift />, label: "Bonuses", labelUrl: "https://grabteambuddyai.com/bonuses", slug: "bonuses", targetBlank: "_blank" },

					{ icon: <GiUpgrade />, label: "Upgrade Page", labelUrl: "/upgrade", slug: "upgrade" },
				].map((item, index) => (
					(menuFullPermission ||
						["dashboard", "support", "training_videos"].includes(item.slug) ||
						menuPermissionArray.includes(item.slug) ||
						(item.submenu && item.submenu.some((subitem) => menuPermissionArray.includes(subitem.slug)))) && (
						<li key={index}>
							<Button
								className={`w-100 text-start justify-content-start align-items-center text-capitalize ${activeTab === index ? "active" : ""
									}`}
								onClick={() => handleMenuClick(item.feature, item.labelUrl, !!item.submenu, index, item.targetBlank)}
							>
								<span className="icon d-flex align-items-center justify-content-center">
									{item.icon}
								</span>
								{isSidebarOpen ? (
									<>
										{item.label}
										{item.submenu && (
											<span className="arrow d-flex align-items-center justify-content-center">
												<FaAngleRight />
											</span>
										)}
									</>
								) : null}
							</Button>
							{item.submenu && isSidebarOpen ? (
								<div
									className={`subMenu-wrapper transition ${activeTab === index ? "open" : "close"
										}`}
								>
									<div className="subMenu transition">
										<ul className="p-0">
											{item.submenu.map((subitem, subIndex) => (
												(menuFullPermission || menuPermissionArray.includes(subitem.slug)) && (
													<li key={subIndex} className="cursor-pointer">
														<a
															// href="javascript:void(0)"
															onClick={() =>
																handleSubmenuClick(subitem.feature, subitem.url, subIndex)
															}
														>
															{subitem.title}
														</a>
													</li>
												)
											))}
										</ul>
									</div>
								</div>
							) : null}
						</li>
					)
				))}
			</ul>

			<div className="logout-wrapper">
				<div className="logout-box d-flex align-items-center justify-content-center">
					<Button
						variant="contained"
						onClick={handleLogout}
						className="btn btn-primary"
					>
						<FiLogOut /> Logout
					</Button>
				</div>
			</div>
		</div >
	);
};

export default Sidebar;
