import React, { useState, useEffect } from "react";
import emptyIcon from "../../../assests/images/emptyIcon.png";
import { FaCloudArrowDown } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Card, Button, Row, Col, Pagination, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import AnimationSpinner from "../../../AnimationSpinner";

// react icons
import { MdOutlineDelete } from "react-icons/md";
import { FaDownload } from "react-icons/fa6";

const Inbox = (props) => {
	const [selectedEmbedProject, setSelectedEmbedProject] = useState("");
	const [selectedLead, setSelectedLead] = useState("");
	const [selectedConversation, setSelectedConversation] = useState("");
	const [selectedChat, setSelectedChat] = useState("");
	const [selectedTitle, setSelectedTitle] = useState("");
	const [embedProjects, setEmbedProjects] = useState([]);
	const [leads, setLeads] = useState([]);
	const [leadConversations, setLeadConversations] = useState([]);
	const [leadChats, setLeadChats] = useState([]);
	const [chatMessages, setChatMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();



	const loadInitialData = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);

			const formData = new FormData();
			formData.append("action", "getLeadsConversationDetails");
			formData.append("token", token);
			formData.append("embed_project_ids", selectedEmbedProject);
			formData.append("leads_ids", selectedLead);
			formData.append("conversation_ids", selectedConversation);

			const response = await axios.post(
				`${props.base_url}api/user/get-user-resource`,
				formData
			);

			if (response.data.status) {
				setEmbedProjects(response.data.embedProject || []);
				setLeads(response.data.leads || []);
				setLeadConversations(response.data.leadConversations || []);
				setLeadChats(response.data.leadChats || []);
			} else if (response.data.reset) {
				handleInvalidToken(navigate);
			} else {
				Swal.fire("Error", response.data.message, "error");
			}
		} catch (error) {
			Swal.fire(
				"Error",
				error.response?.data?.message || "Something went wrong.",
				"error"
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadInitialData();
	}, [selectedEmbedProject, selectedLead, selectedConversation]);

	const fetchData = async (url) => {
		try {
			const response = await fetch(url);
			if (!response.ok) throw new Error(`Error: ${response.statusText}`);
			return await response.json();
		} catch (error) {
			console.error("Failed to fetch data:", error);
			Swal.fire("Something went wrong. Please try again.");
			return null;
		}
	};

	useEffect(() => {
		if (selectedChat) {
			const selected = leadChats.find(chat => chat.chat_ids === selectedChat);
			if (selected) {
				fetchData(selected.chat_file_url)
					.then(data => {
						if (data) {
							setChatMessages(data);
							setSelectedTitle(selected.title);
						} else {
							Swal.fire("Error fetching chat messages.");
						}
					})
					.catch(error => {
						console.error("Unexpected error in fetchData:", error);
						Swal.fire("Error fetching chat messages..");
					});
			}
		}
	}, [selectedChat]);


	const handleDeleteChat = async () => {
		if (!selectedChat || !selectedConversation) {
			Swal.fire("No conversation to delete!");
			return;
		}

		Swal.fire({
			title: "Are you sure you want to delete this conversation?",
			text: "This action cannot be undone.",
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: "Delete",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const token = GetToken();

				if (!token) {
					handleInvalidToken(navigate);
					return;
				}

				const formData = new FormData();
				formData.append("token", token);
				formData.append("action", "deleteLeadChat");
				formData.append("chat_ids", selectedChat);
				formData.append("conversations_ids", selectedConversation);

				try {
					const response = await axios.post(`${props.base_url}api/user/get-user-resource`, formData);

					if (response.data.status) {
						setSelectedEmbedProject("");
						setSelectedLead("");
						setSelectedConversation("");
						setSelectedChat("");
						setChatMessages([]);
						setSelectedTitle("");
					} else {
						if (response.data.reset) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
					});
				}
			} else if (result.isDenied) {
				Swal.fire("Action canceled", "The conversation was not deleted.", "info");
			}
		});
	};

	const exportConversation = () => {
		if (!chatMessages || chatMessages.length === 0) {
			Swal.fire("No conversation to export!");
			return;
		}

		const conversation = chatMessages
			.map(
				(msg) =>
					`[${msg.date}] ${msg.name} (${msg.role}):\n${msg.content}\n`
			)
			.join("\n");

		const blob = new Blob([conversation], { type: "text/plain" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = selectedTitle + ".txt";
		link.click();
		URL.revokeObjectURL(url);
	};

	return (
		<div className="right-container">
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<div className="head-part w-100">
								<div className="row align-items-center">
									<div className="col-lg-12 d-flex justify-content-between align-items=center">
										<h4 className="title">Lead Conversations</h4>

									</div>
								</div>

							</div>

							<div className="row mt-4">
								<div className="col-lg-3 col-md-6 mb-3">
									<label className="form-label">Select Embed Project</label>
									<select
										className="form-control custom-form-control"
										onChange={(e) => {
											setSelectedEmbedProject(e.target.value);
											if (e.target.value == '') {
												setSelectedLead("");
												setSelectedConversation("");
												setSelectedChat("");
												setChatMessages([]);
												setSelectedTitle("");
											}
										}}
									>
										<option value="">Select Embed Project</option>
										{embedProjects.map(project => (
											<option key={project.ids} value={project.ids}>
												{project.title}
											</option>
										))}
									</select>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<label className="form-label">Select Leads</label>
									<select
										className="form-control custom-form-control"
										onChange={(e) => {
											setSelectedLead(e.target.value);
											if (e.target.value == '') {
												setSelectedConversation("");
												setSelectedChat("");
												setChatMessages([]);
												setSelectedTitle("");
											}
										}
										}
										disabled={!selectedEmbedProject}
									>
										<option value="">
											{selectedEmbedProject
												? "Select Leads"
												: "Select an Embed Project First"}
										</option>
										{leads.map(lead => (
											<option key={lead.ids} value={lead.ids}>
												{lead.name}
											</option>
										))}
									</select>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<label className="form-label">Select Conversations</label>
									<select
										className="form-control custom-form-control"
										onChange={(e) => {
											setSelectedConversation(e.target.value);
											if (e.target.value == '') {
												setSelectedChat("");
												setChatMessages([]);
												setSelectedTitle("");
											}
										}
										}
										disabled={!selectedLead}
									>
										<option value="">
											{selectedLead
												? "Select Conversations"
												: "Select a Lead First"}
										</option>
										{leadConversations.map((conversation, index) => (
											<option key={conversation.ids} value={conversation.ids}>
												{`Conversation [${index + 1}]`}
											</option>
										))}
									</select>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<label className="form-label">Select Chat</label>
									<select
										className="form-control custom-form-control"
										onChange={(e) => {
											setSelectedChat(e.target.value);
											if (e.target.value == '') {
												setChatMessages([]);
												setSelectedTitle("");
											}
										}
										}
										disabled={!selectedConversation}
									>
										<option value="">
											{selectedConversation
												? "Select Chat"
												: "Select a Conversation First"}
										</option>
										{leadChats.map(chat => (
											<option key={chat.chat_ids} value={chat.chat_ids}>
												{chat.title}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className="Conversations-div">
								<div className="Conversations-header-div mb-2">
									<div className="left-title-box">
										<h6 className="tite">{selectedTitle}</h6>
									</div>
									<div className="right-button-box">
										{props.menuFullAccess || props.userPermissions.includes('lead_conversation_delete') ? (

											<button onClick={() => handleDeleteChat()}
												className="btn btn-danger btn-sm me-2 mb-2"
												title="Delete"
											>
												<MdOutlineDelete />
											</button>
										) : null}
										<button
											onClick={exportConversation} className="btn btn-primary btn-sm me-2 mb-2"
											title="Download"
										>
											<FaDownload />
										</button>
									</div>
								</div>
								<div className="messages-container ">
									{chatMessages.length > 0 ? (
										chatMessages.map((msg, index) => (
											<div key={index} className={`message ${msg.role}`}>
												<div className="d-flex align-items-center justify-content-end mb-2">
													{/* <span>{msg.date}</span> */}
													<span className="name">{msg.name}</span>
													<img className="img-sm" src={msg.image} alt="img" />
												</div>
												<p className="chat" dangerouslySetInnerHTML={{ __html: msg.content }} />
												<div className="actions">
													{msg.tags && (
														<span>Tags: <p className="tag"> {msg.tags.split(", ").join(", ")}</p></span>
													)}
													<span className="date-time ml-2">{msg.date}</span>
												</div>
											</div>
										))
									) : (

										<div className="empty-box">

											<div className="card-body">
												<div className="list-empty-box">
													<div className="icon">
														<img src={emptyIcon} alt="No Data" />
													</div>
													<p> {
														!selectedEmbedProject ? "Please select an embed project" :
															!selectedLead ? "Please select a lead" :
																!selectedConversation ? "Please select a conversation" :
																	!selectedChat ? "Please select a chat" :
																		chatMessages.length === 0 ? "No messages available" :
																			null
													}</p>
												</div>
											</div>
										</div>


									)}
								</div>
							</div>

							{/* message-container code here */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Inbox;
