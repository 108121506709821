import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Payout = ({ base_url }) => {
	const navigate = useNavigate();
	const [payoutDetails, setPayoutDetails] = useState({
		sandbox_client_id: "",
		sandbox_secret_key: "",
		production_client_id: "",
		production_secret_key: "",
	});

	const [loading, setLoading] = useState(false);

	const fetchPayoutDetails = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);

			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "fetchPayoutDetails");

			const response = await axios.post(`${base_url}api/user/get-membership-resource`, formData);

			if (response.data.status) {
				setLoading(false);
				setPayoutDetails({
					sandbox_client_id: response.data.details.sandbox_client_id || "",
					sandbox_secret_key: response.data.details.sandbox_secret_key || "",
					production_client_id: response.data.details.production_client_id || "",
					production_secret_key: response.data.details.production_secret_key || "",
				});
			} else {
				setLoading(false);
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchPayoutDetails();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);

			const formData = new FormData();
			formData.append("action", "updatePayoutDetails");
			formData.append("token", token);
			formData.append("sandbox_client_id", payoutDetails.sandbox_client_id);
			formData.append("sandbox_secret_key", payoutDetails.sandbox_secret_key);
			formData.append("production_client_id", payoutDetails.production_client_id);
			formData.append("production_secret_key", payoutDetails.production_secret_key);

			const response = await axios.post(
				`${base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: "Updated Successfully",
					text: "Payout details updated successfully.",
				});
			} else {
				setLoading(false);
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Paypal Payout Details</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="sandboxClientId" className="form-label">
												Sandbox Client ID
											</label>
											<input
												type="text"
												id="sandboxClientId"
												className="form-control custom-form-control"
												placeholder="Enter Sandbox Client ID"
												value={payoutDetails.sandbox_client_id}
												onChange={(e) =>
													setPayoutDetails((prev) => ({
														...prev,
														sandbox_client_id: e.target.value,
													}))
												}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="sandboxSecretKey" className="form-label">
												Sandbox Secret Key
											</label>
											<input
												type="text"
												id="sandboxSecretKey"
												className="form-control custom-form-control"
												placeholder="Enter Sandbox Secret Key"
												value={payoutDetails.sandbox_secret_key}
												onChange={(e) =>
													setPayoutDetails((prev) => ({
														...prev,
														sandbox_secret_key: e.target.value,
													}))
												}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="productionClientId" className="form-label">
												Production Client ID
											</label>
											<input
												type="text"
												id="productionClientId"
												className="form-control custom-form-control"
												placeholder="Enter Production Client ID"
												value={payoutDetails.production_client_id}
												onChange={(e) =>
													setPayoutDetails((prev) => ({
														...prev,
														production_client_id: e.target.value,
													}))
												}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="productionSecretKey" className="form-label">
												Production Secret Key
											</label>
											<input
												type="text"
												id="productionSecretKey"
												className="form-control custom-form-control"
												placeholder="Enter Production Secret Key"
												value={payoutDetails.production_secret_key}
												onChange={(e) =>
													setPayoutDetails((prev) => ({
														...prev,
														production_secret_key: e.target.value,
													}))
												}
											/>
										</div>
									</div>
									<button type="submit" className="btn btn-primary mt-2">
										Update Payout
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Payout;
