import React, { useState } from "react";
import "./Create.styles.css";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Create = (props) => {
	const navigate = useNavigate();

	const [agentName, setAgentName] = useState("");
	const [description, setDescription] = useState("");
	const [gender, setGender] = useState("");
	const [avatar, setAvatar] = useState(null);
	const [field, setField] = useState("");
	const [prompt, setPrompt] = useState("");
	const [loading, setLoading] = useState(false)
	const [category, setCategory] = useState("")

	// Define character limits
	const AGENT_NAME_LIMIT = 50;
	const DESCRIPTION_LIMIT = 300;
	const PROMPT_LIMIT = 300;
	const Field = 50;

	const handleAvatarChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setAvatar(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		const token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		// Validation for empty fields
		let isValid = true;
		if (!agentName && !description && !prompt && !gender && !field && !avatar && !category) {
			Sweetalert("Agent Name, Description, Instruction, Gender, Field ,Category& Avatar");
			isValid = false;
		} else {
			if (!agentName) {
				Sweetalert("Agent Name");
				isValid = false;
			}
			if (!description) {
				Sweetalert("Description");
				isValid = false;
			}
			if (!prompt) {
				Sweetalert("Instruction");
				isValid = false;
			}
			if (!gender) {
				Sweetalert("Gender");
				isValid = false;
			}
			if (!field) {
				Sweetalert("Field");
				isValid = false;
			}
			if (!avatar) {
				Sweetalert("Avatar");
				isValid = false;
			}
			if (!category) {
				Sweetalert("Category");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}
		setLoading(true)
		const formData = new FormData();
		formData.append("agent_name", agentName);
		formData.append("description", description);
		formData.append("prompt", prompt);
		formData.append("gender", gender);
		formData.append("field", field);
		formData.append("avatar", avatar);
		formData.append("category", category);
		formData.append("token", token);
		formData.append("action", "buildCustomAIAgent");

		axios.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "AI Agent Added",
						text: "Your custom AI agent was created successfully.",
					});
					navigate("/manage-agents", { replace: true });
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Add AI Agent Details</h4>
								</div>

								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-lg-4  mb-3">
											<label htmlFor="formAgentName" className="form-label">
												Agent Name
											</label>
											<input
												type="text"
												id="formAgentName"
												className="form-control custom-form-control"
												placeholder="Enter agent name"
												value={agentName}
												onChange={(e) => setAgentName(e.target.value)}
												maxLength={AGENT_NAME_LIMIT}
											/>
											<small className="instuction-text">{agentName.length}/{AGENT_NAME_LIMIT} characters</small>
										</div>
										<div className="col-lg-4  mb-3">
											<label htmlFor="formGender" className="form-label">
												Gender
											</label>
											<select
												id="formGender"
												className="form-select custom-form-select"
												value={gender}
												onChange={(e) => setGender(e.target.value)}
											>
												<option value="">Select gender</option>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
											</select>
										</div>
										<div className="col-lg-4  mb-3">
											<label htmlFor="formCategory" className="form-label">
												Category
											</label>
											<select name="category" value={category} onChange={(e) => setCategory(e.target.value)} className="form-select custom-form-select"
												id="category">
												<option value="">Select Category</option>

												<option value="Business Development">Business Development</option>
												<option value="Content Creation">Content Creation</option>
												<option value="Comedy">Comedy</option>
												<option value="Copywriting">Copywriting</option>
												<option value="Creative Design">Creative Design</option>
												<option value="Culinary Arts">Culinary Arts</option>
												<option value="Digital Marketing">Digital Marketing</option>
												<option value="Education">Education</option>
												<option value="Finance">Finance</option>
												<option value="Health & Fitness">Health & Fitness</option>
												<option value="Health & Wellness">Health & Wellness</option>
												<option value="Home Improvement">Home Improvement</option>
												<option value="Human Resources">Human Resources</option>
												<option value="Law">Law</option>
												<option value="Marketing">Marketing</option>
												<option value="Marketing & Advertising">Marketing & Advertising</option>
												<option value="Marketing & Communications">Marketing & Communications</option>
												<option value="Marketing & Social Media">Marketing & Social Media</option>
												<option value="Personal Development">Personal Development</option>
												<option value="Product Management">Product Management</option>
												<option value="Real Estate">Real Estate</option>
												<option value="Research & Development">Research & Development</option>
												<option value="Sales">Sales</option>
												<option value="SEO">SEO</option>
												<option value="SEO & Content Marketing">SEO & Content Marketing</option>
												<option value="Social Media">Social Media</option>
												<option value="Social Media & Customer Engagement">Social Media & Customer Engagement</option>
												<option value="Social Media Marketing">Social Media Marketing</option>
												<option value="Social Media Marketing Pioneer">Social Media Marketing Pioneer</option>
												<option value="Software Development">Software Development</option>
												<option value="Tech Writing">Tech Writing</option>
												<option value="Therapy">Therapy</option>
												<option value="Travel">Travel</option>
											</select>
										</div>
										<div className="col-lg-4  mb-3">
											<label htmlFor="formField" className="form-label">
												Field
											</label>
											<input
												type="text"
												id="formField"
												className="form-control custom-form-control"
												placeholder="Enter field of expertise"
												value={field}
												onChange={(e) => setField(e.target.value)}
												maxLength={Field}
											/>
											<small className="instuction-text">{field.length}/{Field} characters</small>

										</div>
										<div className="col-md-12 mb-3">
											<label htmlFor="formDescription" className="form-label">
												Description
											</label>
											<textarea
												id="formDescription"
												className="form-control custom-form-control"
												style={{ height: '100px' }}
												placeholder="Enter agent description"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
												maxLength={DESCRIPTION_LIMIT}
											/>
											<small className="instuction-text">{description.length}/{DESCRIPTION_LIMIT} characters</small>
										</div>
										<div className="col-md-12 mb-3">
											<label htmlFor="formPrompt" className="form-label">
												Instruction
											</label>
											<textarea
												id="formPrompt"
												className="form-control custom-form-control"
												style={{ height: '100px' }}
												placeholder="Enter instruction for AI agent"
												value={prompt}
												onChange={(e) => setPrompt(e.target.value)}
												maxLength={PROMPT_LIMIT}
											/>
											<small className="instuction-text">{prompt.length}/{PROMPT_LIMIT} characters</small>
										</div>
										<div className="col-md-12 mb-3">
											<label htmlFor="formAvatar" className="form-label">
												Avatar
											</label>
											<input
												type="file"
												id="formAvatar"
												accept=".jpg,.png,.jpeg"
												className="form-control custom-form-control"
												onChange={handleAvatarChange}
											/>
										</div>
										<div className="col-lg-12">
											<button type="submit" className="btn btn-primary mt-2 mb-3">
												Add AI Agent
											</button>
										</div>
									</div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Create;
