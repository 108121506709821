import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";
import { v4 as uuidv4 } from 'uuid';


// bootstrap imports
import { Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";


const StaffAdd = (props) => {
    const [fetchedStaff, setFetchedStaff] = useState([])
    const [items, setItems] = useState([]);
    const [name, setName] = useState("");
    const [staffId, setStaffId] = useState("")
    const [isFree, setIsFree] = useState(true)
    const [price, setPrice] = useState(0)


    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const {membershipIds} = useParams()


       // fetching staff list--------------------------------------------------
    	const fetchStaff = async () => {
    		try {
    			const token = GetToken();
    			if (!token) {
    				handleInvalidToken(navigate);
    				return;
    			}
    			if (searchTerm == "") {
    				setLoading(true);
    			}
    			const formData = new FormData();
    			formData.append("action", "getStaffList");
    			formData.append("token", token);
    			const response = await axios.post(
    				`${props.base_url}api/user/get-membership-resource`,
    				formData
    			);

    			if (response.data.status === true) {
    				setLoading(false);
    				setFetchedStaff(response.data.items);
    				setTotalItems(response.data.total_items || 0);
    			} else {
    				setLoading(false);
    				if (response.data.reset === true) {
    					handleInvalidToken(navigate);
    					return;
    				}
    				Swal.fire({
    					icon: "error",
    					title: "Error",
    					text: response.data.message,
    				});
    			}
    		} catch (error) {
    			setLoading(false);
    			Swal.fire({
    				icon: "error",
    				title: "Error",
    				text:
    					error.response?.data?.message ||
    					error.message ||
    					"Something went wrong. Please try again.",
    			});
    		}
    	};
    	useEffect(() => {
    		fetchStaff();
    	}, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedStaff = fetchedStaff.find((staff) => staff.ids === staffId);
        setFetchedStaff((prevItems) => prevItems.filter((item) => item.ids !== staffId));
        if (!staffId ) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Staff name is required.",
            });
            return;
        }
        if (!isFree && price <= 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Price is required for paid staff.",
            });
            return;
        }
        
        setItems((prev) => [
            ...prev,
            {
                ids: staffId,
                name:selectedStaff.name,
                isFree: isFree,
                price: price
            }
        ]);
       
        setName("");
        setStaffId("");
        setIsFree(true);
        setPrice(0);
    }
    
    const handleDeleteStaff = (id) => {
        const deletedStaff = items.find((item) => item.ids === id);
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.isConfirmed) {
            setFetchedStaff((prevStaff) => [...prevStaff, deletedStaff]);
            setItems((prevItems) => prevItems.filter((item) => item.ids !== id));
            Swal.fire('Deleted!', 'The staff member has been deleted.', 'success');
          }
        });
      };


    const saveStaff = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            if (searchTerm == "") {
                setLoading(true);
            }
            const formData = new FormData();
            formData.append("action", "saveMembershipStaff");
            formData.append("token", token);
            formData.append("staffs", items);
            formData.append("membership_ids", membershipIds);
            const response = await axios.post(
                `${props.base_url}api/user/get-membership-resource`,
                formData
            );

            if (response.data.status === true) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Staff saved",
                    text: "Staff saved is successfully.",
                });
                
            } else {
                setLoading(false);
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    console.log(items)
    return (
        <div className="container h-100">
            <AnimationSpinner show={loading} />
            <div className="right-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-card">
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="head-part d-flex align-items-center justify-content-between mx-0">
                                            <h3 className="title">Add Membership Staff</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div
                                            id="dataTable_agency_user_wrapper"
                                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                                        >
                                            {/* <div className="row align-items-center">
                                                <div className="col-sm-12 col-md-6">
                                                    <div
                                                        className="dataTables_length"
                                                        id="dataTable_agency_user_length"
                                                    >
                                                        <label className="d-flex align-items-center">
                                                            show
                                                            <select
                                                                id="formRange"
                                                                className="form-select custom-form-select"
                                                                value={itemsPerPage}
                                                                onChange={(e) =>
                                                                    setItemsPerPage(e.target.value)
                                                                }
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div
                                                        id="dataTable_agency_user_filter"
                                                        className="dataTables_filter"
                                                    >
                                                        <label className="form-label w-100">
                                                            <input
                                                                type="search"
                                                                className="form-control custom-form-control mt-2"
                                                                placeholder="Search for membership staff"
                                                                aria-controls="dataTable_agency_user"
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <form
                                                onSubmit={handleSubmit}
                                                acceptCharset="utf-8"
                                                autoComplete="off"
                                                className="mt-3"
                                            >

                                                <div className="card-body mx-auto">
                                                    <div className="row form-group mb-0">
                                                        <div className="col-md-3 form-group">
                                                            <label className="form-label" htmlFor="Select-Staff">Select Staff</label>
                                                            <select name="Select-Staff" className="form-control custom-form-control mb-2 w-100"
                                                                value={staffId}
                                                                onChange={(e) => setStaffId(e.target.value)}
                                                            >
                                                                <option value="">select staff</option>
                                                                {fetchedStaff && fetchedStaff.map((staff) => (
                                                                    <option key={staff.ids} value={staff.ids}>{staff.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-3 mb-3">
                                                            <label className="form-label">
                                                                Type
                                                            </label>
                                                            <select
                                                                name="Staff-type" id="staffType"
                                                                className="form-control custom-form-control mb-2 w-100"
                                                                value={isFree}
                                                                onChange={(e) => setIsFree(e.target.value === "true")}
                                                            >
                                                                <option value={true} >Free</option>
                                                                <option value={false} >Paid</option>
                                                            </select>
                                                        </div>

                                                        {isFree ? null : (<div className="col-md-3 mb-3">
                                                            <label className="form-label">
                                                                Price <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="Number"
                                                                className="form-control custom-form-control mb-2 w-100"
                                                                value={price}
                                                                onChange={(e) => setPrice(e.target.value)}
                                                            />
                                                        </div>)}

                                                        <div className="col-md-2 mb-3 d-flex align-items-end justify-content-center pb-1">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary px-4 mt-2 mb-2"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {items.length > 0 ? (
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="table-responsive">
                                                            <table
                                                                id="dataTable_agency_user"
                                                                className="table table-striped w-100 dataTable no-footer"
                                                                role="grid"
                                                                aria-describedby="dataTable_agency_user_info"
                                                                style={{ width: "1067px" }}
                                                            >
                                                                <thead className="table-head">
                                                                    <tr role="row">
                                                                        <th>Staff name</th>
                                                                        <th>Type</th>
                                                                        <th>price</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {items.map((item, index) => (
                                                                        <tr
                                                                            role="row"
                                                                            className={
                                                                                index % 2 == 1 ? "even" : "odd"
                                                                            }
                                                                            key={item.ids}
                                                                        >
                                                                            <td>{item.name}</td>
                                                                            <td>{item.isFree ? "Free" : "Paid"}</td>
                                                                            <td>{item.price}</td>
                                                                            <td>
                                                                                <div className="d-flex justify-content-center alig-items-center">
                                                                                    <div
                                                                                        className="p-2 bg-white me-2 rounded-2 cursor-pointer"
                                                                                    >
                                                                                        <FaRegEdit className="f17 text-success fs-5" />
                                                                                    </div>
                                                                                    <div className="p-2 bg-white me-2 rounded-2 cursor-pointer" 
                                                                                    onClick={()=>handleDeleteStaff(item.ids)}>
                                                                                        <MdDeleteForever className="f17 text-danger fs-5" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <div className="text-end me-2">
                                                                <button className="btn btn-primary" onClick={saveStaff}>Save staff</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row mt-4 mb-5">
                                                    <div className="col-lg-5 col-md-8 mx-auto">
                                                        <div className="empty-box">
                                                            <div className="card-body">
                                                                <div className="list-empty-box">
                                                                    <div className="icon">
                                                                        <img
                                                                            src={emptyIcon}
                                                                            alt="No data available"
                                                                        />
                                                                    </div>
                                                                    <p>Add some staff</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row justify-content-between align-items-center mt-4 mb-4">
                                                <div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
                                                    <Pagination className="mb-0">
                                                        <Pagination.Prev
                                                            onClick={() =>
                                                                setCurrentPage((prev) => Math.max(prev - 1, 1))
                                                            }
                                                            disabled={currentPage === 1}
                                                        />
                                                        {[...Array(totalPages)].map((_, index) => (
                                                            <Pagination.Item
                                                                key={index + 1}
                                                                active={index + 1 === currentPage}
                                                                onClick={() => setCurrentPage(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </Pagination.Item>
                                                        ))}
                                                        <Pagination.Next
                                                            onClick={() =>
                                                                setCurrentPage((prev) =>
                                                                    Math.min(prev + 1, totalPages)
                                                                )
                                                            }
                                                            disabled={currentPage === totalPages}
                                                        />
                                                    </Pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default StaffAdd;