import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Pagination, Form, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { replace, useNavigate } from "react-router-dom";
import emptyIcon from "../../../assests/images/emptyIcon.png";
import Dropdown from "react-bootstrap/Dropdown";

// react-icons
import { FaRegEdit, FaCode, FaEye } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { MdOutlineCreateNewFolder } from "react-icons/md"
import { MdDeleteForever } from "react-icons/md";
import AnimationSpinner from "../../../AnimationSpinner";

const List = (props) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [teamMemberDetails, setTeamMemberDetails] = useState([]);

	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedProject, setSelectedProject] = useState("");

	const [companies, setCompanies] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [projects, setProjects] = useState([]);
	const [showCodeModal, setShowCodeModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [copyText, setCopyText] = useState("");
	const [embedProjectIds, setEmbedProjectIds] = useState("");
	const [userId, setUserId] = useState("");
	const navigate = useNavigate();
	const embed_url = window.location.hostname === "localhost"
		? "http://localhost/aistaff/"
		: window.location.hostname === "192.168.29.228"
			? "http://192.168.29.228/aistaff/"
			: "https://teambuddyai.com/";
	// model functions start----------------------------------------------
	const closeModel = () => {
		setShowCodeModal(false);
	};

	const handleCodeClick = (ids) => {
		setEmbedProjectIds(ids);

		setCopyText(
			`<script src="${embed_url}chatbot.js" ui=${userId
			} epi=${ids}></script>`
		);
		setShowCodeModal(true);
	};

	const handleCopyClick = async (e) => {
		e.preventDefault();
		setShowCodeModal(true);
		if (copyText != "") {
			try {
				await window.navigator.clipboard.writeText(copyText);
				Swal.fire({
					icon: "success",
					title: "Copied Successfully",
					text: "Embed Code Copied Successfully",
				});
			} catch (err) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Unable to copy the Embed code",
				});
			}
		}
	};
	const handlePreviewClick = (ids) => {
		navigate(`chatbot?ui=${userId}&epi=${ids}`);
		const url = `${embed_url}chatbot?ui=${userId}&epi=${ids}`;
		window.open(url, "_blank");
	};
	// model functions end--------------------------------------------------

	// core funcitons ------------------------------------------------------
	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				if (searchTerm == '') {
					setLoading(true);
				}
				const formData = new FormData();
				formData.append("action", "getEmbedProject");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				formData.append("company_ids", selectedCompany);
				formData.append("department_ids", selectedDepartment);
				formData.append("project_ids", selectedProject);
				const response = await axios.post(
					`${props.base_url}api/user/embed-project`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems);
					setCompanies(response.data.company);
					setDepartments(response.data.department);
					setProjects(response.data.project);
					setUserId(response.data.userId);
					setTeamMemberDetails(response.data.teamMemberDetails || []);

				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [
		currentPage,
		navigate,
		props.base_url,
		searchTerm,
		itemsPerPage,
		selectedCompany,
		selectedDepartment,
		selectedProject,
	]);

	const handleEdit = (ids) => {
		navigate("/edit-embed-project/" + ids);
	};

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			html: `
				<p>Deleting this embed projects will <strong class="text-danger">permanently remove</strong> the following:</p>
				<ul style="text-align: left;">
					<li>Leads</li>
					<li>Lead communications</li>
				</ul>
				<p>⚠️ <strong>This action is irreversible, and you will not be able to recover this department!</strong></p>
			`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "removeEmbedProject");
				formData.append("token", token);
				formData.append("embed_project_ids", ids);
				const response = await axios.post(
					`${props.base_url}api/user/embed-project`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your item has been deleted.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to delete item", "error");
			}
		}
	};

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<div className="head-part w-100">
								<div className="row align-items-center">
									<div className="col-lg-12 col-md-4">
										<h4 className=" title"> Manage Embed Project</h4>
									</div>
								</div>
							</div>

							<div className="row" controlid="search">
								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="Search">
											Search
										</label>
										<Form.Control
											className="custom-form-control mb-2 me-2"
											type="text"
											placeholder="Search for an Embed Project..."
											value={searchTerm}
											onChange={(e) => {
												setSearchTerm(e.target.value);
												setCurrentPage(1);
											}}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="select-company">
											Select Company
										</label>
										<select
											name="company"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => {
												setSelectedCompany(e.target.value);
												if (e.target.value === "") {
													setSelectedDepartment("");
													setSelectedProject("");
												}

											}}
										>
											<option value="">All</option>
											{companies.map((company) => (
												<option key={company.ids} value={company.ids}>
													{company.company_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="select-department">
											Select Department
										</label>
										<select
											name="department"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => {
												setSelectedDepartment(e.target.value)
												if (e.target.value === "") {
													setSelectedProject("");
												}

											}}
										>
											<option value="">
												{selectedCompany ? "All" : "Select a company first"}
											</option>
											{departments.map((department) => (
												<option key={department.ids} value={department.ids}>
													{department.department_name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="project">
											Select Project
										</label>
										<select
											name="project"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => setSelectedProject(e.target.value)}
										>
											<option value="">
												{selectedDepartment
													? "All"
													: "Select a department first"}
											</option>
											{projects.map((project) => (
												<option key={project.ids} value={project.ids}>
													{project.project_name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							{items.length > 0 ? (
								<Row className="mt-3">
									{items.map((item) => {
										const created_by = teamMemberDetails.find(
											(member) => member.ids === item.created_by
										);
										const modified_by = teamMemberDetails.find(
											(member) => member.ids === item.modified_by
										);

										return (<Col
											xl={4}
											key={item.ids}
											className="col-lg-6 col-md-6 mb-4"
										>
											<Card className="company-card">
												<Card.Body>
													<Card.Title className="border-0">
														{item.title}
													</Card.Title>
													<div className="d-flex align-items-center justify-content-between flex-wrap">
														<Card.Text className="company-creation-date">
															<span>Created :</span>
															{item.created}
														</Card.Text>
														{(created_by?.name || modified_by?.name) && (
															<Dropdown className="info-dropdown">
																<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-secondary">
																	Team Member <FaCircleInfo />
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	{created_by?.name && (
																		<Dropdown.Item>
																			<MdOutlineCreateNewFolder className="f17 text-success me-2 mb-1" />
																			<span className="staf-menu-dropdown-desc">Created By:</span> <Badge bg="secondary">{created_by.name}</Badge>
																		</Dropdown.Item>
																	)}
																	{modified_by?.name && (
																		<Dropdown.Item>
																			<FaRegEdit className="f17 text-success me-2 mb-1" />
																			<span className="staf-menu-dropdown-desc">Modified By: </span><Badge bg="secondary">{modified_by.name}</Badge>
																		</Dropdown.Item>
																	)}
																</Dropdown.Menu>
															</Dropdown>
														)}

													</div>
													<div className="compay-card-btn-container mt-2 w-100 d-flex justify-content-start align-items-center flex-wrap gap-2">

														{props.menuFullAccess || props.userPermissions.includes('embed_project_edit') ? (

															<Button
																title="Edit Project"
																variant="primary"
																onClick={() => handleEdit(item.ids)}
															>
																<FaRegEdit /> Edit
															</Button>

														) : null}

														{props.menuFullAccess || props.userPermissions.includes('embed_project_view') ? (

															<Button
																title="Preview chatbot"
																variant="dark"
																onClick={() => handlePreviewClick(item.ids)}
															>
																<FaEye /> View
															</Button>
														) : null}

														{props.menuFullAccess || props.userPermissions.includes('embed_project_copy') ? (

															<Button
																title="Embed code"
																variant="dark"
																onClick={() => handleCodeClick(item.ids)}
															>
																<FaCode /> Embed
															</Button>
														) : null}

														{props.menuFullAccess || props.userPermissions.includes('embed_project_delete') ? (

															<Button
																variant="danger"
																onClick={() => handleDelete(item.ids)}
																className="ml-2"
															>
																<MdDeleteForever />
																Delete
															</Button>
														) : null}

													</div>
												</Card.Body>
											</Card>
										</Col>

										)
									})}
								</Row>
							) : (
								<div className="row mt-4 mb-5">
									<div className="col-lg-5 col-md-8 mx-auto">
										<div className="empty-box">
											<div className="card-body">
												<div className="list-empty-box">
													<div className="icon">
														<img src={emptyIcon} alt="No Data available" />
													</div>
													<p>No data available</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() =>
											setCurrentPage((prev) => Math.max(prev - 1, 1))
										}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showCodeModal && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Embed Code</h5>
								<button type="button" className="close" onClick={closeModel}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={(e) => handleCopyClick(e)}>
									<div className="mb-3">
										<label>
											<p>Insert this code in the body section between <code>&lt;body&gt;&lt;/body&gt;</code> tags in your page.</p>
										</label>
										<textarea
											className="form-control custom-form-control mb-3"
											defaultValue={copyText}
											// defaultValue="jsdlkfjls"
											rows={20}
											// cols={60}
											style={{ height: "70px" }}
											readOnly
										/>
										<Button type="submit">Copy</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
