import React, { useState, useEffect } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Integration = ({ base_url }) => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("getresponse");
	const [loading, setLoading] = useState(false);
	const [integrationDetails, setIntegrationDetails] = useState({
		getresponse: { apiKey: "", listId: "", lists: [] },
		activecampaign: { apiKey: "", url: "", listId: "", lists: [] },
		mailchimp: { apiKey: "", listId: "", lists: [] },
	});

	useEffect(() => {
		const fetchIntegrationDetails = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}

				setLoading(true);
				const formData = new FormData();
				formData.append("action", "fetchIntegrationDetails");
				formData.append("token", token);
				formData.append("type", activeTab);

				const response = await axios.post(
					`${base_url}api/user/get-integration-resource`,
					formData
				);

				if (response.data.status) {
					const { item, autoresponderList } = response.data;
					setIntegrationDetails((prev) => ({
						...prev,
						[activeTab]: {
							...prev[activeTab],
							apiKey: item?.api_token	 || "",
							url: item?.url || "",
							listId: item?.list_id || "",
							lists: autoresponderList || [],
						},
					}));
				} else if (response.data.reset) {
					handleInvalidToken(navigate);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				Swal.fire("Error", "Failed to fetch integration details", "error");
			}
		};

		fetchIntegrationDetails();
	}, [activeTab, base_url, navigate]);

	const fetchLists = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (integrationDetails[activeTab].apiKey === '') {
				Swal.fire("Error", "API Key is required.", "error");
				return;
			}

			if (activeTab === 'activecampaign' && integrationDetails[activeTab].url === '') {
				Swal.fire("Error", "URL is required for ActiveCampaign.", "error");
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "fetchIntegrationLists");
			formData.append("token", token);
			formData.append("type", activeTab);
			formData.append("apiKey", integrationDetails[activeTab].apiKey);
			if (activeTab == 'activecampaign') {
				formData.append("url", integrationDetails[activeTab].url);
			}
			const response = await axios.post(
				`${base_url}api/user/get-integration-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false);
				setIntegrationDetails((prev) => ({
					...prev,
					[activeTab]: {
						...prev[activeTab],
						lists: response.data.autoresponderList || [],
					},
				}));
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire("Error", response.data.message || "Failed to fetch lists.", "error");
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire("Error", "Failed to fetch lists. Please try again.", "error");
		}
	};

	const handleSubmit = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			if (integrationDetails[activeTab].apiKey === '') {
				Swal.fire("Error", "API Key is required.", "error");
				return;
			}

			if (activeTab === 'activecampaign' && integrationDetails[activeTab].url === '') {
				Swal.fire("Error", "URL is required for ActiveCampaign.", "error");
				return;
			}

			if (integrationDetails[activeTab].listId === '') {
				Swal.fire("Error", "Please select a list.", "error");
				return;
			}

			setLoading(true);
			const formData = new FormData();
			formData.append("action", "UpdateIntegrationDetails");
			formData.append("token", token);
			formData.append("type", activeTab);
			formData.append("apiKey", integrationDetails[activeTab].apiKey);
			formData.append("listId", integrationDetails[activeTab].listId);

			if (activeTab === 'activecampaign') {
				formData.append("url", integrationDetails[activeTab].url);
			}

			const response = await axios.post(
				`${base_url}api/user/get-integration-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false);
				setActiveTab(activeTab);
				Swal.fire("Success", "Integration updated successfully!", "success");
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire("Error", response.data.message || "Failed to update integration.", "error");
			}


		} catch (error) {
			setLoading(false);
			Swal.fire("Error", "Failed to update integration. Please try again.", "error");
		}
	};


	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="form-card">
					<div className="form-body">
						<Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
							<Nav className="nav-pills">
								<Nav.Item>
									<Nav.Link eventKey="getresponse">GetResponse</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="activecampaign">ActiveCampaign</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="mailchimp">Mailchimp</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								{["getresponse", "activecampaign", "mailchimp"].map((type) => (
									<Tab.Pane eventKey={type} key={type}>
										<div className="form-card mt-4">
											<div className="form-body">
												<h4 className="tab-title">{type.charAt(0).toUpperCase() + String(type).slice(1)} Integration</h4>
												<form>
													<div className="mb-4">
														<label htmlFor={`${type}_apiKey`} className="form-label">
															API Key
														</label>
														<input
															type="text"
															id={`${type}_apiKey`}
															className="form-control"
															placeholder="Enter API Key"
															value={integrationDetails[type].apiKey}
															onChange={(e) =>
																setIntegrationDetails((prev) => ({
																	...prev,
																	[type]: {
																		...prev[type],
																		apiKey: e.target.value,
																	},
																}))
															}
														/>
														{type === "activecampaign" && (
															<>
																<label htmlFor={`${type}_url`} className="form-label">
																	URL
																</label>
																<input
																	type="text"
																	id={`${type}_url`}
																	className="form-control"
																	placeholder="Enter URL"
																	value={integrationDetails[type].url}
																	onChange={(e) =>
																		setIntegrationDetails((prev) => ({
																			...prev,
																			[type]: {
																				...prev[type],
																				url: e.target.value,
																			},
																		}))
																	}
																/>
															</>
														)}
														<div
															className="btn btn-primary mt-2"
															onClick={fetchLists}
														>
															Get List
														</div>
													</div>
													<div className="mb-4">
														<label htmlFor={`${type}_listId`} className="form-label">
															Select List
														</label>
														<select
															id={`${type}_listId`}
															className="form-control"
															value={integrationDetails[type].listId}
															onChange={(e) =>
																setIntegrationDetails((prev) => ({
																	...prev,
																	[type]: {
																		...prev[type],
																		listId: e.target.value,
																	},
																}))
															}
														>
															<option value="">Select List</option>
															{integrationDetails[type].lists.map((list) => (
																<option key={list.id} value={list.id}>
																	{list.name}
																</option>
															))}
														</select>
													</div>
													<div
														className="btn btn-primary mt-2"
														onClick={handleSubmit}
													>
														Update Integration
													</div>
												</form>
											</div>
										</div>
									</Tab.Pane>
								))}
							</Tab.Content>
						</Tab.Container>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Integration;
