import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Create = ({ base_url }) => {
	const navigate = useNavigate();

	const [title, setTitle] = useState("");
	const [headerTitle, setHeaderTitle] = useState("");
	const [headerMessage, setHeaderMessage] = useState("");
	const [chatPosition, setChatPosition] = useState("right");
	const [chatbotIcon, setChatbotIcon] = useState(null);
	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedProject, setSelectedProject] = useState("");
	const [companies, setCompanies] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [footerText, setFooterText] = useState("");
	const [footerUrl, setFooterUrl] = useState("");
	const [preview, setPreview] = useState(null);

	const handleChatbotIconChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setChatbotIcon(file);
			setPreview(URL.createObjectURL(file));
		}
	};
	const TITLE_LIMIT = 100;
	const HT_LIMIT = 50;
	const HM_LIMIT = 100;

	const handleCompanyChange = (e) => {
		const companyId = e.target.value;
		setSelectedCompany(companyId);

		if (!companyId) {
			setSelectedDepartment("");
			setSelectedProject("");
			setDepartmentList([]);
			setProjectList([]);
		}
	};

	useEffect(() => {
		const fetchDataCompanyAndDepartmentAndProject = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "getCompanyAndDepartmentAndProject");
				formData.append("token", token);
				formData.append("selected_company", selectedCompany);
				formData.append("selected_department", selectedDepartment);
				formData.append("selected_project", selectedProject);
				const response = await axios.post(
					`${base_url}api/user/communication-and-collaboration`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					setCompanies(response.data.companies || []);
					if (selectedCompany) {
						setDepartmentList(response.data.department || []);
						setProjectList(response.data.project || []);
					}
				} else {
					setLoading(false);
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchDataCompanyAndDepartmentAndProject();
	}, [
		navigate,
		base_url,
		selectedCompany,
		selectedDepartment,
		selectedProject,
	]);

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: `${field} is required!`,
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;

		if (!title && !selectedCompany && !selectedDepartment && !selectedProject) {
			Sweetalert("Title, Company , Department & Project");
			isValid = false;
		} else {
			if (!title) {
				Sweetalert("Title");
				isValid = false;
			}
			if (!selectedCompany) {
				Sweetalert("Company");
				isValid = false;
			}

			if (!selectedDepartment) {
				Sweetalert("Department");
				isValid = false;
			}
			if (!selectedProject) {
				Sweetalert("Project");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}

		setLoading(true);
		const formData = new FormData();
		formData.append("action", "createEmbedProject");
		formData.append("token", token);
		formData.append("title", title);
		formData.append("header_title", headerTitle);
		formData.append("header_message", headerMessage);
		formData.append("chat_position", chatPosition);
		if (chatbotIcon) formData.append("chatbot_icon", chatbotIcon);
		formData.append("company_ids", selectedCompany);
		formData.append("department_ids", selectedDepartment);
		formData.append("project_ids", selectedProject);
		formData.append("footer_text", footerText);
		formData.append("footer_url", footerUrl);


		axios
			.post(`${base_url}api/user/embed-project`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Embed Added",
						text: "Your embed details have been added successfully.",
					});
					navigate(`/manage-embed-project`);
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Create Embed Project</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formTitle" className="form-label">
												Title
											</label>
											<input
												type="text"
												id="formTitle"
												className="form-control custom-form-control"
												placeholder="Enter title"
												value={title}
												maxLength={TITLE_LIMIT}
												onChange={(e) => setTitle(e.target.value)}
											/>
											<small className="instuction-text">{title.length}/{TITLE_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="headerTitle" className="form-label">
												Header Title
											</label>
											<input
												type="text"
												id="headerTitle"
												className="form-control custom-form-control"
												placeholder="Enter header title"
												value={headerTitle}
												maxLength={HT_LIMIT}
												onChange={(e) => setHeaderTitle(e.target.value)}
											/>
											<small className="instuction-text">{headerTitle.length}/{HT_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="headerMessage" className="form-label">
												Header Message
											</label>
											<input
												type="text"
												id="headerMessage"
												className="form-control custom-form-control"
												placeholder="Enter header message"
												value={headerMessage}
												maxLength={HM_LIMIT}
												onChange={(e) => setHeaderMessage(e.target.value)}
											/>
											<small className="instuction-text">{headerMessage.length}/{HM_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="chatPosition" className="form-label">
												Chatbot Position
											</label>
											<select
												id="chatPosition"
												className="form-control custom-form-control"
												value={chatPosition}
												onChange={(e) => setChatPosition(e.target.value)}
											>
												<option value="">Select Position</option>
												<option value="left">Left</option>
												<option value="right">Right</option>
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Select Company
											</label>
											<select
												name="company"
												className="form-control custom-form-control"
												value={selectedCompany}
												onChange={handleCompanyChange}
											>
												<option value="">Select Company</option>
												{companies.map((company) => (
													<option key={company.ids} value={company.ids}>
														{company.company_name}
													</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label
												htmlFor="formDepartmentName"
												className="form-label"
											>
												Select Department
											</label>
											<select
												name="department"
												className="form-control custom-form-control"
												value={selectedDepartment}
												onChange={(e) => setSelectedDepartment(e.target.value)}
											>
												<option value="">
													{selectedCompany
														? "Select Department"
														: "Select a company first"}
												</option>
												{departmentList.map((department) => (
													<option key={department.ids} value={department.ids}>
														{department.department_name}
													</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectName" className="form-label">
												Select Project
											</label>
											<select
												name="project"
												className="form-control custom-form-control"
												value={selectedProject}
												onChange={(e) => setSelectedProject(e.target.value)}
											>
												<option value="">
													{selectedDepartment
														? "Select Project"
														: "Select a department first"}
												</option>
												{projectList.map((project) => (
													<option key={project.ids} value={project.ids}>
														{project.project_name}
													</option>
												))}
											</select>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="footerText" className="form-label">
												Footer Text
											</label>
											<input
												type="text"
												id="footerText"
												className="form-control custom-form-control"
												placeholder="Enter footer text"
												value={footerText}
												onChange={(e) => setFooterText(e.target.value)}
											/>

										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="chatbotIcon" className="form-label">
												Chatbot Icon
											</label>
											<input
												type="file"
												id="chatbotIcon"
												accept=".jpg,.png,jpeg"
												className="form-control custom-form-control"
												onChange={handleChatbotIconChange}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="footerUrl" className="form-label">
												Footer Url
											</label>
											<input
												type="text"
												id="footerUrl"
												className="form-control custom-form-control"
												placeholder="Enter footer url"
												value={footerUrl}
												onChange={(e) => setFooterUrl(e.target.value)}
											/>
										</div>
										{preview === null ? null : (
											<div className="row ">
												<div className="col-md-6 mb-2">
													<img
														id="img"
														src={preview}
														className="chatboticon"
														width="60px"
														height="60px"
													/>
												</div>
											</div>
										)}
									</div>
									<button
										type="submit"
										className="btn btn-primary mt-2 mb-3 px-4"
									>
										Add
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Create;
