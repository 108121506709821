import React, { useState, useEffect } from "react";
import emptyIcon from "../../assests/images/emptyIcon.png";

import { Button, Pagination, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import { FaCloudArrowDown } from "react-icons/fa6";

import { MdDeleteForever } from "react-icons/md";
import AnimationSpinner from "../../AnimationSpinner";

const Lead = (props) => {
	const [items, setItems] = useState([{ name: "name" }]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedProject, setSelectedProject] = useState("");
	const [selectedEmbedProject, setSelectedEmbedProject] = useState("");
	const [companies, setCompanies] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [projects, setProjects] = useState([]);
	const [embedProjects, setEmbedProjects] = useState([]);
	const [showNameModal, setShowNameModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [allEmbedProjects, setAllEmbedProjects] = useState([])

	const navigate = useNavigate();

	const handleDelete = async (ids) => {
		
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "Deleting this lead will also permanently delete all associated lead communications. This action cannot be undone",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});
		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "deleteLead");
				formData.append("token", token);
				formData.append("leadIds", ids);
				const response = await axios.post(
					`${props.base_url}api/user/get-user-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your Lead has been deleted.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire("Error", "Failed to delete Lead", "error");
			}
		}

	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				if (searchTerm == '') {
					setLoading(true);
				}
				const formData = new FormData();
				formData.append("action", "getLeads");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				formData.append("company_ids", selectedCompany);
				formData.append("department_ids", selectedDepartment);
				formData.append("project_ids", selectedProject);
				formData.append("embed_project_ids", selectedEmbedProject);
				const response = await axios.post(
					`${props.base_url}api/user/get-user-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems);
					setCompanies(response.data.company);
					setDepartments(response.data.department);
					setProjects(response.data.project);
					setEmbedProjects(response.data.embedProject);
					setAllEmbedProjects(response.data.allEmbedProjects)
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [
		currentPage,
		navigate,
		props.base_url,
		searchTerm,
		itemsPerPage,
		selectedCompany,
		selectedDepartment,
		selectedProject,
		selectedEmbedProject,
		showNameModal,
	]);

	useEffect(() => {
		setCurrentPage(1);
	}, [itemsPerPage]);

	const downloadCSV = () => {
        if (items.length === 0) {
            Swal.fire("No Data", "There is no data to download.", "info");
            return;
        }

        const headers = ["Name", "Email", "Embed Project", "Created Date"];
        const rows = items.map((item) => {
            const matchingEmbedProject = allEmbedProjects.find((project) => project.ids === item.embed_project_ids);
            const embedProjectName = matchingEmbedProject ? matchingEmbedProject.title : "No name Found";
            return [item.name, item.email, embedProjectName, item.created];
        });

        const csvContent = [headers, ...rows]
            .map((row) => row.map((value) => `"${value}"`).join(","))
            .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "leads.csv";
        link.click();
        URL.revokeObjectURL(url);
    };

	const totalPages = Math.ceil(totalItems / itemsPerPage);
	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<div className="head-part w-100">
								<div className="row align-items-center justify-content-between">
									<div className="col-lg-6 col-md-6">
										<h4 className=" title"> Manage Your Leads</h4>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="d-flex align-items-center justify-content-end">

											<button
												className="btn btn-icon waves-effect waves-light btn-dark mb-2 text-center"
												onClick={downloadCSV}
												type="button"
												title="Download CSV"
											>
												<FaCloudArrowDown />
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="row " controlid="search">

								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="select-company">
											Select Company
										</label>
										<select
											name="company"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => {
												setSelectedCompany(e.target.value);
												if (e.target.value === "") {
													setSelectedDepartment("");
													setSelectedProject("");
													setSelectedEmbedProject("");
												}
											}}
										>
											<option value="">All</option>
											{companies.map((company) => (
												<option key={company.ids} value={company.ids}>
													{company.company_name}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="select-department">
											Select Department
										</label>
										<select
											name="department"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => {
												setSelectedDepartment(e.target.value)
												if (e.target.value === "") {
													setSelectedProject("");
													setSelectedEmbedProject("")
												}
											}}
										>
											<option value="">All</option>
											{departments.map((department) => (
												<option key={department.ids} value={department.ids}>
													{department.department_name}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="project">
											Select Project
										</label>
										<select
											name="project"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => {
												setSelectedProject(e.target.value)
												if (e.target.value === "") {
													setSelectedEmbedProject("");
												}
											}}
										>
											<option value="">All</option>
											{projects.map((project) => (
												<option key={project.ids} value={project.ids}>
													{project.project_name}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="project">
											Select Embed Project
										</label>
										<select
											name="project"
											className="form-control custom-form-control mb-2 me-2"
											onChange={(e) => setSelectedEmbedProject(e.target.value)}
										>
											<option value="">All</option>
											{embedProjects.map((embedProject) => (
												<option key={embedProject.ids} value={embedProject.ids}>
													{embedProject.title}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-sm-12 col-md-6">
									<div
										className="dataTables_length"
										id="dataTable_agency_user_length"
									>
										<label className="form-label">
											show

											entries
										</label>
										<select
											id="formRange"
											className="form-select custom-form-select mx-1"
											value={itemsPerPage}
											onChange={(e) => setItemsPerPage(e.target.value)}
										>
											<option value="10">10</option>
											<option value="25">25</option>
											<option value="50">50</option>
										</select>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group me-3">
										<label className="form-label" htmlFor="Search">
											Search
										</label>
										<Form.Control
											className="custom-form-control mb-2 me-2"
											type="text"
											placeholder="Search for a Lead..."
											value={searchTerm}
											onChange={(e) => {
												setSearchTerm(e.target.value);
												setCurrentPage(1);
											}}
										/>
									</div>
								</div>
							</div>

							{items.length > 0 ? (
								<div className="row">
									<div className="col-sm-12">
										<div className="table-responsive">
											<table
												id="dataTable_agency_user"
												className="table table-striped w-100 dataTable no-footer"
												role="grid"
												aria-describedby="dataTable_agency_user_info"
												style={{ width: "1067px", marginTop: "15px" }}
											>
												<thead className="table-head">
													<tr role="row">
														<th>Name</th>
														<th>Email </th>
														<th>Embed Project</th>
														<th>Created Date</th>
														{props.menuFullAccess || props.userPermissions.includes('lead_delete') ? (
															<th>Actions</th>
														) : null}
													</tr>
												</thead>
												<tbody>
													{items.map((item, index) => {
														const matchingEmbedProject = allEmbedProjects.find((project) => project.ids == item.embed_project_ids)
														return (
															<tr
																role="row"
																key={index}
																className={index % 2 == 1 ? "even" : "odd"}
															>
																<td>{item.name}</td>
																<td>{item.email}</td>
																<td>{matchingEmbedProject ? matchingEmbedProject.title : 'No name Found'}</td>
																<td>{item.created}</td>
																{props.menuFullAccess || props.userPermissions.includes('lead_delete') ? (
																	<td>
																		<Button onClick={() => handleDelete(item.ids)} >
																			<MdDeleteForever />
																		</Button>
																	</td>
																) : null}
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							) : (
								<div className="row mt-4 mb-5">
									<div className="col-lg-5 col-md-8 mx-auto">
										<div className="empty-box">
											<div className="card-body">
												<div className="list-empty-box">
													<div className="icon">
														<img src={emptyIcon} alt="No Data available" />
													</div>
													<p>No data available</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() =>
											setCurrentPage((prev) => Math.max(prev - 1, 1))
										}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Lead;
