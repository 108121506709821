// css imports
import { useContext } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// libraries imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext } from "./context/AppContext";

// component imports
import Loginpage from "./pages/loginPage";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import CompanyCreate from "./pages/company/create";
import CompanyList from "./pages/company/cp_list";
import CompanyEdit from "./pages/company/edit";
import "bootstrap/dist/css/bootstrap.min.css";
import AIStaffHiring from "./pages/aistaff/pre_built_staff";
import Dfy from "./pages/dfy";
import Reseller from "./pages/reseller";
import WhiteLabel from "./pages/whiteLabel";
import Profile from "./pages/profile";
import ResetPassword from "./pages/resetPassword";
import TrainingVideos from "./pages/trainingVideos";
import UpGradePage from "./pages/upGrade";
import ManageStaff from "./pages/aistaff/my_staff";
import BuildCustomAgents from "./pages/customAgent/build_custom_agents";
import EditCustomAgents from "./pages/customAgent/edit_costom_agents";
import ManageAgents from "./pages/customAgent/custom_agents_lists";
import CreateDepartment from "./pages/department/create";
import ManageDepartment from "./pages/department/list";
import EditDepartment from "./pages/department/edit";
import CreateProject from "./pages/project/create";
import ManageProject from "./pages/project/list";
import EditProject from "./pages/project/edit";
import ProjectTasks from "./pages/project/projecttasks";
import AgencyCreate from "./pages/agency/create";
import AgencyList from "./pages/agency/list";
import StartConversations from "./pages/communication/create";
import ProjectChat from "./pages/communication/chats";
import ManageConversations from "./pages/communication/list";
import OpenAi from "./pages/openAI"
import StaffChat from "./pages/aistaff/staff_chat/chats"
import CreateEmbedProject from "./pages/embedProject/create"
import ManageEmbedProject from "./pages/embedProject/list"
import Chatbot from "./pages/chatbot"
import ForgotPassReset from "./pages/forgotPassReset";
import EmbedProjectEdit from "./pages/embedProject/edit";
import TeamCreate from "./pages/teams/create/TeamCreate";
import ManageLeads from "./pages/leads/Lead";
import LeadConversation from "./pages/leads/conversation";
import TeamList from "./pages/teams/list/TeamList";
import TeamEdit from "./pages/teams/teamEdit/TeamEdit";
import CreateMembership from "./pages/membership/create";
import MembershipList from "./pages/membership/list";
import MembershipEdit from './pages/membership/edit'
import StaffAdd from "./pages/membership/staff_add";
import MembershipStaffList from "./pages/membership/membershipStaffList/MembershipStaffList";
import MembershipPayoutSettings from "./pages/membership/payoutSettings/Payout";
import MembershipLeads from "./pages/membership/membershipLeads/Leads";
import Integration from "./pages/membership/integration";


const AppContent = () => {
	const { base_url, isSidebarOpen, menuPermissionArray, menuFullPermission } = useContext(AppContext);
	return (
		<>
			<Routes>
				<Route
					path="/login"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/password-reset/:varificationCode"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<ForgotPassReset base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/forgot-password-request"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<ForgotPassword base_url={base_url} />
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="*"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Dashboard base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dfy"
					element={
						<ProtectedRoute packageRequiredFeature="dfy" menuRequiredPermission="dfy" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Dfy base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/reseller"
					element={
						<ProtectedRoute packageRequiredFeature="reseller" menuRequiredPermission="reseller" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Reseller base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Profile base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/pass-reset"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ResetPassword base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/whitelabel"
					element={
						<ProtectedRoute packageRequiredFeature="whitelabel" menuRequiredPermission="whitelabel" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<WhiteLabel base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/company-create"
					element={
						<ProtectedRoute menuRequiredPermission="company_create" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CompanyCreate base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/training-videos"
					element={
						<ProtectedRoute  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<TrainingVideos base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/upgrade"
					element={
						<ProtectedRoute menuRequiredPermission="upgrade"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<UpGradePage base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-company"
					element={
						<ProtectedRoute menuRequiredPermission="manage_company"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CompanyList base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-company/:companyIds"
					element={
						<ProtectedRoute menuRequiredPermission="company_edit"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CompanyEdit base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-staff-hiring"
					element={
						<ProtectedRoute menuRequiredPermission="ai_staff_hiring"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AIStaffHiring base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-staff"
					element={
						<ProtectedRoute menuRequiredPermission="manage_staff"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageStaff base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-department"
					element={
						<ProtectedRoute menuRequiredPermission="create_department"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CreateDepartment base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-department"
					element={
						<ProtectedRoute menuRequiredPermission="manage_department"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageDepartment base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-department/:departmentIds"
					element={
						<ProtectedRoute menuRequiredPermission="department_edit"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<EditDepartment base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-project"
					element={
						<ProtectedRoute menuRequiredPermission="create_project"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CreateProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-project"
					element={
						<ProtectedRoute menuRequiredPermission="manage_project"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageProject base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-project/:projectIds"
					element={
						<ProtectedRoute menuRequiredPermission="project_edit" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<EditProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/build-custom-agents"
					element={
						<ProtectedRoute packageRequiredFeature="premium" menuRequiredPermission="build_custom_agents"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<BuildCustomAgents base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-custom-agents/:agentIds"
					element={
						<ProtectedRoute packageRequiredFeature="premium" menuRequiredPermission="edit_agents"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<EditCustomAgents base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-agents"
					element={
						<ProtectedRoute packageRequiredFeature="premium" menuRequiredPermission="manage_agents"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageAgents base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/project-tasks/:projectIds"
					element={
						<ProtectedRoute menuRequiredPermission="project_task"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ProjectTasks base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/create-agency"
					element={
						<ProtectedRoute packageRequiredFeature="agency" menuRequiredPermission="create_agency"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper transition ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AgencyCreate base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-agency"
					element={
						<ProtectedRoute packageRequiredFeature="agency" menuRequiredPermission="manage_agency"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AgencyList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/start-conversations"
					element={
						<ProtectedRoute menuRequiredPermission="start_conversations"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<StartConversations base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/chat/:conversationIds"
					element={
						<ProtectedRoute menuRequiredPermission="communication_chat"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ProjectChat base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-conversations"
					element={
						<ProtectedRoute menuRequiredPermission="manage_conversations"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageConversations base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/open-ai"
					element={
						<ProtectedRoute menuRequiredPermission="open_ai"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<OpenAi base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/staff-chat/:staffIds"
					element={
						<ProtectedRoute menuRequiredPermission="ai_staff_chat"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<StaffChat base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-embed-project"
					element={
						<ProtectedRoute menuRequiredPermission="create_embed_project"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CreateEmbedProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-embed-project"
					element={
						<ProtectedRoute menuRequiredPermission="manage_embed_project"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageEmbedProject base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-embed-project/:ids"
					element={
						<ProtectedRoute menuRequiredPermission="embed_project_edit"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<EmbedProjectEdit base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/chatbot"
					element={

						<Chatbot base_url={base_url} />

					}
				/>

				<Route
					path="/create-team"
					element={
						<ProtectedRoute menuRequiredPermission="create_team" packageRequiredFeature="premium" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<TeamCreate base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-team"
					element={
						<ProtectedRoute menuRequiredPermission="manage_team" packageRequiredFeature="premium" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<TeamList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-team/:teamIds"
					element={
						<ProtectedRoute menuRequiredPermission="edit_team" packageRequiredFeature="premium" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<TeamEdit base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-leads"
					element={
						<ProtectedRoute menuRequiredPermission="manage_leads" packageRequiredFeature="premium" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageLeads base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/leads-conversation"
					element={
						<ProtectedRoute menuRequiredPermission="leads_conversation" packageRequiredFeature="premium" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<LeadConversation base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-membership"
					element={
						<ProtectedRoute menuRequiredPermission="create_membership" packageRequiredFeature="membership">
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CreateMembership base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-membership"
					element={
						<ProtectedRoute menuRequiredPermission="manage_membership" packageRequiredFeature="membership" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<MembershipList base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/edit-membership/:membershipIds"
					element={
						<ProtectedRoute packageRequiredFeature="membership" menuRequiredPermission="edit_membership">
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<MembershipEdit base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-membership-staff/:membershipIds"
					element={
						<ProtectedRoute packageRequiredFeature="membership" menuRequiredPermission="manage_membership_staff" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<MembershipStaffList base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/add-membership-staff/:membershipIds"
					element={
						<ProtectedRoute packageRequiredFeature="membership" menuRequiredPermission="add_membership_staff" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<StaffAdd base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/membership-payout-settings"
					element={
						<ProtectedRoute packageRequiredFeature="membership" menuRequiredPermission="membership_payout_settings" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<MembershipPayoutSettings base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/membership-leads"
					element={
						<ProtectedRoute packageRequiredFeature="membership" menuRequiredPermission="membership_leads" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<MembershipLeads base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/integration-settings"
					element={
						<ProtectedRoute packageRequiredFeature="premium" menuRequiredPermission="integration_settings" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Integration base_url={base_url} userPermissions={menuPermissionArray} menuFullAccess={menuFullPermission} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

			</Routes>
		</>
	);
};

function App() {
	return (
		<BrowserRouter>
			<AppContent />
		</BrowserRouter>
	);
}

export default App;
