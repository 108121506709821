import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const EmbedProjectEdit = ({ base_url }) => {
	const navigate = useNavigate();
	const { ids } = useParams();
	const [embedProject, setEmbedProject] = useState({
		title: "",
		header_title: "",
		header_message: "",
		chat_position: "",
		chatbot_icon: null,
		footer_text: null,
		footer_url: null,
	});
	const TITLE_LIMIT = 100;
	const HT_LIMIT = 50;
	const HM_LIMIT = 100;

	const [departmentName, setDepartmentName] = useState("");
	const [projectName, setProjectName] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [loading, setLoading] = useState(false);
	const [preview, setPreview] = useState("");

	useEffect(() => {
		const fetchingEmbedProject = async (ids) => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "fetchSingleEmbedProject");
				formData.append("token", token);
				formData.append("ids", ids);

				const response = await axios.post(
					`${base_url}api/user/embed-project`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					setEmbedProject(response.data.EmbedProject || []);
					setDepartmentName(response.data.department.department_name || []);
					setProjectName(response.data.project.project_name || []);
					setCompanyName(response.data.company.company_name || []);
				} else {
					setLoading(false);
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};
		fetchingEmbedProject(ids);
	}, [ids]);

	const handleIconChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setEmbedProject((prev) => ({
				...prev,
				chatbot_icon: file,
			}));
			setPreview(URL.createObjectURL(file));
		}
	};


	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "updateEmbedProject");
			formData.append("token", token);
			formData.append("ids", ids);
			formData.append("title", embedProject.title)
			formData.append("header_title", embedProject.header_title)
			formData.append("header_message", embedProject.header_message)
			formData.append("chat_position", embedProject.chat_position)
			formData.append("chatbot_icon", embedProject.chatbot_icon)
			formData.append("footer_text", embedProject.footer_text);
			formData.append("footer_url", embedProject.footer_url);

			const response = await axios.post(
				`${base_url}api/user/embed-project`,
				formData
			);

			if (response.data.status) {
				navigate("/manage-embed-project");
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: "Updated Successfully",
					text: "Embed Project Updated Successfully",
				});

			} else {
				setLoading(false);
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};



	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Edit Embed Project</h4>
								</div>
								<form onSubmit={handleSubmit} >
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formTitle" className="form-label">
												Title
											</label>
											<input
												type="text"
												id="formTitle"
												className="form-control custom-form-control"
												placeholder="Enter title"
												value={embedProject.title}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														title: e.target.value,
													}))
												}
												maxLength={TITLE_LIMIT}

											/>
											<small className="instuction-text">{embedProject.title.length}/{TITLE_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="headerTitle" className="form-label">
												Header Title
											</label>
											<input
												type="text"
												id="headerTitle"
												className="form-control custom-form-control"
												placeholder="Enter header title"
												value={embedProject.header_title}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														header_title: e.target.value,
													}))
												}
												maxLength={HT_LIMIT}

											/>
											<small className="instuction-text">{embedProject.header_title.length}/{HT_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="headerMessage" className="form-label">
												Header Message
											</label>

											<input
												type="text"
												id="headerMessage"
												className="form-control custom-form-control"
												placeholder="Enter header message"
												value={embedProject.header_message}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														header_message: e.target.value,
													}))
												}
												maxLength={HM_LIMIT}
											/>
											<small className="instuction-text">{embedProject.header_message.length}/{HM_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Company
											</label>
											<input
												name="company"
												placeholder="Enter Company Name"
												className="form-control custom-form-control"
												defaultValue={companyName}
												readOnly
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label
												htmlFor="formDepartmentName"
												className="form-label"
											>
												Department
											</label>
											<input
												name="department"
												placeholder="Enter Department Name"
												className="form-control custom-form-control"
												defaultValue={departmentName}
												readOnly
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectName" className="form-label">
												Project
											</label>
											<input
												name="project"
												placeholder="Enter project Name"
												className="form-control custom-form-control"
												defaultValue={projectName}
												readOnly
											/>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="footerText" className="form-label">
												Footer Text
											</label>
											<input
												type="text"
												id="footerText"
												className="form-control custom-form-control"
												placeholder="Enter footer text"
												value={embedProject.footer_text}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														footer_text: e.target.value,
													}))
												}

											/>

										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="footerUrl" className="form-label">
												Footer Url
											</label>
											<input
												type="text"
												id="footerUrl"
												className="form-control custom-form-control"
												placeholder="Enter footer url"
												value={embedProject.footer_url}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														footer_url: e.target.value,
													}))
												}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="chatbotIcon" className="form-label">
												Chatbot Icon
											</label>
											<input
												type="file"
												id="chatbotIcon"
												accept=".jpg,.png,jpeg"
												className="form-control custom-form-control mb-2"
												value={embedProject.chatIcon}
												onChange={handleIconChange}
											/>
											{preview || embedProject.chatbot_icon && (
												<div className="row ">
													<div className="col-lg-12 mb-2">
														<img
															id="img"
															src={preview || embedProject.chatbot_icon}
															className="chatboticon"
															width="50px"
															height="50px"
														/>
													</div>
												</div>
											)}

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="chatPosition" className="form-label">
												Chatbot Position
											</label>
											<select
												id="chatPosition"
												className="form-control custom-form-control"
												value={embedProject.chat_position}
												onChange={(e) =>
													setEmbedProject((prev) => ({
														...prev,
														chat_position: e.target.value,
													}))
												}
											>
												<option value="">Select Position</option>
												<option value="left">Left</option>
												<option value="right">Right</option>
											</select>
										</div>
									</div>
									<button type="submit" className="btn btn-primary mt-2 mb-3 px-4">
										Update
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default EmbedProjectEdit;
