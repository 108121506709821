import React, { createContext, useState, useEffect } from 'react';
import GetToken from "../GetToken";
import { fetchMenuPermissions } from "../fetchMenuPermissions";

export const AppContext = createContext();

// provider component
export const AppProvider = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(false);
	const [menuPermissionArray, setMenuPermissionArray] = useState([]);
	const [menuFullPermission, setMenuFullPermission] = useState(false);
	const [packageAccess, setPackageAccess] = useState([]);
	const [features, setFeatures] = useState([]);
	// const navigate = useNavigate();
	const base_url =
		window.location.hostname === "localhost"
			? "http://localhost/aistaff/"
			: window.location.hostname === "192.168.29.228"
				? "http://192.168.29.228/aistaff/"
				: "https://teambuddyai.com/api/";

	const setPermissions = (menu_permissions, package_access, features_array) => {
		if (menu_permissions === 0) {
			setMenuFullPermission(true);
			setMenuPermissionArray([]);
		} else {
			setMenuFullPermission(false);
			setMenuPermissionArray(menu_permissions);
		}
		setPackageAccess(package_access);
		setFeatures(features_array);
	};

	const clearPermissions = () => {
		setMenuPermissionArray([]);
		setMenuFullPermission(false);
		setPackageAccess([]);
		setFeatures([]);

	};




	const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
	useEffect(() => {
		const token = GetToken();
		if (token) {
			fetchMenuPermissions(token, base_url, setPermissions);
		}
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsSidebarOpen(false);
				setIsSmallScreen(true);
			} else {
				setIsSidebarOpen(true);
				setIsSmallScreen(false);
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<AppContext.Provider value={{ isSidebarOpen, isSmallScreen, toggleSidebar, setIsSidebarOpen, base_url, menuFullPermission, menuPermissionArray, clearPermissions, setPermissions, packageAccess, features }}>
			{children}
		</AppContext.Provider>
	);
};
