import React, { useState, useEffect } from "react";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";

import { Card, Button, Row, Col, Pagination, Form, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import { MdOutlineCreateNewFolder } from "react-icons/md";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import { FaCircleInfo } from "react-icons/fa6";

const List = (props) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [companys, setCompanys] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState("");
	const [staffCustomization, setStaffCustomization] = useState(null);
	const [aiStaffImage, setAiStaffImage] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [teamMemberDetails, setTeamMemberDetails] = useState([]);
	const navigate = useNavigate();
	const DESCRIPTION_LIMIT = 500;
	const TITLE_LIMIT = 100;
	const [preview, setPreview] = useState(null);

	const fetchData = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == '') {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "getUserStaffList");
			formData.append("token", token);
			formData.append("offset", currentPage);
			formData.append("limit", itemsPerPage);
			formData.append("search", searchTerm);
			formData.append("company_ids", selectedCompany);
			const response = await axios.post(
				`${props.base_url}user/api/process-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				setItems(response.data.items || []);
				setTotalItems(response.data.totalItems || 0);
				setCompanys(response.data.company || []);
				setTeamMemberDetails(response.data.teamMemberDetails || []);

			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	useEffect(() => {
		fetchData();
	}, [
		currentPage,
		navigate,
		props.base_url,
		searchTerm,
		itemsPerPage,
		selectedCompany,
	]);

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			html: `
				<p>Firing this staff member will <strong class="text-danger">permanently remove</strong> them from:</p>
				<ul style="text-align: left;">
					<li>All associated departments</li>
					<li>Projects</li>
					<li>Communications and chats</li>
				</ul>
				<p>⚠️ <strong>This action is irreversible, and you will not be able to recover this staff member!</strong></p>
			`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, fire them!",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "removeUserStaff");
				formData.append("token", token);
				formData.append("staff_ids", ids);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Fired!", "Your staff has been fire.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to fire staff", "error");
			}
		}
	};
	const handleHiring = (ids) => {
		const item = items.find((item) => item.ids === ids);
		if (item) {
			setStaffCustomization(item);
			setShowModal(true);
		}
	};
	const handleStaffChat = (ids) => {
		navigate(`/staff-chat/${ids}`);
	};

	const handleClose = () => {
		setShowModal(false);
		setStaffCustomization(null);
		setPreview(null)
	};

	const handleAiStaffImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setAiStaffImage(file);
		}
		setPreview(URL.createObjectURL(file));
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		if (!staffCustomization.name) {
			Sweetalert("AI Staff Name");
			return;
		}
		if (!staffCustomization.description) {
			Sweetalert("AI Staff Description");
			return;
		}
		if (!staffCustomization.company_ids) {
			Sweetalert("Company");
			return;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "editUserStaff");
		formData.append("name", staffCustomization.name);
		formData.append("description", staffCustomization.description);
		formData.append("ai_staff_image", aiStaffImage);
		formData.append("negative_prompt", staffCustomization.negative_prompt);
		formData.append("edit_staff_ids", staffCustomization.ids);
		formData.append("company_ids", staffCustomization.company_ids);

		axios
			.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: "Update Successful",
						text: "Your company staff has been updated successfully.",
					});
					handleClose();
					fetchData();
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<div className="head-part w-100">
								<div className="row align-items-center">
									<div className="col-lg-12">
										<h4 className="title"> Manage Company Staff</h4>
									</div>
								</div>
							</div>

							<div className="row company-search-bar" controlid="search">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="staff">
											Search Staff
										</label>
										<Form.Control
											className="custom-form-control mb-2 w-100 "
											type="text"
											placeholder="Search Staff..."
											value={searchTerm}
											onChange={(e) => {
												setSearchTerm(e.target.value);
												setCurrentPage(1);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label" htmlFor="select-company">
										{" "}
										Select Company
									</label>
									<select
										className="form-control custom-form-control mb-2 w-100"
										name="company"
										onChange={(e) => setSelectedCompany(e.target.value)}
									>
										<option value="">All</option>
										{companys.map((company) => (
											<option key={company.ids} value={company.ids}>
												{company.company_name}
											</option>
										))}
									</select>
								</div>
							</div>

							{items.length > 0 ? (
								<Row>
									{items.map((item) => {
										const created_by = teamMemberDetails.find(
											(member) => member.ids === item.created_by
										);
										const modified_by = teamMemberDetails.find(
											(member) => member.ids === item.modified_by
										);
										return (
											<Col
												xl={4}
												key={item.ids}
												className="col-lg-6 col-md-6 mb-4"
											>
												<div className="company-card shadow-md">
													<div className="company-card-image-container">
														<Card.Img variant="top" src={item.avatar} />
													</div>
													<Card.Body className="px-3 pt-2 pb-3">
														<Card.Title className="card-title border-0 pb-0">
															{item.name}
														</Card.Title>
														<div className="badge-new badge-top-right">
															<Card.Text className="comapny-category">
																{item.field}
															</Card.Text>
														</div>

														<Card.Text className="company-description truncated-two-lines des-text">
															{item.gender}
														</Card.Text>
														<div className="d-flex align-items-center justify-content-between flex-wrap">
															<Card.Text className="company-creation-date">
																<span>Created :</span>{" "}
																{item.created}
															</Card.Text>
															{(created_by?.name || modified_by?.name) && (
																<Dropdown className="info-dropdown">
																	<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-secondary">
																		Team Member <FaCircleInfo />
																	</Dropdown.Toggle>
																	<Dropdown.Menu className="staff-dropdown-menu">
																		{created_by?.name && (
																			<Dropdown.Item>
																				<MdOutlineCreateNewFolder className="f17 text-success me-2 mb-1" />
																				<span className="staf-menu-dropdown-desc">Created By:</span> <Badge bg="secondary">{created_by.name}</Badge>
																			</Dropdown.Item>
																		)}
																		{modified_by?.name && (
																			<Dropdown.Item>
																				<FaRegEdit className="f17 text-success me-2 mb-1" />
																				<span className="staf-menu-dropdown-desc">Modified By: </span><Badge bg="secondary">{modified_by.name}</Badge>
																			</Dropdown.Item>
																		)}
																	</Dropdown.Menu>
																</Dropdown>
															)}

														</div>
														<div className="compay-card-btn-container mt-2 w-100 d-flex justify-content-start align-items-center gap-2">

															{props.menuFullAccess || props.userPermissions.includes('ai_staff_edit') ? (
																<Button
																	variant="primary"
																	onClick={() => handleHiring(item.ids)}
																>
																	<FaRegEdit /> Edit
																</Button>
															) : null}


															{props.menuFullAccess || props.userPermissions.includes('ai_staff_chat') ? (
																<Button
																	variant="info"
																	onClick={() => handleStaffChat(item.ids)}
																>
																	<FaRegEdit /> Chat
																</Button>
															) : null}
															{props.menuFullAccess || props.userPermissions.includes('ai_staff_fire') ? (

																<Button
																	variant="danger"
																	onClick={() => handleDelete(item.ids)}
																	className="ml-2"
																>
																	<MdDeleteForever />
																	Fire
																</Button>
															) : null}

														</div>
													</Card.Body>
												</div>
											</Col>
										)
									})}
								</Row>
							) : (
								<div className="row mt-4 mb-5">
									<div className="col-lg-5 col-md-8 mx-auto">
										<div className="empty-box">
											<div className="card-body">
												<div className="list-empty-box">
													<div className="icon">
														<img src={emptyIcon} alt="No data available" />
													</div>
													<p>No data available</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() =>
											setCurrentPage((prev) => Math.max(prev - 1, 1))
										}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Form.Group controlId="search" className="company-search-bar">
				<label > Manage Company Staff</label>
				
			</Form.Group> */}

			{/* Modal */}
			{showModal && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Staff Details</h5>
								<button type="button" className="close" onClick={handleClose}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={handleSubmit}>
									<div className="mb-3">
										<label className="form-label">AI Staff Name</label>
										<input
											type="text"
											className="form-control custom-form-control"
											value={staffCustomization.name}
											onChange={(e) =>
												setStaffCustomization({
													...staffCustomization,
													name: e.target.value,
												})
											}
											maxLength={TITLE_LIMIT}
										/>
										<small className="instuction-text">{staffCustomization.name.length}/{TITLE_LIMIT}</small>

									</div>
									<div className="mb-3 ">
										<select
											disabled
											className="form-select"
											name="company_ids"
											value={staffCustomization.company_ids}
											onChange={(e) =>
												setStaffCustomization({
													...staffCustomization,
													company_ids: e.target.value,
												})
											}
										>
											{companys.map((company) => (
												<option key={company.ids} disabled value={company.ids}>
													{company.company_name}
												</option>
											))}
										</select>
									</div>
									<div className="mb-3">
										<label className="form-label">Description</label>
										<textarea
											className="form-control custom-form-control"
											value={staffCustomization.description}
											style={{ height: "100px" }}
											onChange={(e) =>
												setStaffCustomization({
													...staffCustomization,
													description: e.target.value,
												})
											}
											maxLength={DESCRIPTION_LIMIT}

										/>
										<small className="instuction-text">{staffCustomization.description.length}/{DESCRIPTION_LIMIT}</small>

									</div>
									<div className="mb-3">
										<div className="row ">
											<div className="col-lg-12 mb-4">
												<img
													id="img"
													src={preview ? preview : staffCustomization.avatar}
													className="avatar"
													width="100px"
													height="100px"
												/>
											</div>
										</div>
										<label className="form-label">Upload Image</label>

										<input
											type="file"
											id="avatar"
											accept=".jpg,.jpeg,.png"
											className="form-control form-radius upload-img"
											onChange={handleAiStaffImageChange}
										/>
									</div>
									<div className="mb-3">
										<label className="form-label">Negative Prompt</label>
										<input
											type="text"
											className="form-control custom-form-control"
											value={staffCustomization.negative_prompt}
											onChange={(e) =>
												setStaffCustomization({
													...staffCustomization,
													negative_prompt: e.target.value,
												})
											}
										/>
									</div>
									<button type="submit" className="btn btn-primary">
										Update
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
