import React, { useState, useContext } from "react";
import "../loginPage/LoginPage.styles.css";
import { Link } from "react-router-dom";
import lightLogo from "../../assests/images/White.png";
import darkLogo from "../../assests/images/dark-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import { RiLockPasswordFill } from "react-icons/ri";
import { handleInvalidToken } from "../../AuthUtils";
import loginImg from "../../assests/images/loginPage/loginpageBackground.png";
import { BsFillPersonFill } from "react-icons/bs";

const ForgotPassword = ({ base_url }) => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		try {
			let isValid = true;
			if (!email) {
				Swal.fire({
					icon: "error",
					title: "Validation Error",
					text: "Email is required.",
				});
				isValid = false;
			}
			if (!isValid) {
				return;
			}
			setLoading(true);

			const formData = new FormData();
			formData.append("action", "forgotPasswordRequest");
			formData.append("email", email);

			axios
				.post(`${base_url}api/user/forgot-password`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Email Sent",
							text: "Check your inbox for the request.",
						});
					} else {
						setLoading(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	return (
		<>
			<section className="login-box-wrapper">
				<AnimationSpinner show={loading} />
				<div className="container-fluid h-100">
					<div className="login-box h-100">
						<div className="row justify-content-center align-items-center h-100">
							<div className="col-lg-7 d-none d-lg-block">
								<div className="left-login-container">
									<img src={loginImg} alt="Login image" />
								</div>
							</div>
							<div className="col-md-8 col-lg-5 mx-auto">
								<div className="right-login-container mx-auto">
									<div className="inner-login-box">
										<div className="login-content ">
											<div className="login-head forgot-password col-md-10 col-lg-12 mx-auto">
												<div className="login-logo">
													<img src={darkLogo} alt="Logo" />
												</div>
												<h2 className="title fs-lg-2">
													Welcome To TeamBuddy AI
												</h2>
												<h6 className="sub-title fs-6 text-center">
													Enter your email to receive a password reset link.
												</h6>
											</div>

											<div className="row p-0">
												<div className="col-lg-12 mx-auto">
													{/* <h6 className="sub-title mb-2 login-content d-none d-md-none d-lg-block ">
														<span className="forgot-password">Forgot Password</span>
													</h6> */}
													<form onSubmit={handleSubmit}>
														<div className="row mt-2">
															<div className="col-md-12 ">
																<div className="form-group">
																	<div
																		data-mdb-input-init
																		className="form-outline "
																	>
																		<label htmlFor="form3Example1">
																			Enter your Email
																		</label>
																		<div className="position-relative">
																			<input
																				type="text"
																				id="form3Example1"
																				className="form-control login-from-control mt-1"
																				placeholder="Enter your Email"
																				onChange={({ target: { value } }) =>
																					setEmail(value)
																				}
																			/>
																			<div className="login-input-icon position-absolute ">
																				<BsFillPersonFill />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																<button
															type="submit"
															data-mdb-button-init
															data-mdb-ripple-init
															className="btn w-100 login-button"
														>
															send Request
														</button>
														<div className="forgot-link ">
															<span className="go-back">Go back to{" "}</span>
															<Link to="/login" className="login-link">
																Login
															</Link>
														</div>
																</div>
															</div>
														</div>
														
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPassword;
