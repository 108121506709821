import React, { useState, useEffect } from "react";
import "./Chatbot.styles.css";
import Avatar from '../../assests/images/avtar.png';
import loadingGIF from '../../assests/images/loading.gif';
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FiSend } from 'react-icons/fi';
import { FaCopy } from "react-icons/fa6";
import { TbNewSection } from "react-icons/tb";
import Select from 'react-select';
import { TbFileExport } from "react-icons/tb";

// react icons
import { FaEllipsisV } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";

import Swal from "sweetalert2";
import axios from "axios";

const Chatbot = ({ base_url }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [userIds, setUserIds] = useState("");
	const [embedProjectIds, setEmbedProjectIds] = useState("");
	const [error, setError] = useState("");

	const urlParams = new URLSearchParams(window.location.search);
	const user_ids = urlParams.get('ui');
	const embed_project_ids = urlParams.get('epi');
	const enduser_token = localStorage.getItem('enduser_token');
	const [inputValue, setInputValue] = useState("");
	const [selectedStaffs, setSelectedStaffs] = useState([]);
	const [allStaffs, setAllStaffs] = useState([]);
	const [staffs, setStaffs] = useState([]);
	var user_name = localStorage.getItem("enduser_name");
	const PROMPT_LIMIT = 300;
	const [messages, setMessages] = useState([]);
	const [lastReply, setLastReply] = useState('');
	const [title, setTitle] = useState('');
	const [chatHistory, setChatHistory] = useState(null);
	const [chatId, setChatId] = useState(null);
	const [conversationIds, setConversationIds] = useState(null);
	const [headerTitle, setHeaderTitle] = useState('Hi there 👋');
	const [headerMessage, setHeaderMessage] = useState('Need help? Search our help center for answers or start a conversation:');
	const [chatPosition, setChatPosition] = useState('');
	const [chatbotIcon, setChatbotIcon] = useState('https://cdn-icons-png.flaticon.com/512/1946/1946429.png');
	const [footerText, setFooterText] = useState('Teambuddy AI');
	const [footerUrl, setFooterUrl] = useState('');

	const fetchChatbotStyleData = async () => {
		try {

			const formData = new FormData();
			formData.append("uids", userIds);
			formData.append("epids", embedProjectIds);

			const response = await axios.post(`${base_url}api/chatbot/end-user/chatbot-style-data`, formData);

			if (response.data.status) {
				const {
					header_title,
					header_message,
					chat_position,
					chatbot_icon,
					footer_text,
					footer_url,
				} = response.data.chatbot;

				setHeaderTitle(header_title || 'Hi there 👋');
				setHeaderMessage(header_message || 'Need help? Search our help center for answers or start a conversation:');
				if (chat_position == 'right') {
					setChatPosition('');
				} else {
					setChatPosition('left-chatbaot');
				}
				setChatbotIcon(chatbot_icon || 'https://cdn-icons-png.flaticon.com/512/1946/1946429.png');
				if (footer_text != null) {

					setFooterText(footer_text || 'Teambuddy AI');
				}
				if (footer_url != null) {

					setFooterUrl(footer_url || '');
				}


			} else {
				if (response.data.reset) setIsLoggedIn(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message || "An unknown error occurred.",
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};
	// useEffect(() => {
	// 	if (footerUrl) {
	// 		const link = document.createElement('link');
	// 		link.rel = 'stylesheet';
	// 		link.href = footerUrl;
	// 		document.head.appendChild(link);

	// 		return () => {
	// 			document.head.removeChild(link);
	// 		};
	// 	}
	// }, [footerUrl]);
	useEffect(() => {
		if (!user_ids || !embed_project_ids) {
			Swal.fire({
				icon: 'error',
				title: 'Missing Parameters',
				text: 'Required parameters are missing or invalid. Please check the URL.',
			});
			return;
		}
		if (enduser_token != null) {
			setIsLoggedIn(true);
		}
		setEmbedProjectIds(embed_project_ids);
		setUserIds(user_ids);

	}, []);
	useEffect(() => {
		if (embedProjectIds && userIds) {
			fetchChatbotStyleData();
		}
	}, [embedProjectIds, userIds]);


	// Declare fetchStaff using const
	const fetchStaff = async () => {
		try {
			const enduser_token = localStorage.getItem("enduser_token");
			if (!enduser_token) {
				setIsLoggedIn(false);
				return;
			}

			if (!chatId) {
				setChatId(`${userIds.substr(0, 6)}${embedProjectIds}${generateUniqueIdWithTimestamp()}`);
			}

			const formData = new FormData();
			formData.append("action", "getChatbotData");
			formData.append("enduser_token", enduser_token);

			const response = await axios.post(`${base_url}api/chatbot/end-user/chatbot-data-kmp45jk`, formData);

			if (response.data.status) {
				if (!response.data.staffs || response.data.staffs.length === 0) {
					Swal.fire({
						icon: "error",
						title: "No Staff Found",
						text: "No hired staff found for this project.",
					});
					return;
				}

				const staffOptions = response.data.staffs.map((staff) => ({
					value: staff.ids,
					label: staff.name + '(' + staff.field + ')',
				}));

				setAllStaffs(response.data.staffs);
				setStaffs(staffOptions);
				setTitle(response.data.conversation_title);
				setConversationIds(response.data.conversation_ids);
				setChatHistory(response.data.chat_history || []);
			} else {
				if (response.data.reset) setIsLoggedIn(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message || "An unknown error occurred.",
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};


	useEffect(() => {
		if (isLoggedIn) {
			fetchStaff();
		} else {
			setMessages([]);
			setInputValue("")
			setChatId(userIds.substr(0, 6) + embedProjectIds + generateUniqueIdWithTimestamp());
		}
	}, [userIds, embedProjectIds, base_url, isLoggedIn]);

	useEffect(() => {
		if (chatHistory) {
			if (chatHistory && chatHistory.chat_ids !== chat_id) {
				var chat_id = chatHistory.chat_ids;
				var chat_file_url = chatHistory.chat_file_url;

				fetchData(chat_file_url)
					.then(data => {
						if (data) {
							setChatId(chat_id);
							setMessages(data);
						} else {
							Swal.fire("Somethings Went's Wrong!");
						}
					})
					.catch(error => {
						console.error("Error fetching data:", error);
						Swal.fire("Somethings Went's Wrong!");

					});
			}
		}
	}, [chatHistory]);

	const handleLogin = async (e) => {
		e.preventDefault();
		if (!email || !name) {
			setError("Both email and name are required.");
			return;
		}
		setError("");

		try {
			const formData = new FormData();
			formData.append("action", "endUserLogin");
			formData.append("email", email);
			formData.append("name", name);
			formData.append("uids", userIds);
			formData.append("epids", embedProjectIds);

			const response = await axios.post(`${base_url}api/chatbot/end-user/join-chatbot`, formData);

			if (response.data.status) {
				localStorage.setItem('enduser_token', response.data.token);
				localStorage.setItem('enduser_name', response.data.username);
				setIsLoggedIn(true);
			} else {
				Swal.fire({
					icon: "error",
					title: "Joining Failed",
					text: response.data.message || "Details add failed. Please try again.",
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};

	const toggleChat = () => {
		setIsOpen(!isOpen);
	};


	const generateUniqueIdWithTimestamp = () => {
		const timestamp = Date.now().toString(36);
		const randomPart = Math.random().toString(36).substr(2, 10);
		return (timestamp + randomPart).substr(0, 20);
	};

	const updateChatHistory = async () => {
		// const token = GetToken();

		const enduser_token = localStorage.getItem('enduser_token');
		if (!enduser_token) {
			setIsLoggedIn(false);
			return;
		}

		if (chatId == null || chatId == '') {
			return;
		}

		const jsonArray = JSON.stringify(messages);

		const jsonBlob = new Blob([jsonArray], { type: 'application/json' });
		// const chatHtml = document.getElementById("chat-messages").innerHTML;

		// const htmlBlob = new Blob([chatHtml], { type: "text/html" });
		const formData = new FormData();
		formData.append("enduser_token", enduser_token);
		formData.append("action", "updateChatHistory");
		formData.append("conversation_ids", conversationIds);
		formData.append("chatJson", jsonBlob);
		formData.append("heading", inputValue);
		formData.append("chatId", chatId);

		try {
			const response = await axios.post(`${base_url}api/chatbot/end-user/chatbot-data-kmp45jk`, formData);

			if (response.data.status) {
			} else {
				if (response.data.reset) {
					setIsLoggedIn(false);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};

	const handleSendMessage = async (e) => {
		if (e) e.preventDefault();
		// const token = GetToken();

		const enduser_token = localStorage.getItem('enduser_token');
		if (!enduser_token) {
			setIsLoggedIn(false);
			return;
		}


		if (inputValue.trim() === '') {
			Swal.fire("Please Enter Question!");
			return;
		}
		if (selectedStaffs.length === 0) {
			Swal.fire("Please Mention Staff!");
			return;
		}

		const staffTags = selectedStaffs.map(staff => staff.label).join(", ");
		setMessages(prevMessages => [...prevMessages, { role: "user", content: inputValue, image: Avatar, name: user_name, date: new Date().toLocaleString('en-US'), tags: staffTags }
		]);

		document.getElementById("send_icon").style.display = "none";
		document.getElementById("loader_2_fill").style.display = "block";
		const formData = new FormData();
		formData.append("enduser_token", enduser_token);
		formData.append("action", "conversation");
		formData.append("conversation_ids", conversationIds);
		formData.append("staffs", JSON.stringify(selectedStaffs));
		formData.append("last_reply", lastReply);
		formData.append("question", inputValue);

		try {
			const response = await axios.post(`${base_url}api/chatbot/end-user/chatbot-data-kmp45jk`, formData);

			if (response.data.status) {
				const aiMessages = response.data.data || [];
				aiMessages.forEach(aiMessage => {
					const item = allStaffs.find(item => item.ids === aiMessage.ids);
					const formattedText = aiMessage.message.replace(/\n/g, "<br />");
					setMessages(prevMessages => [...prevMessages, { role: "staff", content: formattedText, image: item.avatar, name: item.name + '(' + item.field + ')', date: new Date().toLocaleString('en-US') }]);
					setLastReply(formattedText + '. This answer is from ' + item.name + ' (' + item.field + ').');


				});


				document.getElementById("send_icon").style.display = "block";
				document.getElementById("loader_2_fill").style.display = "none";
			} else {
				if (response.data.reset) {
					setIsLoggedIn(false);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
				document.getElementById("send_icon").style.display = "block";
				document.getElementById("loader_2_fill").style.display = "none";
			}
		} catch (error) {
			document.getElementById("send_icon").style.display = "block";
			document.getElementById("loader_2_fill").style.display = "none";
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}

		setInputValue("");
	};

	const handleKeyDown = (event) => {
		const send_icon = document.getElementById("send_icon").style.display;

		if (event.key === 'Enter' && send_icon != 'none') {
			handleSendMessage();
		}
	};

	useEffect(() => {
		if (messages.length > 0) {
			updateChatHistory();
		}
	}, [messages]);

	const handleUserChange = (selectedOptions) => {
		setSelectedStaffs(selectedOptions);
	};

	const handleLiveChatCopy = (text) => {
		window.navigator.clipboard.writeText(text)
			.then(() => {
				Swal.fire({
					title: "Copied!",
					text: "The message has been copied to your clipboard.",
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
			})
			.catch(() => {
				Swal.fire({
					title: "Copy Failed",
					text: "There was an issue copying the message. Please try again.",
					icon: "error",
					timer: 1500,
					showConfirmButton: false,
				});
			});
	};
	const handleNewChat = () => {
		setMessages([]);
		setInputValue("")
		setChatId(userIds.substr(0, 6) + embedProjectIds + generateUniqueIdWithTimestamp());
	};

	const fetchData = async (url) => {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.error("Failed to fetch data:", error);
			return null;
		}
	};

	const exportConversation = () => {
		if (!messages || messages.length === 0) {
			Swal.fire("No conversation to export!");
			return;
		}

		const conversation = messages
			.map(
				(msg) =>
					`[${msg.date}] ${msg.name} (${msg.role}):\n${msg.content}\n`
			)
			.join("\n");

		const blob = new Blob([conversation], { type: "text/plain" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = title + ".txt";
		link.click();
		URL.revokeObjectURL(url);
	};





	return (
		<div className={`chatbot-container ${chatPosition}`}>
			<div className="chat-toggle-button d-none" onClick={toggleChat}>
				{isOpen ? (
					<button className="close-btn">×</button>
				) : (
					<img
						src={chatbotIcon}
						alt="Chat Icon"
						className="chat-icon"
					/>
				)}
			</div>
			{isOpen && (
				<div className="chat-window">
					<div className="chat-header">
						<div className="d-flex justify-content-between">
							<h3>{headerTitle}</h3>
							{isLoggedIn && (
								<Dropdown className="chat-modal-dropdown mb-1">
									<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn">
										<FaEllipsisV />
									</Dropdown.Toggle>

									<Dropdown.Menu>

										<Dropdown.Item onClick={handleNewChat}>

											<button className="new-chat me-2" title="New Chat" >New chat</button>

										</Dropdown.Item>

										<Dropdown.Item onClick={exportConversation}>
											<button className="new-chat me-2" title="Export Conversation" >Export</button>
										</Dropdown.Item>

									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>

						<p className="header-content">{headerMessage}</p>
					</div>

					{!isLoggedIn ? (
						<div className="login-form">
							<h4>Please Enter Your Details</h4>
							<form className="chat-login-form" onSubmit={handleLogin}>
								<input
									className="form-control custom-form-control "
									type="text"
									placeholder="Enter Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
								<input
									className="form-control custom-form-control mb-3"
									type="email"
									placeholder="Enter Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>

								{error && <p className="error-text">{error}</p>}
								<button type="submit">Join</button>
							</form>
							<div className="input-container chat-footer">
								<a rel="stylesheet" href={footerUrl} ><small className="instuction-text">{footerText} </small></a>

							</div>
						</div>
					) : (
						<Container className=" px-0">
							<div className="chat-inner-box">
								<div className="row">
									<div className="col-lg-12 mx-auto">
										<div className="chat-container">
											{/* <div className="heading">
												<h6 className="project-name">{title}</h6>
											</div> */}
											<div className="chat-box" id="chat-messages" role={chatId} style={{ display: messages.length === 0 ? 'none' : 'block' }}>
												{messages.map((msg, index) => (
													<div key={index} className={msg.role === "user" ? "user-message" : "staff-message"}>
														<div className="d-flex align-items-center justify-content-between mb-1 px-1 flex-wrap">
															<span className="user-name">{msg.date}</span>
															<div className="">
																<span className="user-name">{msg.name}</span>
																<img className="img-sm" src={msg.image} alt="img" />
															</div>
														</div>
														<p
															dangerouslySetInnerHTML={{
																__html: msg.content
															}}
														/>
														<div className="d-flex justify-content-between align-items-center flex-wrap">
															{msg.role === "user" && (
																<div className="tag-block">
																	<span>Tags</span>
																	<small className="tags-text">
																		{msg.tags && msg.tags.split(", ").map((tag, i) => (
																			<span key={i}>@ {tag}{i < msg.tags.split(", ").length - 1 ? ', ' : ''}</span>
																		))}
																	</small>
																</div>
															)}
															<div className="chat-icon-div d-flex">
																<FaCopy onClick={() => handleLiveChatCopy(msg.content)} className="icon-fill copy-btn pointer" />

															</div>
														</div>

													</div>
												))}
											</div>

											<div className="chat-front-view my-4" id="welcome_message" style={{ display: messages.length === 0 ? 'block' : 'none' }}>								<div className="heading-area text-center">
												<h5 className="main-title">What can I help with?</h5>
												<h6 className="sub-text">How can I assist you today?</h6>									<small className="tags-text">
													{allStaffs && allStaffs.map((staff, i) => (
														<span key={i}>@{staff.name}{'(' + staff.field + ')'}{i < staffs.length - 1 ? ', ' : ''}</span>
													))}
												</small>
											</div>
											</div>


										</div>
									</div>
								</div>
							</div>
							<div className="input-container">

								<Select
									isMulti
									key={isLoggedIn}
									menuPlacement="top"
									value={selectedStaffs}
									onChange={handleUserChange}
									options={staffs}
									classNamePrefix="custom"
									placeholder="Mention Staff..."
								/>
								<input
									type="text"
									placeholder="Type questions"
									value={inputValue}
									maxLength={PROMPT_LIMIT}
									onChange={(e) => setInputValue(e.target.value)}
									onKeyDown={handleKeyDown}
								/>
								<small className="instuction-text">{inputValue.length}/{PROMPT_LIMIT}</small>

								<FiSend className="send-icon" id="send_icon" onClick={handleSendMessage} />
								<img src={loadingGIF} className="loader-2" id="loader_2_fill" style={{ display: 'none' }} alt="" />
							</div>
						</Container>

					)}


				</div>
			)}
		</div>
	);
};

export default Chatbot;
