import React, { useState, useEffect } from "react";
import "./Create.styles.css";

import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AnimationSpinner from "../../../AnimationSpinner";

const Create = ({ base_url }) => {
	const navigate = useNavigate();

	const [projectName, setProjectName] = useState("");
	const [assignStaff, setAssignStaff] = useState([]);
	const [description, setDescription] = useState("");
	const [projectStatus, setProjectStatus] = useState("active");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [companies, setCompanies] = useState([]);
	const [staffList, setStaffList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [loading, setLoading] = useState(false)
	const DESCRIPTION_LIMIT = 500;
	const TITLE_LIMIT = 100;

	useEffect(() => {
		const fetchDataCompanyAndStaffAndDepartment = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}

				// document.getElementById("ai_staff").value = "";
				// alert(document.getElementById("ai_staff").value);
				setLoading(true)
				const formData = new FormData();
				formData.append('action', 'getUserCompanyAndStaffAndDepartmentLists');
				formData.append('token', token);
				formData.append('selected_company', selectedCompany);
				formData.append('selected_department', selectedDepartment);
				const response = await axios.post(`${base_url}user/api/process-resource`, formData);

				if (response.data.status) {
					setLoading(false)
					if (response.data.items === '') {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Please Create Project First',
						});
						return;
					}

					setCompanies(response.data.companies || []);
					setDepartmentList(response.data.department || []);

					const staffOptions = response.data.staff.map((staff) => ({
						value: staff.assign_staff_ids,
						label: staff.assign_staff_name,
					}));
					setStaffList(staffOptions || []);

				} else {
					setLoading(false)
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: error.response?.data?.message || error.message || 'Something went wrong. Please try again.',
				});
			}
		};

		fetchDataCompanyAndStaffAndDepartment();
	}, [navigate, base_url, selectedCompany, selectedDepartment]);


	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}
	const handleSubmit = (event) => {
		event.preventDefault();

		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}


		let isValid = true;
		if (!projectName && !assignStaff && !selectedCompany && !selectedDepartment) {
			Sweetalert("Project Name, Assign Staff , Company & Department");
			isValid = false;
		} else {
			if (!projectName) {
				Sweetalert("Project Name");
				isValid = false;
			}
			if (!assignStaff) {
				Sweetalert("Assign Staff");
				isValid = false;
			}

			if (!selectedCompany) {
				Sweetalert("Company");
				isValid = false;
			}
			if (!selectedDepartment) {
				Sweetalert("Department");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}
		const selectedStaff = staffList.filter(staff => assignStaff.includes(staff.value));
		setLoading(true)
		const formData = new FormData();
		formData.append("project_name", projectName);
		formData.append("assign_staff", JSON.stringify(selectedStaff));
		formData.append("description", description);
		formData.append("company_ids", selectedCompany);
		formData.append("department_ids", selectedDepartment);
		formData.append("project_status", projectStatus);
		formData.append("token", token);
		formData.append("action", "createProject");

		axios.post(`${base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "Project Added",
						text: "Your Project details have been added successfully.",
					});
					// navigate("/manage-project", { replace: true });
					navigate(`/project-tasks/${response.data.ids}`);
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Add Project Details</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectName" className="form-label">
												Project Name
											</label>
											<input
												type="text"
												id="formProjectName"
												className="form-control custom-form-control"
												placeholder="Enter project name"
												value={projectName}
												maxLength={TITLE_LIMIT}
												onChange={(e) => setProjectName(e.target.value)}
											/>
											<small className="instuction-text">{projectName.length}/{TITLE_LIMIT}</small>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Select Company
											</label>
											<select name="company" className="form-control custom-form-control" onChange={(e) => setSelectedCompany(e.target.value)}>
												<option value="" >Select Company</option>
												{companies.map((company) => (
													<option key={company.ids} value={company.ids}>{company.company_name}</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Select Department
											</label>
											<select name="departmentList" className="form-control custom-form-control" onChange={(e) => setSelectedDepartment(e.target.value)}>
												<option value="" >{selectedCompany
													? "Select Department"
													: "Select a company first"}</option>
												{departmentList && departmentList.length > 0 && departmentList.map((department) => (
													<option key={department.ids} value={department.ids}>{department.department_name}</option>
												))}
											</select>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Select AI Staff
											</label>

											<Select
												isMulti
												key={[selectedDepartment, selectedCompany]}
												name="Staff"
												id="ai_staff"
												placeholder={selectedDepartment
													? "Select AI Staff"
													: "Select a Department first"}
												className="custom-select"
												onChange={(selectedOptions) =>
													setAssignStaff(selectedOptions.map(option => option.value))
												}
												options={staffList}



											/>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectStatus" className="form-label">
												Select Project Status
											</label>
											<select className="form-control custom-form-control" name="project_status" value={projectStatus} onChange={(e) => setProjectStatus(e.target.value)}>
												<option value="active">Active</option>
												<option value="inactive">Inactive</option>
												<option value="complete">Complete</option>
											</select>
										</div>

									</div>

									<div className="mb-4">
										<label htmlFor="formDescription" className="form-label">
											Description
										</label>
										<textarea
											id="formDescription"
											className="form-control custom-form-control"
											style={{ height: '100px' }}
											placeholder="Enter description"
											value={description}
											maxLength={DESCRIPTION_LIMIT}
											onChange={(e) => setDescription(e.target.value)}
										/>
										<small className="instuction-text">{description.length}/{DESCRIPTION_LIMIT}</small>

									</div>

									<button type="submit" className="btn btn-primary mt-2 mb-3">
										Add Project
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Create;
