import React, { useState, useContext } from "react";
import "./LoginPage.styles.css";
import { Link } from "react-router-dom";
import lightLogo from "../../assests/images/White.png";
import loginImg from "../../assests/images/loginPage/loginpageBackground.png";
import loginPage2 from "../../assests/images/loginPage/login page background 2.jpg"
import loginImg3 from "../../assests/images/loginPage/loginPage3.jpg"
import darkLogo from "../../assests/images/dark-logo.png";
import { BsFillPersonFill } from "react-icons/bs";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import GetToken from "../../GetToken";
import { AppContext } from "../../context/AppContext";
import { fetchMenuPermissions } from "../../fetchMenuPermissions";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
const LoginPage = (props) => {
	const { setPermissions } = useContext(AppContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (email.trim() === "" || password.trim() === "") {
			const text =
				email.trim() === "" && password.trim() === ""
					? "Email and Password are required."
					: email.trim() === ""
					? "Email is required."
					: "Password is required.";

			Swal.fire({
				icon: "error",
				title: "Warning...",
				text: text,
			});
			return;
		}
		setLoading(true);
		const auth = new FormData();
		auth.append("email", email);
		auth.append("password", password);

		axios
			.post(`${props.base_url}authentication`, auth)
			.then((res) => {
				if (res.data.status === true) {
					localStorage.setItem("aistafftoken", res.data.token);
					localStorage.setItem("username", res.data.username);
					localStorage.setItem("avatar", res.data.avatar);
					localStorage.setItem("email", res.data.email);
					const token = GetToken();
					fetchMenuPermissions(token, props.base_url, setPermissions);
					setTimeout(() => {
						navigate("/dashboard", { replace: true });
						setLoading(false);
					}, 500);
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Login Failed...",
						text: res.data.message,
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				// console.error('Error:', err);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "An error occurred during login. Please try again.",
				});
			});
	};

	return (
		<>
			<section className="login-box-wrapper">
				<AnimationSpinner show={loading} />
				<div className="container-fluid">
					<div className="login-box">
						<div className="row justify-content-center align-items-center h-100">
							<div className="col-lg-7 d-none d-lg-block">
								<div className="left-login-container ">
									<img src={loginImg} alt="Login image" />
									{/* <img src={loginPage2} alt="Login image" /> */}
								</div>
							</div>
							<div className="col-md-7 col-lg-5 mx-auto">
								<div className="right-login-container mx-auto">
									<div className="inner-login-box">
										<div className="login-content">
											{/* <div className="login-head mx-auto"> */}
											<div className="login-head mx-auto col-lg-12 text-center">
												<div className="login-logo text-center ">
													<img src={darkLogo} alt="Logo" />
												</div>
												<h2 className="title fs-lg-2">
													Welcome To TeamBuddy AI
												</h2>
												<h6 className="sub-title">
													{/* Log in with your credentials provided below */}
													Sign into your account by entering information below
												</h6>
											</div>

											<div className="row p-0">
												<div className="col-lg-12 mx-auto">
													{/* <h6 className="sub-title mb-2 login-content d-none d-md-none d-lg-block">
														Log in
													</h6> */}
													<form onSubmit={handleSubmit}>
														<div className="row mt-2">
															<div className="col-md-12 ">
																<div className="form-group">
																	<div
																		data-mdb-input-init
																		className="form-outline "
																	>
																		<label htmlFor="form3Example1">
																			 Email
																			{/* Enter your Email */}
																		</label>
																		<div className="position-relative">
																			<input
																				type="text"
																				id="form3Example1"
																				className="form-control login-from-control mt-1"
																				placeholder="Enter your Email"
																				onChange={({ target: { value } }) =>
																					setEmail(value)
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12 ">
																<div className="form-group">
																	<div
																		data-mdb-input-init
																		className="form-outline"
																	>
																		<label htmlFor="form3Example2">
																			Password
																			{/* Enter your Password */}
																		</label>
																		<div className="position-relative">
																			<input
																				type={!showPassword?"password" :"text"}
																				id="form3Example2"
																				className="form-control login-from-control mt-1"
																				placeholder="Enter your Password"
																				onChange={({ target: { value } }) =>
																					setPassword(value)
																				}
																			/>
																			<div
																				className="login-input-icon position-absolute"
																				onClick={handleShowPassword}
																				style={{cursor: "pointer"}}
																			>
																				{showPassword ? (
																					<FaRegEyeSlash />
																				) : (
																					<FaRegEye />
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																<button
															type="submit"
															data-mdb-button-init
															data-mdb-ripple-init
															className="btn login-button w-100"
														>
															Login
														</button>
																</div>
															</div>
														</div>

														
														
														<div className="forgot-link">
															<Link
																className="text-muted"
																to="/forgot-password-request"
															>
																Forgot Password?
															</Link>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginPage;
