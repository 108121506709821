import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";

// bootstrap imports
import { Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";


const MembershipStaffList = (props) => {
	const [items, setItems] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [showModal, setShowModal] = useState(false);

	const [dropdownStaff, setDropdownStaff] = useState([])
	const [name, setName] = useState("");
	const [staffId, setStaffId] = useState("")
	const [ids, setIds] = useState("")
	const [isFree, setIsFree] = useState("1")
	const [price, setPrice] = useState("0")
	const { membershipIds } = useParams()
	const [isEditing, setIsEdititing] = useState(false)

	// model functions 
	const handleModelClick = () => {
		setShowModal(true);
	}
	const closeModel = () => {
		setShowModal(false);
		setStaffId("");
		setPrice("0");
		setIsFree("1");

	};

	// core functions---------------------------
	// fetching staff list--------------------------------------------------
	const fetchStaff = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == "") {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "getStaffList");
			formData.append("token", token);
			formData.append("membership_ids", membershipIds);
			formData.append("offset", currentPage);
			formData.append("limit", itemsPerPage ? itemsPerPage : 10);
			formData.append("search", searchTerm);
			const response = await axios.post(
				`${props.base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				setItems(response.data.membership_staff)
				const notAddedStaff = response.data.dropdown_list.filter(
					(staff) => !response.data.allMemberStaff.some((member) => member.staff_ids === staff.ids)
				);
				setDropdownStaff(notAddedStaff);
				setTotalItems(response.data.total_items || 0);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	useEffect(() => {
		fetchStaff();
	}, [currentPage, navigate, props.base_url, searchTerm, itemsPerPage]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const selectedStaff = dropdownStaff.find((staff) => staff.ids === staffId);
		setDropdownStaff((prevItems) => prevItems.filter((item) => item.ids !== staffId));
		if (!staffId) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Staff name is required.",
			});
			return;
		}
		
		
		if (!isFree && price <= '0') {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Price is required for paid staff.",
			});
			return;
		}


		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == "") {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "saveMembershipStaff");
			formData.append("token", token);
			formData.append("staff_ids", staffId);
			formData.append("membership_ids", membershipIds);
			formData.append("staff_name", selectedStaff.name);
			formData.append("isFree", isFree);
			formData.append("price", (isFree == '1') ? "0" : price);
			
			const response = await axios.post(
				`${props.base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				fetchStaff();
				// setItems(response.data.membership_staff)
				setTotalItems(response.data.total_items || 0);
				Swal.fire({
					icon: "success",
					title: "Staff added",
					text: "Staff has been added successfully",
				});
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
		setName("");
		setStaffId("");
		setIsFree('1');
		setPrice('0');
		setShowModal(false);

	}

	const handleEditStaffClick = (item) => {
		setShowModal(true)
		setIsEdititing(true);
		setStaffId(item.staff_ids);
		setIds(item.ids)
		setName(item.staff_name)
		setIsFree(item.price == '0' ? "1" : "0");
		setPrice(item.price)
	}

	const handleEditStaff = async (e) => {
		e.preventDefault();
		if (!isFree && price <= '0') {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Price is required for paid staff.",
			});
			return;
		}
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == "") {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "updateMembershipStaff");
			formData.append("token", token);
			formData.append("ids", ids);
			formData.append("staff_ids", staffId);
			formData.append("membership_ids", membershipIds);
			formData.append("isFree", isFree);
			formData.append("price", isFree == "1" ? "0" : price);
			const response = await axios.post(
				`${props.base_url}api/user/get-membership-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				fetchStaff();
				setTotalItems(response.data.total_items || 0);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
		setName("");
		setStaffId("");
		setIsFree("1");
		setPrice("0");
		setShowModal(false);
		setIsEdititing(false)

	}



	const handleDeleteStaff = async (item) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You won\'t be able to revert this!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const token = GetToken();
					if (!token) {
						handleInvalidToken(navigate);
						return;
					}

					setLoading(true);
					const formData = new FormData();
					formData.append("action", "deleteMembershipStaff");
					formData.append("token", token);
					formData.append("ids", item.ids);
					formData.append("staff_ids", item.staff_ids);
					formData.append("membership_ids", membershipIds);

					const response = await axios.post(
						`${props.base_url}api/user/get-membership-resource`,
						formData
					);

					if (response.data.status === true) {
						setLoading(false);
						fetchStaff();
						Swal.fire('Deleted!', 'The staff member has been deleted.', 'success');
						setName("");
						setStaffId("");
						setIsFree("1");
						setPrice("0");
					} else {
						setLoading(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				} catch (error) {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response?.data?.message ||
							error.message ||
							"Something went wrong. Please try again.",
					});
				}
				setName("");
				setStaffId("");
				setIsFree("1");
				setPrice("0");
			}
		});
	};
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="row">
									<div className="col-lg-12">
										<div className="head-part d-flex align-items-center justify-content-between mx-0">
											<h3 className="title">Membership AI Staff List</h3>
											<div>

												<Link to="/manage-membership"
													type="button"
													className="btn btn-primary mr-3 mb-2 me-4"

												>
													Back
												</Link>
												<button
													type="button"
													className="btn btn-primary mr-3 mb-2"
													onClick={handleModelClick}
												>
													New Staff
												</button>

											</div>
										</div>
									</div>
								</div>
								<div>
									<div>
										<div
											id="dataTable_agency_user_wrapper"
											className="dataTables_wrapper dt-bootstrap4 no-footer"
										>
											<div className="row align-items-center">
												<div className="col-sm-12 col-md-6">
													<div
														className="dataTables_length"
														id="dataTable_agency_user_length"
													>
														<label className="d-flex align-items-center">
															show
															<select
																id="formRange"
																className="form-select custom-form-select"
																value={itemsPerPage}
																onChange={(e) =>
																	setItemsPerPage(e.target.value)
																}
															>
																<option value="10">10</option>
																<option value="25">25</option>
																<option value="50">50</option>
															</select>
															entries
														</label>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div
														id="dataTable_agency_user_filter"
														className="dataTables_filter"
													>
														<label className="form-label w-100">
															<input
																type="search"
																className="form-control custom-form-control mt-2"
																placeholder="Search for a Membership Ai Staff"
																aria-controls="dataTable_agency_user"
																value={searchTerm}
																onChange={(e) => setSearchTerm(e.target.value)}
															/>
														</label>
													</div>
												</div>
											</div>
											{items.length > 0 ? (
												<div className="row">
													<div className="col-sm-12">
														<div className="table-responsive">
															<table
																id="dataTable_agency_user"
																className="table table-striped w-100 dataTable no-footer"
																role="grid"
																aria-describedby="dataTable_agency_user_info"
																style={{ width: "1067px" }}
															>
																<thead className="table-head">
																	<tr role="row">
																		<th>Status</th>
																		<th>Staff name</th>
																		<th>Type</th>
																		<th>price</th>
																		<th>Actions</th>
																	</tr>
																</thead>
																<tbody>
																	{items.map((item, index) => (
																		<tr
																			role="row"
																			key={item.staff_ids}
																			className={
																				index % 2 == 1 ? "even" : "odd"
																			}

																		>
																			<td>
																				<span
																					className={`badge ${item.status == 1 ? "text-bg-success" : "text-bg-danger"
																						}`}
																				>
																					{item.status == 1 ? "active" : "inactive"}
																				</span>
																			</td>
																			<td>{item.staff_name}</td>
																			<td>{item.price == "0" ? "Free" : "Paid"}</td>
																			<td>${item.price}</td>
																			<td>
																				<div className="d-flex justify-content-center alig-items-center"
																				>
																					<div title="Edit Price"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handleEditStaffClick(item)}
																					>
																						<FaRegEdit className="f17 text-success fs-5" />
																					</div>
																					<div title="Delete Staff"
																						className="p-2 bg-white me-2 rounded-2 cursor-pointer"
																						onClick={() => handleDeleteStaff(item)}
																					>
																						<MdDeleteForever className="f17 text-danger fs-5" />
																					</div>
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											) : (
												<div className="row mt-4 mb-5">
													<div className="col-lg-5 col-md-8 mx-auto">
														<div className="empty-box">
															<div className="card-body">
																<div className="list-empty-box">
																	<div className="icon">
																		<img
																			src={emptyIcon}
																			alt="No data available"
																		/>
																	</div>
																	<p>No data available</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="row justify-content-between align-items-center mt-4 mb-4">
												<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
													<Pagination className="mb-0">
														<Pagination.Prev
															onClick={() =>
																setCurrentPage((prev) => Math.max(prev - 1, 1))
															}
															disabled={currentPage === 1}
														/>
														{[...Array(totalPages)].map((_, index) => (
															<Pagination.Item
																key={index + 1}
																active={index + 1 === currentPage}
																onClick={() => setCurrentPage(index + 1)}
															>
																{index + 1}
															</Pagination.Item>
														))}
														<Pagination.Next
															onClick={() =>
																setCurrentPage((prev) =>
																	Math.min(prev + 1, totalPages)
																)
															}
															disabled={currentPage === totalPages}
														/>
													</Pagination>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showModal && (
				<div className="modal d-block p-4">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Staff Details</h5>
								<button type="button" className="close" onClick={closeModel}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form
									acceptCharset="utf-8"
									autoComplete="off"
									className="mt-3"
								>

									<div className="card-body mx-auto">
										<div className="row form-group mb-0">
											<div className="col-md-3 form-group">
												<label className="form-label" htmlFor="Select-Staff">Select Staff</label>
												{isEditing ? (
													<input
														type="text"
														className="form-control custom-form-control mb-2 w-100"
														value={name}
														disabled
													/>
												) : (<select name="Select-Staff" className="form-control custom-form-control mb-2 w-100"
													value={staffId}
													onChange={(e) => setStaffId(e.target.value)}
												>
													<option value="">select staff</option>
													{dropdownStaff && dropdownStaff.map((staff) => (
														<option key={staff.ids} value={staff.ids}>{staff.name}</option>
													))}
												</select>)}
											</div>
											<div className="col-md-3 mb-3">
												<label className="form-label">
													Type
												</label>
												<select
													name="Staff-type" id="staffType"
													className="form-control custom-form-control mb-2 w-100"
													value={isFree}
													onChange={(e) => setIsFree(e.target.value === "1" ? "1" : "0")}
												>
													<option value={"1"} >Free</option>
													<option value={"0"} >Paid</option>
												</select>
											</div>
											{isFree == 1 ? null : (<div className="col-md-3 mb-3">
												<label className="form-label">
													Price <span className="text-danger">*</span>
												</label>
												<input
													type="Number"
													className="form-control custom-form-control mb-2 w-100"
													value={price}
													onChange={(e) => setPrice(e.target.value)}
												/>
											</div>)}

											{isEditing ? (<div className="col-md-2 mb-3 d-flex align-items-end justify-content-center pb-1 ms-3">
												<button
													onClick={handleEditStaff}
													className="btn btn-primary px-4 mt-2 mb-2"
												>
													update
												</button>
											</div>) : (<div className="col-md-2 mb-3 d-flex align-items-end justify-content-center pb-1">
												<button
													onClick={handleSubmit}
													className="btn btn-primary px-4 mt-2 mb-2"
												>
													Add
												</button>
											</div>)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

			)}
		</div>

	)
}

export default MembershipStaffList;
