import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from "./context/AppContext";

const ProtectedRoute = ({ children, isLoginRoute = false, menuRequiredPermission = null, packageRequiredFeature = null }) => {
	const token = localStorage.getItem('aistafftoken');
	const { menuPermissionArray, menuFullPermission, packageAccess, features } = useContext(AppContext);

	if (!token && !isLoginRoute) {
		return <Navigate to="/login" replace />;
	}

	if (token && isLoginRoute) {
		return <Navigate to="/dashboard" replace />;
	}
	  
	if (!menuFullPermission && menuRequiredPermission && !menuPermissionArray.includes(menuRequiredPermission)) {
		return <Navigate to="/dashboard" replace />;
	}
	if (packageRequiredFeature && !packageAccess.includes(packageRequiredFeature)) {

		const featureDetails = features.find(feature => feature.value === packageRequiredFeature);
		if (!featureDetails) {
			return <Navigate to="/dashboard" replace />;
		}
		window.location.href = featureDetails.upgrade_url; 
		return null;
	}

	return children;
};

export default ProtectedRoute;
