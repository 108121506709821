import React, { useState, useContext, useEffect } from "react";
import "./TrainingVideos.css";
import { Link } from "react-router-dom";
import axios from "axios";
import GetToken from "../../GetToken";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import AnimationSpinner from "../../AnimationSpinner";
import SmallLogo from "../../assests/images/dark-logo.png";


const TrainingVideos = () => {
	const [activeTab, setActiveTab] = useState("introductionVideo");
	const [fetchedVideos, setFetchedVideos] = useState([]);
	const [demoVideo, setDemoVideo] = useState({});
	const [loading ,setLoading] = useState(false)
	const navigate = useNavigate();
	const { base_url } = useContext(AppContext);

	const handleTabChange = (str) => {
		setActiveTab(str);
	};
	
	const fetchVideos = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true)
			const formData = new FormData();
			formData.append("action", "getTrainingVideos");
			formData.append("token", token);
			const response = await axios.post(
				`${base_url}api/user/get-user-resource`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false)
				setFetchedVideos(response.data.items || []);
				setDemoVideo(response.data.demoVideo || {})
			} else {
				setLoading(false)
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	useEffect(() => {
		fetchVideos();
	}, []);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading}/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="page-title-box">
								<h4 className="page-title">Training Videos</h4>
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="card">
							<div className="card-top-header">
								<h6 className="profile-heading">Demo and Training Videos</h6>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-xl-5 col-lg-6 mx-auto">
										<ul
											className="nav nav-pills  mt-3 mb-3 justify-content-center style-1"
											role="tablist"
										>
											<li className="nav-item">
												<div
													className={`nav-link ${
														activeTab === "introductionVideo" ? "active" : null
													} `}
													onClick={() => handleTabChange("introductionVideo")}
												>
													Introduction Video
												</div>
											</li>
											<li className="nav-item">
												<div
													className={`nav-link ${
														activeTab === "trainingVideo" ? "active" : null
													} `}
													onClick={() => handleTabChange("trainingVideo")}
												>
													Training Videos
												</div>
											</li>
										</ul>
									</div>

									{activeTab === "introductionVideo" ? (
										<div className="col-lg-12 mt-4">
											<div className="tab-content pt-0">
												<div
													className="tab-pane show active"
													id="navpills-1"
													role="tabpanel"
													aria-labelledby="navpills-1-tab"
												>
													<div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
														<div className="video-container demo-video">
															<iframe
																uniquetitle="video"
																width="100%"
																height="400"
																className="demo-video"
																src={demoVideo.src}
																frameBorder="0"
																allowFullScreen
															></iframe>
														</div>
													</div>
												</div>
												{/* cut from here paste again here */}
											</div>
										</div>
									) : (
										<div
											className="tab-pane mt-4"
											id="navpills-2"
											role="tabpanel"
											aria-labelledby="navpills-2-tab"
										>
											<div className="row">
												{/* Video 1 */}

												{fetchedVideos.map((item, index) => (
													<div
														className="col-xl-4 col-lg-6 col-md-6"
														key={index}
													>
														<div className="training-box">
															<div className="media">
																<iframe
																	uniquetitle="video"
																	width="100%"
																	height="190"
																	src={item.src}
																	style={{ borderRadius: "10px" }}
																	allowFullScreen
																></iframe>
															</div>
															<div className="content">
																<h5 className="title">{item.title}</h5>
																<div className="download-btn mb-0">
																	<Link
																		to={item.src}
																		target="_blank"
																		className="btn btn-primary btn-sm"
																	>
																		Watch Training
																	</Link>
																</div>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrainingVideos;
