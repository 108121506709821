import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import AnimationSpinner from "../../AnimationSpinner";
import GetToken from "../../GetToken";
import UpGradeBox from "../../components/upgradeBox/UpGradeBox";
import TeamPremium from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-PREMIUM.png"
import TeamMax from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-MAX.png"
import TeamMembership from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-MEMBERSHIP.png"
import TeamDfy from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-DFY.png"
import TeambuddyAi from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-FRONTEND.png"
import TeamEbook from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-EBOOK.png"
import TeamStore from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-STORE.png"
import TeamAudiobook from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-AUDIOBOOK.png"
import TeamAgency from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-AGENCY.png"
import TeamReseller from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-RESELLER.png"
import TeamWhiteLabel from "../../assests/images/TEAMBUDDY OTOS MOCKUP/TEAM-WHITELABEL.png"



const UpGradePage = ({ base_url }) => {
	const [userPackages, setUserPackages] = useState([]);
	// const [packages, setPackages] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchUserPackage = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken();
					return;
				}
				setLoading(true);
				const formdata = new FormData();
				formdata.append("action", "fetchUserPackages");
				formdata.append("token", token);
				const response = await axios.post(
					`${base_url}api/user/get-user-resource`,
					formdata
				);
				if (response.data.status) {
					setLoading(false);
					console.log(response.data)
					setUserPackages(response.data.items);
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Failed to insert data. Please try again.",
				});
			}
		};

		// const fetchPackageList = async () => {
		// 	try {
		// 		const token = GetToken();
		// 		if (!token) {
		// 			handleInvalidToken();
		// 			return;
		// 		}
		// 		setLoading(true);
		// 		const formdata = new FormData();
		// 		formdata.append("action", "fetchPackages");
		// 		formdata.append("token", token);
		// 		const response = await axios.post(
		// 			`${base_url}api/user/get-user-resource`,
		// 			formdata
		// 		);
		// 		if (response.data.status) {
		// 			setLoading(false);
		// 			setPackages(response.data.items);
		// 		} else {
		// 			setLoading(false);
		// 			Swal.fire({
		// 				icon: "error",
		// 				title: "Error",
		// 				text: response.data.message,
		// 			});
		// 		}
		// 	} catch (error) {
		// 		setLoading(false);
		// 		Swal.fire({
		// 			icon: "error",
		// 			title: "Error",
		// 			text:
		// 				error.response?.data?.message ||
		// 				error.message ||
		// 				"Failed to insert data. Please try again.",
		// 		});
		// 	}
		// };
		// fetchPackageList();
		fetchUserPackage();
	}, []);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="content">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="page-title-box">
								<h4 className="page-title">Upgrade</h4>
							</div>
						</div>
					</div>
					<div className="row">
						{userPackages.includes(1) || userPackages.includes(2) ? (
							<UpGradeBox title={"TeamBuddy AI Frontend"} activated={true} img={TeambuddyAi} />
						) : <UpGradeBox title={"TeamBuddy AI Frontend "} activated={false} img={TeambuddyAi} upgrade={"Frontend"} url="https://grabteambuddyai.com/live" />}

						{userPackages.includes(3) ? (
							<UpGradeBox title={"TeamBuddy AI Premium Edition"} activated={true} img={TeamPremium} />
						) : <UpGradeBox title={"TeamBuddy AI Premium Edition"} activated={false} img={TeamPremium} upgrade={"Premium"} url="https://grabteambuddyai.com/premium-oto1" />}
						{userPackages.includes(4) ? (
							<UpGradeBox title={"TeamBuddy AI Max Edition"} activated={true} img={TeamMax} />
						) : <UpGradeBox title={"TeamBuddy AI Max Edition"} activated={false} img={TeamMax} upgrade={"Max"} url="https://grabteambuddyai.com/max-oto2" />}
						{userPackages.includes(5) ? (
							<UpGradeBox title={"TeamBuddy AI Membership Edition"} activated={true} img={TeamMembership} />
						) : <UpGradeBox title={"TeamBuddy AI Membership Edition"} activated={false} img={TeamMembership} upgrade={"Membership"} url="https://grabteambuddyai.com/membership-oto3" />}
						{userPackages.includes(6) ? (
							<UpGradeBox title={"TeamBuddy AI Ebook Edition"} activated={true} img={TeamEbook} />
						) : <UpGradeBox title={"TeamBuddy AI Ebook Edition"} activated={false} img={TeamEbook} upgrade={"Ebook"} url="https://grabteambuddyai.com/ebook-oto4" />}
						{userPackages.includes(7) ? (
							<UpGradeBox title={"TeamBuddy AI DFY Edition"} activated={true} img={TeamDfy} />
						) : <UpGradeBox title={"TeamBuddy AI DFY Edition"} activated={false} img={TeamDfy} upgrade={"DFY"} url="https://grabteambuddyai.com/dfy-oto5" />}
						{userPackages.includes(8) ? (
							<UpGradeBox title={"TeamBuddy AI Store Edition"} activated={true} img={TeamStore} />
						) : <UpGradeBox title={"TeamBuddy AI Store Edition"} activated={false} img={TeamStore} upgrade={"Store"} url="https://grabteambuddyai.com/store-oto6" />}
						{userPackages.includes(9) ? (
							<UpGradeBox title={"TeamBuddy AI AudioBook Edition"} activated={true} img={TeamAudiobook} />
						) : <UpGradeBox title={"TeamBuddy AI AudioBook Edition"} activated={false} img={TeamAudiobook} upgrade={"AudioBook"} url="https://grabteambuddyai.com/audiobook-oto7" />}
						{userPackages.includes(10) || userPackages.includes(11) ? (
							<UpGradeBox title={"TeamBuddy AI Agency Edition"} activated={true} img={TeamAgency} />
						) : <UpGradeBox title={"TeamBuddy AI Agency Edition"} activated={false} img={TeamAgency} upgrade={"Agency"} url="https://grabteambuddyai.com/agency-oto8" />}
						{userPackages.includes(12) || userPackages.includes(13) ? (
							<UpGradeBox title={"TeamBuddy AI Reseller Edition"} activated={true} img={TeamReseller} />
						) : <UpGradeBox title={"TeamBuddy AI Reseller Edition"} activated={false} img={TeamReseller} upgrade={"Reseller"} url="https://grabteambuddyai.com/reseller-oto9" />}
						{userPackages.includes(14) ? (
							<UpGradeBox title={"TeamBuddy AI Whitelabel Edition"} img={TeamWhiteLabel} activated={true} />
						) : (<UpGradeBox title={"TeamBuddy AI Whitelabel Edition"} img={TeamWhiteLabel} activated={false} upgrade={"Whitelabel"} url="https://grabteambuddyai.com/whitelabel-oto10" />)}
						{userPackages.includes(15) ? (
							<UpGradeBox title={"TeamBuddy AI VIP Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(16) ? (
							<UpGradeBox title={"TeamBuddy AI Automation Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(25) ? (
							<UpGradeBox title={"TeamBuddy AI Unlimited Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(17) ? (
							<UpGradeBox title={"TeamBuddy AI Ultimate Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(18) ? (
							<UpGradeBox title={"TeamBuddy AI Ebook VIP Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(19) ? (
							<UpGradeBox title={"TeamBuddy AI DFY Club"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(20) ? (
							<UpGradeBox title={"TeamBuddy AI Payment Club"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(21) ? (
							<UpGradeBox title={"TeamBuddy AI Unlimited AudioBook Pack"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(22) ? (
							<UpGradeBox title={"TeamBuddy AI Agency Club"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(23) ? (
							<UpGradeBox title={"TeamBuddy AI Reseller Bundle"} img={TeambuddyAi} activated={true} />
						) : null}
						{userPackages.includes(24) ? (
							<UpGradeBox title={"TeamBuddy AI Developer VIP Pack"} img={TeambuddyAi} activated={true} />
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpGradePage;
