import React, { useState, useEffect } from "react";
import emptyIcon from "../../../assests/images/emptyIcon.png"

import { Card, Button, Row, Col, Pagination, Form, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

// react-icons
import { FaCircleInfo } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import AnimationSpinner from "../../../AnimationSpinner";
import { IoMdListBox } from "react-icons/io";

import { truncateTextByWords } from "../../../util";

const List = (props) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [companies, setCompanies] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [teamMemberDetails, setTeamMemberDetails] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				if (searchTerm == '') {
					setLoading(true);
				}
				const formData = new FormData();
				formData.append("action", "getDepartmentLists");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				formData.append("company_ids", selectedCompany);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems || 0);
					setCompanies(response.data.company || 0);
					setTeamMemberDetails(response.data.teamMemberDetails || []);

				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [
		currentPage,
		navigate,
		props.base_url,
		searchTerm,
		itemsPerPage,
		selectedCompany,
	]);

	const handleEdit = (ids) => {
		navigate(`/edit-department/${ids}`);
	};

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			html: `
				<p>Deleting this department will <strong class="text-danger">permanently remove</strong> the following:</p>
				<ul style="text-align: left;">
					<li>All related projects</li>
					<li>Communications</li>
					<li>Embed projects</li>
					<li>Leads and lead communications</li>
				</ul>
				<p>⚠️ <strong>This action is irreversible, and you will not be able to recover this department!</strong></p>
			`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "removeDepartment");
				formData.append("token", token);
				formData.append("department_ids", ids);
				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your item has been deleted.", "success");
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to delete item", "error");
			}
		}
	};

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<Form.Group className="department-search-bar">
								<div className="head-part">
									<div className="row w-100 align-items-center">
										<div className="col-lg-12">
											<h4 className=" title"> Manage Your Department</h4>
										</div>
									</div>
								</div>
							</Form.Group>

							<div className="row" controlid="search">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="company-department">
											Search Department
										</label>
										<Form.Control
											className="custom-form-control mb-2 w-100 "
											type="text"
											placeholder="Search for a Department..."
											value={searchTerm}
											onChange={(e) => {
												setSearchTerm(e.target.value);
												setCurrentPage(1);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label" htmlFor="select-company">
											Select Company
										</label>
										<select
											name="company"
											className="form-control custom-form-control mb-2 w-100"
											onChange={(e) => setSelectedCompany(e.target.value)}
										>
											<option value="">All</option>
											{companies.map((company) => (
												<option key={company.ids} value={company.ids}>
													{company.company_name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							{items.length > 0 ? (<Row className="mt-3">
								{items.map((item) => {
									const created_by = teamMemberDetails.find(
										(member) => member.ids === item.created_by
									);
									const modified_by = teamMemberDetails.find(
										(member) => member.ids === item.modified_by
									);

									return (<Col
										xl={4}
										key={item.ids}
										className=" col-lg-6 col-md-6 mb-4"
									>
										<div className="company-card">
											<div className="company-card-image-container">
												<Card.Img variant="top" src={item.department_logo} />
											</div>
											<Card.Body className="px-3 pt-2 pb-3">
												<Card.Title className="border-0 pb-0">
													{item.department_name}
												</Card.Title>
												<Card.Text title={item.description} className="company-description  des-text">
													{truncateTextByWords(item.description, 10)}
												</Card.Text>
												<div className="d-flex align-items-center justify-content-between flex-wrap">
													<Card.Text className="company-creation-date">
														<span>Created :</span>{" "}
														{item.created}
													</Card.Text>
													{(created_by?.name || modified_by?.name) && (
														<Dropdown className="info-dropdown">
															<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-secondary">
																Team Member <FaCircleInfo />
															</Dropdown.Toggle>
															<Dropdown.Menu className="staff-dropdown-menu">
																{created_by?.name && (
																	<Dropdown.Item>
																		<MdOutlineCreateNewFolder className="f17 text-success me-2 mb-1" />
																		<span className="staf-menu-dropdown-desc">Created By:</span><Badge bg="secondary">{created_by.name}</Badge>
																	</Dropdown.Item>
																)}
																{modified_by?.name && (
																	<Dropdown.Item>
																		<FaRegEdit className="f17 text-success me-2 mb-1" />
																		<span className="staf-menu-dropdown-desc">Modified By: </span><Badge bg="secondary">{modified_by.name}</Badge>
																	</Dropdown.Item>
																)}
															</Dropdown.Menu>
														</Dropdown>
													)}

												</div>
												<div className="compay-card-btn-container mt-2 w-100 d-flex justify-content-start align-items-center gap-2">

													{props.menuFullAccess || props.userPermissions.includes('department_edit') ? (
														<Button
															variant="primary"
															onClick={() => handleEdit(item.ids)}
														>
															<FaRegEdit /> Edit
														</Button>
													) : null}

													{props.menuFullAccess || props.userPermissions.includes('department_delete') ? (
														<Button
															variant="danger"
															onClick={() => handleDelete(item.ids)}
															className="ml-2"
														>
															<MdDeleteForever />
															Delete
														</Button>
													) : null}
												</div>
											</Card.Body>
										</div>
									</Col>
									)
								})}
							</Row>) : (<div className="row mt-4 mb-5">
								<div className="col-lg-5 col-md-8 mx-auto">
									<div className="empty-box">
										<div className="card-body">
											<div className="list-empty-box">
												<div className="icon">
													<img src={emptyIcon} alt="No data available" />
												</div>
												<p>No data available</p>
											</div>
										</div>
									</div>
								</div>
							</div>)}

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() =>
											setCurrentPage((prev) => Math.max(prev - 1, 1))
										}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default List;
