import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import AnimationSpinner from "../../../AnimationSpinner";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const AgencyCreate = ({ base_url }) => {
	const [agency, setAgency] = useState({
		agencyName: "",
		email: "",
		password: "",
		confirmPassword: "",
		package: [],
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [agencyPackage, setAgencyPackage] = useState([]);

	const fetchAgencyUsersPackage = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			const formData = new FormData();
			formData.append("action", "getAgencyPackages");
			formData.append("token", token);
			const response = await axios.post(
				`${base_url}api/user/get-agency-resource`,
				formData
			);

			if (response.data.status === true) {
				setLoading(false);
				setAgencyPackage(
					response.data.packages.map((pkg) => ({
						value: pkg.id,
						label: pkg.name,
					}))
				);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchAgencyUsersPackage();
	}, [navigate, base_url]);

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (isSubmitted) return;
		setIsSubmitted(true);

		try {
			const token = GetToken();

			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			let isValid = true;
			if (
				!agency.agencyName &&
				!agency.email &&
				!agency.password &&
				!agency.confirmPassword &&
				agency.package.length === 0
			) {
				setIsSubmitted(false);
				Sweetalert("Agency Name, Email, Password, and Package");
				isValid = false;
			} else {
				setIsSubmitted(false);
				if (!agency.agencyName) {
					Sweetalert("Agency Name");
					isValid = false;
				} else if (!agency.email) {
					Sweetalert("Email");
					isValid = false;
				} else if (!agency.password) {
					Sweetalert("Password");
					isValid = false;
				} else if (!agency.confirmPassword) {
					Sweetalert("Confirm Password");
					isValid = false;
				} else if (agency.password.length < 6 || agency.password.length > 12) {
					Swal.fire({
						icon: "error",
						title: "Validation Error",
						text: "Password should be between 6 and 12 characters.",
					});
					isValid = false;
				} else if (agency.password !== agency.confirmPassword) {
					Sweetalert("Password & Confirm Password do not match");
					isValid = false;
				} else if (agency.package.length === 0) {
					Sweetalert("Package");
					isValid = false;
				}
			}
			if (!isValid) {
				return;
			}

			setLoading(true);
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "createAgency");

			formData.append("agency_name", agency.agencyName);
			formData.append("agency_email", agency.email);
			formData.append("password", agency.password);
			formData.append(
				"package",
				agency.package.map((pkg) => pkg.value).join(",")
			);

			axios
				.post(`${base_url}api/user/get-agency-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Agency Added",
							text: "Your Agency details have been added successfully.",
						});
						navigate("/manage-agency", { replace: true });
						setIsSubmitted(false);
					} else {
						setLoading(false);
						setIsSubmitted(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			setIsSubmitted(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};


	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 col-md-12 mx-auto">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h6 className="title">Add New Agency</h6>
								</div>
								<form
									onSubmit={handleSubmit}
									acceptCharset="utf-8"
									autoComplete="off"
								>
									<div className="card-body">
										<div className="row form-group mb-0">
											<div className="col-md-6 mb-3">
												<label className="form-label">
													Agency Name{" "}
													<span className="text-danger">*</span>
												</label>
												<input
													type="text"
													value={agency.agencyName}
													placeholder="Enter agency name"
													onChange={(e) =>
														setAgency({
															...agency,
															agencyName: e.target.value,
														})
													}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Email Address{" "}
													<span className="text-danger">*</span>
												</label>
												<input
													type="email"
													value={agency.email}
													placeholder="Enter email"
													onChange={(e) =>
														setAgency({
															...agency,
															email: e.target.value,
														})
													}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Password <span className="text-danger">*</span>
												</label>
												<input
												placeholder="Enter password"
													type="password"
													value={agency.password}
													onChange={(e) =>
														setAgency({
															...agency,
															password: e.target.value,
														})
													}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Confirm Password{" "}
													<span className="text-danger">*</span>
												</label>
												<input
													type="password"
													placeholder="Confirm password"
													value={agency.confirmPassword}
													onChange={(e) =>
														setAgency({
															...agency,
															confirmPassword: e.target.value,
														})
													}
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-12 mb-3">
												<label className="form-label">
													Select Packages
												</label>
												<Select
													isMulti
													options={agencyPackage}
													value={agency.package}
													onChange={(selected) =>
														setAgency({
															...agency,
															package: selected,
														})
													}
												/>
											</div>
										</div>
									</div>

									<button
										type="submit"
										className="btn btn-primary px-4 mt-2 mb-2"
									>
										Create
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgencyCreate;
