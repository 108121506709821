import React from 'react'
import './Reseller.styles.css'

const Reseller = () => {
  return (
    <div className="right-container">
    <div className="content">
      <div className="container-fluid">
        {/* start page title  */}
        <div className="row mb-2">
          <div className="col-12 ">
            <div className="page-title-box w-100">
              <h4 className="page-title">Reseller</h4>
              
            </div>
          </div>
        </div>
        {/* end page title  */}
        {/* Card  */}
        <div className="row">
          <div className="col-md-12">
            <div className="card w-100">
              <div className="head-part mb-0">
                <h6 className="profile-heading">Reseller Account Info</h6>
              </div>
              <div className="card-body">
            <h6 className="description-text">To sell TeamBuddy AI as reseller you need to request your affiliate link through Warrior Plus and mention <span className='fw-bold'>"20ACTIVETEAMBUDDYRESELLER24"</span> in message also. We will approve you and set your commission to 100%. You will get 100% commission for every sell you made through your affiliate link.</h6>
            <div className=" mt-4 mb-3">
              <a className="btn btn-primary" href="https://warriorplus.com/as/o/h6531s" target="_blank" rel="noopener noreferrer" >Request your affiliate link</a>
            </div>

          </div>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
    </div>
  </div>
  )
}

export default Reseller
