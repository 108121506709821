import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";
import Select from "react-select";

const TeamEdit = ({ base_url }) => {
	const navigate = useNavigate();
	const { teamIds } = useParams();
	const [item, setItem] = useState([]);
	const [editedItem, setEditedItem] = useState({});
	const [loading, setLoading] = useState(false);
	const [permissions, setPermissions] = useState([]);
	const [menuList, setMenuList] = useState([]);

	const fetchEditData = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "getSingleTeamDetails");
			formData.append("token", token);
			formData.append("teamIds", teamIds);

			const response = await axios.post(
				`${base_url}api/user/get-team-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false);
				setItem(response.data.items);
				setEditedItem(response.data.items);
				const initialPermissions = JSON.parse(response.data.items.menu) || [];
				setPermissions(initialPermissions.map((id) => Number(id)));
			} else {
				setLoading(false);
				if (response.data.reset) {
					handleInvalidToken(navigate);
				} else {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchEditData();
	}, [teamIds, navigate, base_url]);

	const handleUpdate = async (e) => {
		e.preventDefault();
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			if (!editedItem.name) {
				Swal.fire({
					icon: "warning",
					title: "Validation Error",
					text: "Team Member Name is required.",
				});
				return;
			}
			if (permissions == '') {
				Swal.fire({
					icon: "warning",
					title: "Validation Error",
					text: "Team Member Permissions is required.",
				});
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "updateTeam");
			formData.append("teamName", editedItem.name);
			formData.append("teamIds", teamIds);
			formData.append("permissions", JSON.stringify(permissions));

			const response = await axios.post(
				`${base_url}api/user/get-team-resource`,
				formData
			);
			if (response.data.status) {
				setLoading(false);
				navigate("/manage-team");
				Swal.fire({
					icon: "success",
					text: "Team Member Details Updated",
					text: "Your team member are updated successfully",
				});
				setItem(response.data.items);
				setEditedItem(response.data.items);
			} else {
				setLoading(false);
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const fetchMenuList = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "fetchMenuList");

			await axios
				.post(`${base_url}api/user/get-team-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setMenuList(response.data.items);
					} else {
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
					}
				});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	useEffect(() => {
		fetchMenuList();
	}, []);

	const groupedItems = menuList.reduce((acc, item) => {
		if (!acc[item.type]) acc[item.type] = [];
		acc[item.type].push({ value: Number(item.id), label: item.name });
		return acc;
	}, {});

	const groupedOptions = Object.entries(groupedItems).map(
		([category, items]) => ({
			label: category,
			options: items,
		})
	);

	const handleSelectionChange = (selectedOptions) => {
		const selectedIdsArray = selectedOptions
			? selectedOptions.map((option) => option.value)
			: [];
		setPermissions(selectedIdsArray);
	};

	// const handleSelectionChange = (selectedOptions) => {
	// 	const selectedIdsArray = selectedOptions
	// 		? selectedOptions.map((option) => option.value)
	// 		: [];
	// 	setPermissions(selectedIdsArray);
	// };

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body team-form-body">
								<div className="head-part">
									<h4 className=" title">Edit Team Member Details</h4>
								</div>
								<form onSubmit={handleUpdate}>
									<div className="row w-100 h-100">
										<div className="col-md-6 mb-4 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Team Member Name
											</label>
											<input
												type="text"
												id="formCompanyName"
												className="form-control custom-form-control"
												placeholder="Enter company name"
												value={editedItem.name}
												onChange={(e) =>
													setEditedItem((prev) => ({
														...prev,
														name: e.target.value,
													}))
												}
											/>
										</div>

										<div className="col-md-12 mb-4 mb-4">
											<label className="form-label">
												Select access for team member
											</label>
											<Select
												isMulti
												options={groupedOptions}
												onChange={handleSelectionChange}
												closeMenuOnSelect={false}
												styles={{
													groupHeading: (base) => ({
														...base,
														fontSize: "14px",
														color: "#333",
													}),
													multiValueLabel: (base) => ({
														...base,
														fontSize: "14px",
														color: "black",
													}),
													placeholder: (base) => ({
														...base,
														color: "#0000008a",
														fontSize: "14px",
													}),
													option: (base) => ({
														...base,
														color: "#0202029f",
														fontSize: "14px",
														padding: "10px",
													}),
													menu: (base) => ({
														...base,
														height: '200px',
														maxHeight: '300px',
														overflowY: 'auto', 
														scrollbarWidth : 'none'
													  }),
													  menuList: (base) => ({
														...base,
														padding: 0, 
														maxHeight: '500px', 
													  }),
													  control: (base) => ({
														...base,
														minHeight: "90px", // Minimum height of the control
														maxHeight: "300px", // Maximum height of the control
														overflowY: "auto", // Make the selected options scrollable
													}),
													multiValueContainer: (base) => ({
														...base,
														maxHeight: "30px", // Max height for selected options
														overflowY: "auto",
													}),
												}}
												value={permissions.map((id) => {
													for (let group of groupedOptions) {
														const selectedOption = group.options.find(
															(option) => option.value === id
														);
														if (selectedOption) return selectedOption;
													}
													return null;
												})}
											/>
										</div>
									</div>

									<div className="col-lg-12 mt-4">
										<button type="submit" className="btn btn-primary mt-2 mb-3">
											Update Team Member
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default TeamEdit;
