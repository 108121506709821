import React, { useContext } from 'react'
import { AppContext } from "../../context/AppContext";
import Swal from 'sweetalert2';
import './WhiteLabel.styles.css'

const WhiteLabel = () => {
	const { menuFullPermission, packageAccess, base_url } = useContext(AppContext);
	const downloadZip = () => {
		if (menuFullPermission && packageAccess.includes('whitelabel')) {
			const zipUrl = base_url + 'whitelabel/teambuddy.zip';
			const link = document.createElement('a');
			link.href = zipUrl;
			link.download = 'teambuddy.zip';
			link.click();
			Swal.fire({
				title: "Download Successful!",
				text: "Your download will start shortly!",
				icon: "success"
			});
		} else {
			Swal.fire({
				title: "Permission Denied",
				text: "You do not have permission to download the Whitelabel code.",
				icon: "error"
			});
		}
	};
	return (
		<div className="right-container">
			<div className="content">
				<div className="container-fluid">
					{/* start page title  */}
					<div className="row mb-2">
						<div className="col-12 ">
							<div className="page-title-box w-100">
								<h4 className="page-title">Whitelabel</h4>
							</div>
						</div>
					</div>
					{/* end page title  */}
					{/* Card  */}
					<div className="row">
						<div className="col-md-12">
							<div className="card w-100">
								<div className="head-part mb-0">
									<h6 className="profile-heading">Whitelabel</h6>
								</div>
								<div className="card-body">
									{/* Content */}
									<h4 className="description-text">
										<span className='fw-bold'>Exciting News ! </span>
										To get TeamBuddy AI whitelabel program, please submit a ticket on our support desk with code <span className='fw-bold'>"12ACTIVETEAMBUDDYWHITELABEL24"</span> and we will provide you whitelabel access once our launch week ends. Thank you for partnering with us!
										<div class="row mt-4">
											<div class="col-lg-12">
												<button id="downloadBtn" class="btn btn-primary" onClick={downloadZip}>Download Whitelabel Code</button>
											</div>
										</div>
									</h4>

									{/* End Content */}
								</div>
							</div>
						</div>
					</div>
					{/* End Card */}
				</div>
			</div>
		</div>
	)
}

export default WhiteLabel
